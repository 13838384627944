import React from "react";
import { t } from "i18next";

type OverridedStatusProps = {
  value: boolean;
  style?: React.CSSProperties;
};
const OverridedStatus: React.FC<OverridedStatusProps> = ({ value = false, style }) => {
  const text = value ? t("jobfile_validation.field_overrided") : t("jobfile_validation.field_not_overrided");
  const color = value ? "bg-orange-300" : "bg-green-300";

  return <div className={`${color} rounded-r-sm absolute top-[1px] bottom-[1px] right-[1px] w-3 z-10`} title={text} style={style} />;
};

export default OverridedStatus;