import React from "react";
import { t } from "i18next";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setLeftPanelVisibility } from "redux/jobfile";

const SidemenuToggle: React.FC = () => {
  const dispatch = useAppDispatch();
  const jobfileState = useAppSelector((state) => state.jobfile);

  const tooltipTitle = jobfileState.isLeftPanelVisible ? t("jobfile_documents_list.hide") : t("jobfile_documents_list.show");

  return (
    <div
      className="text-align:center absolute -right-[41px]  z-[10] flex h-10 w-10 cursor-pointer items-center justify-center border-b border-[#EBF0F4] bg-white text-2xl shadow-md hover:text-blue-600"
      title={tooltipTitle}
      onClick={() => dispatch(setLeftPanelVisibility(!jobfileState.isLeftPanelVisible))}
    >
      {jobfileState.isLeftPanelVisible ? <RiMenuFoldLine /> : <RiMenuUnfoldLine />}
    </div>
  );
};

export default SidemenuToggle;
