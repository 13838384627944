import en_translation from "./en/translation.json";
import en_state from "./en/state.json";
import en_types from "./en/types.json";
import en_connectors from "./en/connectors.json";

import fr_translation from "./fr/translation.json";
import fr_state from "./fr/state.json";
import fr_types from "./fr/types.json";
import fr_connectors from "./fr/connectors.json";
import fr_fields from "./fr/fields.json";

const availableLanguages = {
  en: { translation: { ...en_translation, ...en_state, ...en_types, ...en_connectors } },
  fr: { translation: { ...fr_translation, ...fr_state, ...fr_types, ...fr_connectors, ...fr_fields } },
};

export default availableLanguages;
