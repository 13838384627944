import React from "react";
import { t } from "i18next";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlineCreateNewFolder, MdOutlineFileUpload } from "react-icons/md";
import toast from "react-hot-toast";

import Button, { ButtonStyle } from "components/Button";
import DropdownMenu from "components/DropdownMenu";
import JobFilesService from "services/jobfiles.service";
import { uploadDocuments } from "redux/documents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { SearchType } from "./SearchTypeSelector";
import { MAX_UPLOAD_SIZE } from "config/constants";

const ACCEPTED_MIMETYPES = ["image/*", "application/pdf", "application/x-zip-compressed"]

type NewMenuProps = {
  reloadSearch: (searchType: SearchType) => void;
};

export const NewMenu: React.FC<NewMenuProps> = ({ reloadSearch }) => {
  const dispatch = useAppDispatch();
  const { uploadProgression } = useAppSelector((state) => state.documents);

  const fileInputRef = React.createRef<HTMLInputElement>();

  // Create a new jobfile
  const onClickCreateJobfile = async () => {
    try {
      const jobfile = await JobFilesService.createJobfile();
      if (jobfile) {
        toast.success(t("home.new_menu.new_jobfile_created", { name: jobfile.name }));
      }
    } catch (error) {
      toast.error(t("home.new_menu.new_jobfile_error"));
    } finally {
      // Trigger reload of search
      reloadSearch(SearchType.Jobfiles);
    }
  };

  // Triggered when user click "upload document" in NewMenu
  const onClickUploadDocument = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
    fileInputRef.current?.click();
  };

  // Handle file input change
  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const _files = e.target.files;

    if (!_files) {
      toast.error("No files in target to be uploaded");
      return;
    }

    const _file = _files[0];
    if (_file.size > MAX_UPLOAD_SIZE) {
      // Max file size exceeded
      toast.error(t("document_upload_size_exceeded") + ` ${Math.round(MAX_UPLOAD_SIZE / 1024 / 1024 * 10) / 10} MB. (${Math.round(_file.size / 1024 / 1024 * 10) / 10} MB).`);
    } else if (!ACCEPTED_MIMETYPES.includes(_file.type)) {
      // Mimetype not accepted
      // Can happen even if file input is properly configured with "All files *.*" option
      toast.error(`${_file.type} ` + t("document_upload_mimetype_not_accepted") + ` ${ACCEPTED_MIMETYPES.join(", ")}.`);
    } else {
      // Handle the file
      const result = await dispatch(uploadDocuments({ files: [_file] }));
      if (result.payload) {
        toast.success(t("home.new_menu.document_uploaded"));

        // Trigger reload of search
        reloadSearch(SearchType.Documents);
      } else {
        // FIXME: We copy result to avoid Typescript error on result.error not existing
        const errorResult: any = { ...result }
        if (errorResult.error) {
          toast.error(t("document_upload_failed") + `\n${errorResult.error.message}`);
        } else {
          toast.error(t("document_upload_failed") + `\n${_file.name}`);
        }
      }

    }
  };

  // Rendering
  return (
    <div className="self-center ml-2">
      <DropdownMenu
        button={
          <Button
            text={uploadProgression.uploading ? t("document_upload_btn.uploading_label") : t("home.new_menu.title")}
            loading={uploadProgression.uploading}
            disabled={uploadProgression.uploading}
            leftIcon={!uploadProgression.uploading ? <AiOutlinePlusCircle className="inline mr-2 text-xl -mt-0.5" /> : null}
            className="shadow py-[17px]"
            color={ButtonStyle.Gray}
          />
        }
        items={[
          { text: t("home.new_menu.new_jobfile"), leftIcon: <MdOutlineCreateNewFolder className="text-lg mr-2 opacity-60" />, onClick: onClickCreateJobfile },
          { text: t("home.new_menu.upload_document"), leftIcon: <MdOutlineFileUpload className="text-lg mr-2 opacity-60" />, onClick: onClickUploadDocument },
        ]}
      />

      <input className="hidden" type="file" onInput={handleFileInput} ref={fileInputRef} accept={`${ACCEPTED_MIMETYPES.join(",")}`} />
    </div>
  );
};
