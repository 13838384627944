import { StylesConfig } from 'react-select';

// Custom styles for react-select component
export const selectCustomStyles: StylesConfig = {
  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    fontSize: 12
  }),
  control: (styles) => ({
    ...styles,
    cursor: "pointer",
  }),
  input: (styles) => ({
    ...styles,
    boxShadow: 'none !important',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: 180
  })
};
