import React from "react";
import { t } from "i18next";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { Tooltip } from "@mui/material";

import { DocumentStatus } from "models/document";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { loadJobfileAndDocuments } from "redux/jobfile";
import { unvalidateDocument, validateDocument } from "redux/labelling";

const ValidateAnnotationButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentDocument } = useAppSelector((state) => state.labelling);
  const { currentJobfile } = useAppSelector((state) => state.jobfile);

  const isDocAlreadyValidated = currentDocument?.status === DocumentStatus.None ?? false;

  const onClickValidate = async () => {
    // if the document is already validated, prevent the update again
    if (currentDocument && !isDocAlreadyValidated) {
      try {
        await dispatch(validateDocument(currentDocument._id));
        // If we are inside a jobfile, refresh documents list
        if (currentJobfile) {
          dispatch(loadJobfileAndDocuments({ jobFileId: currentJobfile._id }));
        }
        // Else, just reload the page
        else {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onClickUnvalidate = async () => {
    // if the document is not validated, prevent the unvalidate
    if (currentDocument && isDocAlreadyValidated) {
      try {
        await dispatch(unvalidateDocument(currentDocument._id));
        // If we are inside a jobfile, refresh documents list
        if (currentJobfile) {
          dispatch(loadJobfileAndDocuments({ jobFileId: currentJobfile._id }));
        }
        // Else, just reload the page
        else {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="flex flex-row w-full">
      <div
        className={`flex-1 grow px-3 py-3 font-medium text-center border-t border-1 border-slate-300 ${isDocAlreadyValidated ? "bg-slate-200" : "bg-emerald-200 hover:bg-emerald-300 cursor-pointer"}`}
        onClick={onClickValidate}
      >
        <BsFileEarmarkCheck className="inline mr-2 -mt-0.5 text-lg" />
        {isDocAlreadyValidated ? t("labelling_panel.doc_already_validated") : t("labelling_panel.validate_annotation")}
      </div>
      {isDocAlreadyValidated && (
        <Tooltip title={t("labelling_panel.unvalidate_annotation")} arrow={true}>
          <div className="flex text-center items-center justify-center grow-0 w-12 border-l cursor-pointer bg-slate-400 hover:bg-slate-500" onClick={onClickUnvalidate}>
            <MdOutlineCancel className="inline text-xl text-white" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ValidateAnnotationButton;
