import React from "react";
import { Link, Outlet } from "react-router-dom";

import AppMenu from "./AppMenu";
import docloopLogoWhite from "assets/icon/logowhite.png";
import NavigationBreadcrumbs from "./NavigationBreadcrumbs";

export default function MainLayout() {
  return (
    <div className="flex h-screen w-screen flex-col bg-[#FAFBFF]">
      <div className="flex h-14 w-full items-center bg-docloop_mainColor px-3">
        <div className="flex grow items-center">
          <Link to="/">
            <img className="h-8 w-auto object-contain" src={docloopLogoWhite} alt="" />
          </Link>
        </div>
        <div className="flex items-center">
          <AppMenu />
        </div>
      </div>
      <NavigationBreadcrumbs />
      <div className="flex flex-1 overflow-hidden">
        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
