import React from "react";
import { t } from "i18next";

import Select, { ActionMeta, MultiValue } from "react-select";

import { selectCustomStyles } from "components/SelectSearch";
import { IDocumentType } from "models/document_type";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSearchDocumentsTypes } from "redux/search";

type SearchTypeProps = {};

export const SearchDocTypesSelector: React.FC<SearchTypeProps> = () => {
  const dispatch = useAppDispatch();
  const { documentsTypes } = useAppSelector((state) => state.documents);
  const { searchDocumentsTypes } = useAppSelector((state) => state.search);

  // Format tenants for the select component
  const formatTenantsSelectOptions = () => {
    return documentsTypes?.map((dt) => ({ value: dt.name, label: t(`document_type.${dt.name}`, dt.displayName) })) ?? [];
  };

  // Triggered when a tenant is selected
  const onSelect = async (newValue: MultiValue<any>) => {
    const selectedTypes: IDocumentType[] = newValue.reduce((arr, option) => {
      const docType = documentsTypes?.find((dt) => dt.name === option.value);
      if (docType) arr.push(docType);

      return arr;
    }, [] as IDocumentType[]);

    dispatch(setSearchDocumentsTypes(selectedTypes.map((t) => t.name)));
  };

  // Format selected documents types options
  const selectedDocumentsTypes: IDocumentType[] = searchDocumentsTypes.reduce((arr, docType_name) => {
    const docType = documentsTypes?.find((dt) => dt.name === docType_name);
    if (docType) arr.push(docType);

    return arr;
  }, [] as IDocumentType[]);

  // Rendering
  return (
    <Select
      classNamePrefix="docType_select"
      className="ml-2 text-xs"
      isSearchable
      isMulti
      isClearable={false}
      name="doc_type"
      value={selectedDocumentsTypes.map((sdt) => ({ value: sdt.name, label: t(`document_type.${sdt.name}`, sdt.displayName) }))}
      placeholder={t("home.filters.doc_types.placeholder")}
      options={formatTenantsSelectOptions()}
      onChange={(newValue: MultiValue<unknown>, actionMeta: ActionMeta<unknown>) => onSelect(newValue)}
      styles={selectCustomStyles}
      tabSelectsValue={false}
    />
  );
};
