import React from "react";
import { t } from "i18next";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { ImTable2 } from "react-icons/im";
import { TbTableColumn } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import { useAppSelector } from "redux/hooks";

type AnnotationIndicatorProps = {};

const AnnotationIndicator: React.FC<AnnotationIndicatorProps> = () => {
  const { focusedField, focusedTable } = useAppSelector((state) => state.labelling);

  const getColorFromContext = () => {
    if (focusedTable?.focusedCell?.edition || focusedTable?.focusedColumn) return "#ffa500";
    else return "#309bef";
  };

  const getToolipFromContext = () => {
    if (focusedField) {
      return t("annotation_indicator.field_mapping", { field: t(`documentType.${focusedField.technicalName}`, focusedField.technicalName) });
    } else if (focusedTable?.focusedColumn) {
      return t("annotation_indicator.table_column_mapping", { field: t(`documentType.${focusedTable.focusedColumn.column.technicalName}`, focusedTable.focusedColumn.column.technicalName) });
    } else if (focusedTable?.focusedCell) {
      return t("annotation_indicator.table_cell_mapping", {
        row: focusedTable.focusedCell.row + 1,
        field: t(`documentType.${focusedTable.focusedCell.cell.technicalName}`, focusedTable.focusedCell.cell.technicalName),
      });
    }

    // If we reach here, return an empty string
    return "";
  };

  // Handle click on annotationIndicator
  const handleClick = () => {};

  //
  // Rendering
  //
  if (!focusedField && !focusedTable?.focusedColumn && !focusedTable?.focusedCell?.edition) return null;

  const indicatorColor = getColorFromContext();

  return (
    <Tooltip title={getToolipFromContext()} arrow={true}>
      <div
        id="annotation_indicator"
        className={`absolute right-1.5 w-16 h-16 bottom-0 text-white flex items-center justify-center z-50 rounded-t-lg animate-pulse`}
        style={{ backgroundColor: indicatorColor }}
        onClick={handleClick}
      >
        <MdOutlineDocumentScanner className="inline text-2xl  relative z-1" />
        {focusedTable?.focusedColumn && <TbTableColumn className="absolute text-md z-0 bottom-0 mb-0.5 mr-0.5 right-0 opacity-60" />}
        {focusedTable?.focusedCell?.edition && <ImTable2 className="absolute text-md z-0 bottom-0 mb-0.5 mr-0.5 right-0 opacity-60" />}
      </div>
    </Tooltip>
  );
};

export default AnnotationIndicator;
