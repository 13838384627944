import React from "react";
import { FormControl, Stack, TextField } from "@mui/material";
import { IProcessor } from "models/usecase";
import DocFieldSelector from "./DocFieldSelector";
import { DocumentTypeFieldType, IDocumentTypeField } from "models/document_type";
import { t } from "i18next";

type FormatNumberInputProps = {
  processor: IProcessor;
  usableDocumentsFields: IDocumentTypeField[];
  onChange: (proc: any) => void;
};

const DEFAULT_DECIMALS_COUNT = 2;

const FormatNumberInput: React.FC<FormatNumberInputProps> = ({ processor, usableDocumentsFields, onChange }) => {
  return (
    <div className="w-full flex flex-col">
      <Stack spacing={1}>
        <DocFieldSelector
          processor={processor}
          onChange={(settings: Object) => onChange({ ...settings, decimals_count: processor.decimals_count ?? DEFAULT_DECIMALS_COUNT })}
          usableDocumentsFields={usableDocumentsFields.filter((f) => f.type === DocumentTypeFieldType.number)}
        />
        <FormControl fullWidth size="small">
          <TextField
            label={t('processor.format_number.decimals_count_field')}
            value={processor?.decimals_count ?? DEFAULT_DECIMALS_COUNT}
            inputProps={{ shrink: true, step: 1, style: { padding: "4.5px 6px", fontSize: 12, boxShadow: "none" } }}
            InputLabelProps={{style: {fontSize: 14}}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ decimals_count: parseInt(event.target.value), field: processor.field });
            }}
            type="number"   
          
          />
        </FormControl>
      </Stack>
    </div>
  );
};

export default FormatNumberInput;
