import api from "./api";
import axios from "axios";

const AUTH_URL = "/auth";

// TODO: properly type axios responses
class AuthService {
  async login(email: string, password: string) {
    return api.post(AUTH_URL + "/login", { email: email, password: password }).then((response) => {
      return response.data;
    });
  }

  async logout() {
    return api.post(AUTH_URL + "/logout").then((response) => {});
  }

  async refreshTokens() {
    return api.post(AUTH_URL + "/refresh").then((response) => {
      return response.data;
    });
  }

  async register(username: string, password: string) {
    return axios.post(AUTH_URL + "/register", {
      username,
      password,
    });
  }
}

const authService = new AuthService();
export default authService;
