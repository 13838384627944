import React from "react";
import { FormControl, Stack, TextField } from "@mui/material";
import { IProcessor } from "models/usecase";
import DocFieldSelector from "./DocFieldSelector";
import { IDocumentTypeField } from "models/document_type";
import { t } from "i18next";

type SubstringInputProps = {
  processor: IProcessor;
  usableDocumentsFields: IDocumentTypeField[];
  onChange: (proc: any) => void;
};

const SubstringInput: React.FC<SubstringInputProps> = ({ processor, usableDocumentsFields, onChange }) => {
  return (
    <div className="w-full flex flex-col">
      <Stack spacing={1}>
        <DocFieldSelector
          processor={processor}
          onChange={(settings: Object) => onChange({ ...settings, start: processor.start ?? 0, end: processor.end ?? 0})}
          usableDocumentsFields={usableDocumentsFields}
        />
        <FormControl fullWidth size="small">
          <TextField
            label={t('processor.substring.start_field')}
            value={processor?.start ?? 0}
            inputProps={{ step: 1, style: { padding: "4.5px 6px", fontSize: 12, boxShadow: "none" } }}
            InputLabelProps={{style: {fontSize: 14}}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ start: parseInt(event.target.value), end: processor.end, field: processor.field });
            }}
            type="number"   
          />
        {/* </FormControl>
        <FormControl fullWidth size="small"> */}
          <TextField
            label={t('processor.substring.end_field')}
            value={processor?.end ?? 0}
            inputProps={{ step: 1, style: { padding: "4.5px 6px", fontSize: 12, boxShadow: "none" } }}
            InputLabelProps={{style: {fontSize: 14}}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ start: processor.start, end: parseInt(event.target.value), field: processor.field });
            }}
            type="number"   
          />
        </FormControl>
      </Stack>
    </div>
  );
};

export default SubstringInput;
