import React from "react";

type SectionBlocProps = {
  title: string;
  className?: string;
  children: React.ReactNode;
};

const SectionBloc: React.FC<SectionBlocProps> = ({ title, className = "", children }) => {
  return (
    <div className={`${className} w-full relative border border-docloop_borderColor rounded bg-white shadow -mb-4`}>
      <div className={"px-3 py-2 text-sm font-semibold border-b border-docloop_borderColor"}>{title}</div>
      <div className="p-2 -mb-2">{children}</div>
    </div>
  );
};

export default SectionBloc;
