import { ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { AnyAction, combineReducers } from "redux";
import * as Documents from "./documents";
import * as UseCases from "./usecases";
import * as User from "./users";
import * as Labelling from "./labelling";
import * as Jobfile from "./jobfile";
import * as Search from "./search";
import * as Navigation from "./navigation";
import * as TenantUsers from "./tenantUsers";

export interface ApplicationState {
  navigation: Navigation.NavigationState;
  user: User.UserState;
  documents: Documents.DocumentsState;
  usecases: UseCases.UseCasesState;
  labelling: Labelling.LabellingState;
  jobfile: Jobfile.JobfileState;
  search: Search.SearchState;
  tenantUsers: TenantUsers.TenantUsersState;
}

export const reducers = combineReducers({
  navigation: Navigation.reducer,
  user: User.reducer,
  documents: Documents.reducer,
  usecases: UseCases.reducer,
  labelling: Labelling.reducer,
  jobfile: Jobfile.reducer,
  search: Search.reducer,
  tenantUsers: TenantUsers.reducer,
});

const initialState: ApplicationState = {
  navigation: Navigation.initialState,
  user: User.initialState,
  documents: Documents.initialState,
  usecases: UseCases.initialState,
  labelling: Labelling.initialState,
  jobfile: Jobfile.initialState,
  search: Search.initialState,
  tenantUsers: TenantUsers.initialState,
};

export const store = configureStore({
  reducer: reducers,
  preloadedState: initialState,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch & ThunkDispatch<RootState, null, AnyAction>;
// we export the Store type because we need to inject it to non React componenent like axios interceptor
export type Store = typeof store;
