import React from "react";

import { IDocumentTypeField } from "models/document_type";
import { IReferential } from "models/referential";
import { IProcessor } from "models/usecase";

import FixedValueInput from "./FixedValueInput";
import DocFieldSelector from "./DocFieldSelector";
import FormatDateInput from "./FormatDateInput";
import FormatNumberInput from "./FormatNumberInput";
import SubstringInput from "./SubstringInput";
import ReferentialLookupInput from "./ReferentialLookupInput";

export type InputFieldProps = {
  processor: IProcessor;
  usableDocumentsFields: IDocumentTypeField[];
  referentials: IReferential[];
  onChange: (proc: any) => void;
};

const ProcessorInput: React.FC<InputFieldProps> = ({ processor, ...rest }) => {
  switch (processor.type) {
    case "fixed_value":
      return <FixedValueInput processor={processor} {...rest} />;
    case "doc_field":
      return <DocFieldSelector processor={processor} {...rest} />;
    case "format_number":
      return <FormatNumberInput processor={processor} {...rest} />;
    case "format_date":
      return <FormatDateInput processor={processor} {...rest} />;
    case "substring":
      return <SubstringInput processor={processor} {...rest} />;
    case "referential_lookup":
      return <ReferentialLookupInput processor={processor} {...rest} />;
    default:
      return <span className="text-[12px] italic mt-1 text-gray-400">Not handled yet</span>;
  }
};

export default ProcessorInput;
