import React from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { IDocumentTypeField } from "models/document_type";
import { IProcessor } from "models/usecase";
import { t } from "i18next";

type DocFieldSelectorProps = {
  processor: IProcessor;
  usableDocumentsFields: IDocumentTypeField[];
  required?: boolean;
  label?: string;
  onChange: (proc: any) => void;
};

const DocFieldSelector: React.FC<DocFieldSelectorProps> = ({ processor, usableDocumentsFields, required = true, label, onChange }) => {
  return (
    <div className="w-full">
      <FormControl fullWidth size="small">
        <InputLabel required={required}>{label ?? t("processor.doc_field.field")}</InputLabel>
        <Select
          label={label ?? t("processor.doc_field.field")}
          value={processor?.field ?? "-1"}
          displayEmpty
          required={required}
          SelectDisplayProps={{ style: { padding: "2px 30px 2px 6px", fontSize: 12 } }}
          onChange={(event: SelectChangeEvent) => onChange({ field: event?.target.value })}
        >
          {usableDocumentsFields?.map((field) => (
            <MenuItem key={`processor_${processor.type}_${field.technicalName}`} value={field.technicalName} style={{ fontSize: 12 }}>
              {field.technicalName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DocFieldSelector;
