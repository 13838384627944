import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getAllUsers } from "redux/tenantUsers";
import { TenantUserTableComponent, TenantUserTableRowComponent } from "./components/TenantUserTableComponents";
import { IUser } from "models/user";
import { Box, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import TenantUserFormModal from "./components/AddTenantUserFormModal";
import userService from "services/user.service";
import { removeUser } from 'redux/tenantUsers';
import ConfirmationModal from "components/ConfirmationModal";
import { t } from "i18next";

const TenantUserList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const tenantUsers: IUser[] = useSelector((state: RootState) => state.tenantUsers.entities);
  const loading = useSelector((state: RootState) => state.tenantUsers.loading);
  const error = useSelector((state: RootState) => state.tenantUsers.error);
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedUserForDeletion, setSelectedUserIdForDeletation] = useState<IUser|null>(null);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  if (loading) return <div>Loading users...</div>
  if (error) return <div>Error fetching users: {error}</div>

  const handleOpenAdduserModal = () => setAddUserModalOpen(true);
  const handleCloseAddUserModal = () => setAddUserModalOpen(false);

  const handleConfirmModalOpen = (userId: IUser) => {
    setSelectedUserIdForDeletation(userId);
    setConfirmModalOpen(true);
  };
  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  const handleConfirmModalConfirm = async () => {
    if (selectedUserForDeletion) {
      const result = await userService.deleteUserById(selectedUserForDeletion._id);
      if (result) {
        dispatch(removeUser(selectedUserForDeletion));
        handleConfirmModalClose();
      }
    }
  };

  return (
    <div className="px-3">
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAdduserModal}
        >
          {t("user_list_view.add_new_user")}
        </Button>
      </Box>

      <TenantUserFormModal
        open={isAddUserModalOpen}
        handleClose={handleCloseAddUserModal}
      />

      <TenantUserTableComponent headers={[
          t("user_list_view.email_address"),
          t("user_list_view.name"),
          t("user_list_view.role"),
          t("user_list_view.account_state"),
          '']}>
        {tenantUsers.map((user) => (
          <TenantUserTableRowComponent
            key={user._id}
            values={[
              { value: user.email },
              { value: `${user.firstname ?? ""} ${ user.lastname ?? ""}`.trim() },
              { value: user.role },
              { value: user.accountState},
              { value: user._id === currentUser?._id ? false: true }
            ]}
            onDelete={() => {
              if (user._id === currentUser?._id) return null;
              return handleConfirmModalOpen(user);
            }}
          />
        ))}
      </TenantUserTableComponent>

      <ConfirmationModal
        open={isConfirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleConfirmModalConfirm}
        title={t("user_list_view.confirm_user_deletion")}
      >
        {t("user_list_view.are_you_sure_to_delete_user")} <br/>
        <strong>{selectedUserForDeletion?.email}</strong> ?
      </ConfirmationModal>
    </div>
  );
};

export default TenantUserList;
