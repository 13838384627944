import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { t } from "i18next";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useInView } from "react-intersection-observer";
import { FiDownload } from "react-icons/fi";
import { TbZoomReplace } from "react-icons/tb";
import { MdOutlineZoomInMap, MdOutlineZoomOutMap } from "react-icons/md";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  addColumnMappingWords,
  selectMappingWords,
  setTableAnnotationZone,
  setTableAnnotationZoneActiveRow,
  Position,
  LabellingState,
  addPageMetadata,
  PageMetadata,
  setFocusedField,
  setFocusedTable,
  setFocusedTableCell,
  TableFocusedCell,
} from "redux/labelling";
import { useDocumentTypes } from "hooks/DocumentTypesHook";

import Loading from "components/Loading";
import Button, { ButtonStyle } from "components/Button";

import { AzureOcrWord } from "types/azure";
import { Coord, OcrBbox, OcrTableRowsCenters, OcrWord } from "types/labelling";
import { getColorForFieldOrigin, getColumnsCentersForPageForTable, getMappedFieldsForPage, getPageRotation, rotateBbox, rotateCoord } from "utils/labelling";
import { getMappedRowsForPageForTable } from "utils/labelling";
import { ANNOTATION_ORIGIN_COLORS, ACTIVE_ROW_SIZE, ACTIVE_ROW_OFFSET } from "config/constants";
import { DocumentFieldOrigin, DocumentStatus, IDocument, IDocumentField, IDocumentMappedField } from "models/document";
import { DocumentTypeFieldType } from "models/document_type";
import AnnotationModal from "./AnnotationModal";
import documentsServices from "services/documents.service";

// FIXME: Kludge for an unknown reason the webpack configured under CRA will
// export a pdf.worker.min.js with requires() statements, probably because he
// tries to compile at load when resolving the import.meta.url, consequence is
// the resulting file is not readable in Chrome. Also it seems that js files
// are skipped from file loading with the CRA webpack config
// IMPORTANT: this requires that the dockerfile for prod copies BOTH the min.js and
// the map file to the static location
if (process.env.NODE_ENV === "production") {
  pdfjs.GlobalWorkerOptions.workerSrc = "/static/media/pdf.worker.min.js";
} else {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();
}

const MAX_DOCUMENT_WIDTH = 800;

type PageViewerProps = {
  doc?: IDocument;
  disableAnnotation?: Boolean;
};

export type SelectionZoneRectangle = {
  x: number;
  y: number;
  w: number;
  h: number;
};
export type SelectionZone = SelectionZoneRectangle & {
  mouseX: number;
  mouseY: number;
  pageIndex: number;
  active: boolean;
};

enum PageZoom {
  DEFAULT = 0,
  FIT_TO_PAGE = 1,
  FIT_TO_WIDTH = 2,
}

export const PageViewer: React.FC<PageViewerProps> = ({ doc = null, disableAnnotation = false }) => {
  const dispatch = useAppDispatch();
  const labellingState: LabellingState = useAppSelector((state) => state.labelling);
  const { currentDocType } = useDocumentTypes();

  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();

  const pagesCanvas = useRef<{ [key: string]: HTMLCanvasElement | null }>({});
  const pagesAnnotationsCanvas = useRef<{ [key: string]: HTMLCanvasElement | null }>({});
  const pagesRenderingOrientation = useRef<{ [key: string]: number }>({});
  const pagesOCR = useRef<{ [key: string]: any }>({});
  const pagesWords = useRef<{ [key: string]: Array<OcrWord> }>({});
  const infoRect = useRef<SelectionZone>({ x: 0, y: 0, w: 0, h: 0, mouseX: 0, mouseY: 0, pageIndex: -1, active: false });

  const [numPages, setNumPages] = useState<number>();
  const [activePageNumber, setActivePageNumber] = useState(1);

  const [pageZoom, setPageZoom] = useState<PageZoom>(!disableAnnotation && localStorage.getItem("docloop.pageZoom") ? Number(localStorage.getItem("docloop.pageZoom")) : PageZoom.DEFAULT);
  const [pageDisplayWidth, setPageDisplayWidth] = useState<number>();

  const [currentDocumentFileUrl, setCurrentDocumentFileUrl] = useState<string>();
  // TODO: implement a layoutMeta interface
  const [currentDocumentLayoutMeta, setCurrentDocumentLayoutMeta] = useState<{}>();

  // Important line: choose on which document we will use for this pdfViewer. Redux state or document in props
  const currentDocument = doc ? doc : labellingState.currentDocument;

  // Getters for the Ref, especially those with keys because some shit can happen if we access them directly
  const getPagesWords = (pageNumber: number): Array<OcrWord> => {
    const refKeyPageIndex = `page_${pageNumber}`;
    const pageWords = pagesWords.current[refKeyPageIndex];

    // place holder here for more advanced verification logic
    if (!pageWords) {
      return [];
    }

    return pageWords;
  };

  // Each time we init or switch the current document we first retrieve the layout headers (said meta)
  // This tells us the layout is available without having to perform many calls for each page
  // It also triggers an analysis in the backend if the layout is not present and fixes any mismatch between
  // the storage and the DB
  useEffect(() => {
    if (!currentDocument?._id) return;
    // if we switch current document we are not sure it comes from an get document to the backend
    // hence the sas token may be outdated and it will not be possible to retrieve the file
    // so we make an explicit call to the backend to get the url refreshed and not rely on IDoc
    documentsServices
      .getDocumentFileUrl(currentDocument._id)
      .then((url) => setCurrentDocumentFileUrl(url))
      .catch((err) => console.log(err));

    documentsServices
      .getDocumentLayoutMeta(currentDocument._id)
      .then((layout_meta) => setCurrentDocumentLayoutMeta(layout_meta))
      .catch((err) => console.log(err));
  }, [currentDocument?._id]);

  // The ResizeObserver listens to the Document containerRef, that is the container div of <Document>
  // on change size the containerWidth state is updated to re-render the pdf pages
  useEffect(() => {
    if (!containerRef) return;

    const observer = new ResizeObserver(() => {
      if (containerRef.clientWidth) {
        setContainerWidth(containerRef.clientWidth);
      }
      if (containerRef.clientHeight) {
        setContainerHeight(containerRef.clientHeight);
      }
    });
    observer.observe(containerRef);

    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  // Refresh mapped zones based on multiple criterias: focusedField, focusedTable.focusedColumn, hoveredField, page
  useEffect(() => {
    if (!numPages || disableAnnotation) return;

    const labellingHoveredField = labellingState.hoveredField?.technicalName;
    const documentHoveredField: IDocumentField | undefined = labellingHoveredField ? currentDocument?.extraction?.data[labellingHoveredField] : undefined;

    // On pagesMetadata change, check if canvas dimensions are still identical between pageCanvas and annotationCanvas
    // An issue related to PdfJs can occured when we apply a rotation on a page. When it happens, canvas width/height can be hazardous and we need to adapt our own annotation canvas dimensions
    for (let pageIndex: number = 1; pageIndex <= numPages; pageIndex++) {
      const annotationCanvas = pagesAnnotationsCanvas.current[`page_${pageIndex}`];
      const canvas = pagesCanvas.current[`page_${pageIndex}`];
      if (annotationCanvas && canvas) {
        // Apply change only if dimension(s) changed
        if (annotationCanvas.width !== canvas.width) annotationCanvas.width = canvas.width;
        if (annotationCanvas.height !== canvas.height) annotationCanvas.height = canvas.height;
      }
    }

    for (let pageIndex: number = 1; pageIndex <= numPages; pageIndex++) {
      const canvas = clearCanvas(pageIndex);
      if (canvas === null) {
        continue;
      }

      const pageMetadata = labellingState.pagesMetadata.find((pm) => pm.page === pageIndex);
      if (!pageMetadata) continue;

      // If there is an hovered field (from labelling panel), then we only display the associated words
      if (documentHoveredField && documentHoveredField.mapping) {
        // We are on the canvas of a particular page we will then select only the words of this page to display
        const documentHoveredFieldWordsInPage = documentHoveredField.mapping.filter((word) => word.page === pageIndex);
        for (const word of documentHoveredFieldWordsInPage) {
          const mappingColor = getColorForFieldOrigin(documentHoveredField.origin);
          const scaledBbox = convertBboxToCanvasScale(canvas, word.bbox, pageMetadata);
          drawWordOverlay(canvas, convertOcrBoxToSelectionZoneRectangle(scaledBbox), mappingColor, 1, true);
        }
      }
      // If there is no hovered field, display all mapped fields
      else {
        drawMappedFields(pageIndex);
      }

      // If needed, draw selected mapping words
      drawSelectedMappingWords(pageIndex);

      // In any case, re-draw the table area if needed
      drawTableArea(pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentDocument,
    numPages,
    labellingState.focusedField,
    labellingState.multiMapping,
    labellingState.hoveredField,
    labellingState.selectedMappingWords,
    disableAnnotation,
    labellingState.focusedTable,
    labellingState.pagesMetadata,
  ]);

  // Listen to tableArea in order to auto-expand the table if needed
  // When user has just created the tableArea, it is based on user selected zone
  // We compare this user selected zone with coordinates from bbox already mapped in the table
  // We adapt the tableArea with final
  useEffect(() => {
    if (!currentDocument || !labellingState.focusedTable?.annotationZone || !numPages || disableAnnotation) return;

    // Get canvas info
    const pageIndex = labellingState.focusedTable.annotationZone.pageIndex;
    const refKeyPageIndex = `page_${pageIndex}`;
    let canvas = pagesAnnotationsCanvas.current[refKeyPageIndex];
    const pageMetadata = labellingState.pagesMetadata.find((pm) => pm.page === pageIndex);

    // Initialize tableArea props that may be updated
    let expandedSelectedZone = null;
    let activeRowRect = null;
    const rowCoordinates = getMappedRowsForPageForTable(currentDocument, pageIndex, labellingState.focusedTable.table.technicalName);
    const activeRowCoordinates = rowCoordinates?.[labellingState.focusedTable.annotationZone.activeRow];

    if (canvas && pageMetadata) {
      // Compute expanded tableArea based on existing data
      const focusedTable = labellingState.focusedTable.table.technicalName;
      const documentTable = currentDocument.extraction?.data[focusedTable] ?? null;
      // Get bboxes from the existing table in the IDocument
      if (documentTable) {
        // Filter words from the current page, based on MAPPING
        const tableBboxes = extractMappings(documentTable);
        if (tableBboxes.length > 0) {
          // Get the x0, x1, y0, y1 boundaries of the table
          const tableCoordinates = tableBboxes.reduce((acc: OcrBbox, currWord: OcrWord) => {
            if (!canvas) return acc;

            const scaledBbox = convertBboxToCanvasScale(canvas, currWord.bbox, pageMetadata);
            return {
              x0: Math.min(acc.x0, scaledBbox.x0),
              x1: Math.max(acc.x1, scaledBbox.x1),
              y0: Math.min(acc.y0, scaledBbox.y0),
              y1: Math.max(acc.y1, scaledBbox.y1),
            };
          }, convertBboxToCanvasScale(canvas, tableBboxes[0].bbox, pageMetadata));

          const { x, y, w, h } = labellingState.focusedTable.annotationZone.position;
          const lastSelectedZone = {
            x0: Math.min(x, x + w),
            x1: Math.max(x, x + w),
            y0: Math.min(y, y + h),
            y1: Math.max(y, y + h),
          };

          // Compare user - selected zone with tables boundaries to get the actual boundaries
          const updatedTableCoordinates = {
            x0: Math.min(tableCoordinates.x0, lastSelectedZone.x0),
            x1: Math.max(tableCoordinates.x1, lastSelectedZone.x1),
            y0: Math.min(tableCoordinates.y0, lastSelectedZone.y0),
            y1: Math.max(tableCoordinates.y1, lastSelectedZone.y1),
          };

          expandedSelectedZone = convertOcrBoxToSelectionZoneRectangle(updatedTableCoordinates);
        }
      }

      if (pageMetadata && activeRowCoordinates) {
        // When mapped coordinates are available for this row
        // Compute active row rectangle
        const scaledActiveRowCoordinates = convertBboxToCanvasScale(canvas, activeRowCoordinates, pageMetadata);

        // Active row on the right
        // X-axis: On the right of the table area OR on the right of the canvas
        const { x, w } = labellingState.focusedTable.annotationZone.position;
        const activeRowRectX = Math.min(Math.max(x, x + w) + ACTIVE_ROW_OFFSET, canvas.width - ACTIVE_ROW_SIZE);
        // // Active row on the left
        // X-axis: On the left of the table area OR on the left of the canvas
        // const activeRowRectX = Math.max((Math.min(x, x + w) - (ACTIVE_ROW_SIZE + ACTIVE_ROW_OFFSET)), 0);

        // Y-axis: In the middle of the row
        const activeRowRectY = (scaledActiveRowCoordinates.y0 + scaledActiveRowCoordinates.y1) / 2 - ACTIVE_ROW_SIZE / 2;
        activeRowRect = { x: activeRowRectX, y: activeRowRectY, w: ACTIVE_ROW_SIZE, h: ACTIVE_ROW_SIZE };
      } else if (pageMetadata && rowCoordinates && !activeRowCoordinates) {
        // When no bbox were mapped yet in this row
        // Deduce active row coordinates based on other available coordinates
        activeRowRect = computeActiveRowCoordinates(rowCoordinates, canvas, pageMetadata);
      }
    }

    if (expandedSelectedZone && !haveEqualCoordinates(expandedSelectedZone, labellingState.focusedTable.annotationZone.position)) {
      // Case 1: Expand tableArea to match existing table
      //console.log("[PAGE VIEWER] Update tableArea with expandedSelectedZone");

      // Update the size and activeRow of tableArea
      // Update leave table mode rectangle & active row rectangle
      dispatch(
        setTableAnnotationZone({
          pageIndex: pageIndex,
          activeRow: labellingState.focusedTable.annotationZone.activeRow ?? 0,
          position: {
            x: expandedSelectedZone ? expandedSelectedZone.x : infoRect.current.x,
            y: expandedSelectedZone ? expandedSelectedZone.y : infoRect.current.y,
            w: expandedSelectedZone ? expandedSelectedZone.w : infoRect.current.w,
            h: expandedSelectedZone ? expandedSelectedZone.h : infoRect.current.h,
          },
          originalCanvasWidth: labellingState.focusedTable.annotationZone.originalCanvasWidth,
          activeRowButtonCoordinates: activeRowRect,
        })
      );
    } else if (activeRowRect && !haveEqualCoordinates(activeRowRect, labellingState.focusedTable.annotationZone.activeRowButtonCoordinates)) {
      // Case 2: Update only activeRowRect, not the all boundaries
      //console.log("[PAGE VIEWER] Move activeRow rectangle");
      dispatch(
        setTableAnnotationZone({
          ...labellingState.focusedTable.annotationZone,
          activeRowButtonCoordinates: activeRowRect,
        })
      );
    } else if (!activeRowRect && labellingState.focusedTable.annotationZone.activeRowButtonCoordinates) {
      // Case 3: Make activeRowRect disappear when there is no mapping available in activeRow
      //console.log("[PAGE VIEWER] Make activeRow rectangle disappear");
      dispatch(
        setTableAnnotationZone({
          ...labellingState.focusedTable.annotationZone,
          activeRowButtonCoordinates: null,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labellingState.focusedTable?.annotationZone, currentDocument]);

  // Each time the current document change, reset canvas listeners in order for them to have the last redux state
  useEffect(() => {
    if (!numPages || disableAnnotation) return;

    for (let pageIndex: number = 1; pageIndex <= numPages; pageIndex++) {
      const refKeyPageIndex = `page_${pageIndex}`;
      let canvas = pagesAnnotationsCanvas.current[refKeyPageIndex];
      if (canvas) addListenersToCanvas(canvas, pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument, numPages, labellingState.focusedField, labellingState.multiMapping, labellingState.focusedTable, labellingState.pagesMetadata]);

  // Listener on mouse wheel events for FIT_TO_PAGE mode
  // In Fit to page zoom level and Ctrl not pressed, we override the standard behaviour
  // Scroll up means "Focus on previous page", Scroll down means "Focus on next page"
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      handlePageKeyboard(event);
    };
    document.addEventListener("keydown", handleKeyDown);
    applyPageZoom(pageZoom);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageZoom, activePageNumber, labellingState.currentDocument, containerHeight, containerWidth]);

  //
  // Callback of react-pdf for PDFs display
  //
  const onDocumentLoadSuccess = async (pdf: PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
    pagesCanvas.current = {};
    pagesAnnotationsCanvas.current = {};

    for (let i = 1; i <= pdf.numPages; i++) {
      try {
        const page = await pdf.getPage(i);
        pagesRenderingOrientation.current[`page_${i}`] = page.rotate;
      } catch (error) {
        console.error(error);
      }
    }
  };

  //
  // Functions related to annotation canvas drawing
  //

  // Init the annotation canvas for a page
  const initAnnotationCanvas = async (pageIndex: number) => {
    const pageCanvas = pagesCanvas.current[`page_${pageIndex}`];
    var canvas = document.createElement("canvas");
    pagesAnnotationsCanvas.current[`page_${pageIndex}`] = canvas;

    // if a canvas already exists with the same id, remove it
    const canvasId = `canvas-annotation-${pageIndex}`;
    const existingCanvas = document.getElementById(canvasId);
    if (existingCanvas) existingCanvas.remove();

    canvas.className = "absolute top-0 left-0 z-10 w-full h-full";
    canvas.id = canvasId;
    if (pageCanvas) {
      canvas.width = pageCanvas.width;
      canvas.height = pageCanvas.height;
      pageCanvas.parentNode?.appendChild(canvas);
    }

    if (!disableAnnotation) addListenersToCanvas(canvas, pageIndex);
    try {
      await loadOCR(canvas, pageIndex);

      if (!disableAnnotation) {
        if (pageIndex === activePageNumber) applyPageZoom(pageZoom);
        drawMappedFields(pageIndex);
        drawSelectedMappingWords(pageIndex);
        // This re-draw will reset table zone if canvas width has changed
        drawTableArea(pageIndex);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Add listeners to an annotation canvas
  const addListenersToCanvas = (canvas: HTMLCanvasElement, pageIndex: number) => {
    canvas.onmouseup = (ev: MouseEvent) => onMouseUp(ev, pageIndex);
    canvas.onmousedown = (ev: MouseEvent) => onMouseDown(ev, pageIndex);
    canvas.onmousemove = (ev: MouseEvent) => onMouseMove(ev, pageIndex);
  };

  // Apply user-selected page zoom
  // This is used at time of initAnnotationCanvas and each time page viewer dimensions change
  const applyPageZoom = (zoom: PageZoom) => {
    switch (zoom) {
      case PageZoom.FIT_TO_PAGE:
        // Rule of three to get final width from canvas size and page viewer div size
        const current = pagesCanvas.current[`page_${activePageNumber}`];
        if (current && containerWidth && containerHeight) {
          // Round to avoid tiny pixel difference between canvas
          const newContainerWidth = Math.round(Math.min(containerHeight * 0.95 * (current.width / current.height), containerWidth) / 10) * 10;
          // Avoid infinite re-render
          if (newContainerWidth !== containerWidth) setPageDisplayWidth(newContainerWidth);
        }
        break;
      case PageZoom.FIT_TO_WIDTH:
        // Maximize width
        setPageDisplayWidth(containerWidth);
        break;
      case PageZoom.DEFAULT:
        // Default tradeoff: not too large but as large as possible
        setPageDisplayWidth(containerWidth ? Math.min(containerWidth, MAX_DOCUMENT_WIDTH) : MAX_DOCUMENT_WIDTH);
    }
  };

  // Listen to PageUp and PageDown to focus previous/next page instantly
  const handlePageKeyboard = (event: KeyboardEvent) => {
    if (event.key === "PageUp") {
      event.preventDefault();
      const prevKey = `page_${activePageNumber - 1}`;
      const previousActiveCanvas = pagesAnnotationsCanvas.current[prevKey];
      if (previousActiveCanvas) {
        previousActiveCanvas.scrollIntoView({ behavior: "instant" });
        setActivePageNumber(Math.max(0, activePageNumber - 1));
      }
    } else if (event.key === "PageDown") {
      event.preventDefault();
      const nextKey = `page_${activePageNumber + 1}`;
      const nextActiveCanvas = pagesAnnotationsCanvas.current[nextKey];
      if (nextActiveCanvas && labellingState.currentDocument && labellingState.currentDocument.meta.num_pages) {
        nextActiveCanvas.scrollIntoView({ behavior: "instant" });
        setActivePageNumber(Math.min(labellingState.currentDocument.meta.num_pages, activePageNumber + 1));
      }
    }
  };

  // Load OCR for a page
  const loadOCR = async (canvas: HTMLCanvasElement, pageIndex: number) => {
    if (!currentDocument) return;

    try {
      // Check if we have already retrieved the OCR for this page
      let page: any = null;

      if (pagesOCR.current[`page_${pageIndex}`]) page = pagesOCR.current[`page_${pageIndex}`];
      // If not, we call the API to retrieve it
      else {
        try {
          page = await documentsServices.getDocumentPageLayout(currentDocument._id, pageIndex);
          // Keep OCR in local ref for later use
          pagesOCR.current[`page_${pageIndex}`] = page;
        } catch (e) {
          console.warn(`Load OCR for page ${pageIndex} failed`);
          console.warn(e);
          return; // FIXME: Do we need this?
        }
      }

      // Save pages dimensions / rotation information
      dispatch(
        addPageMetadata({
          page: pageIndex,
          width: page.width,
          height: page.height,
          rotationAngle: getPageRotation(page.angle),
        })
      );

      pagesWords.current[`page_${pageIndex}`] = [];

      page.words.forEach((word: AzureOcrWord) => {
        const bbox = {
          x0: word.polygon[0].x,
          x1: word.polygon[2].x,
          y0: word.polygon[0].y,
          y1: word.polygon[2].y,
        };

        pagesWords.current[`page_${pageIndex}`].push({
          page: page.pageNumber,
          content: word.content,
          bbox: bbox,
          confidence: word.confidence,
        });
      });
    } catch (e) {
      console.warn(`Load OCR for page ${pageIndex} failed`);
      console.warn(e);
    }
  };

  // Get current mouse position
  // Note: we have to convert the position to match the real canvas dimensions
  const getMousePos = (evt: MouseEvent, pageIndex: number) => {
    let canvas = pagesAnnotationsCanvas.current[`page_${pageIndex}`];
    if (!canvas) return { x: 0, y: 0 };

    const rect = canvas.getBoundingClientRect();

    let x = ((evt.clientX - rect.left) * canvas.width) / rect.width;
    let y = ((evt.clientY - rect.top) * canvas.height) / rect.height;
    return { x, y };
  };

  // Listen mouse move
  const onMouseMove = (evt: MouseEvent, pageIndex: number) => {
    const { x, y } = getMousePos(evt, pageIndex);
    // If there is already another selectionZone active on another page, stop current selection and reset canvas
    if (infoRect.current.active && infoRect.current.pageIndex !== pageIndex) {
      infoRect.current.active = false;
      clearCanvas(infoRect.current.pageIndex);
      // Re-draw mapped fields
      drawMappedFields(infoRect.current.pageIndex);
      return;
    }

    infoRect.current.w = Number(x) - infoRect.current.x;
    infoRect.current.h = Number(y) - infoRect.current.y;
    infoRect.current.mouseX = evt.clientX;
    infoRect.current.mouseY = evt.clientY;

    if (infoRect.current.active) {
      const canvas = clearCanvas(pageIndex);
      if (canvas === null) {
        return;
      }

      // Draw the infoRect
      drawWordOverlay(canvas, infoRect.current, "#f0f2f1", 1, true);

      // If we arrive here, then a text selection is in progress
      const selectedWords = getWordsInSelectedZone(pageIndex);

      const pageMetadata = labellingState.pagesMetadata.find((pm) => pm.page === pageIndex);
      if (!pageMetadata) return;

      selectedWords.forEach((word) => {
        const scaledBbox = convertBboxToCanvasScale(canvas, word.bbox, pageMetadata);
        drawWordOverlay(canvas, convertOcrBoxToSelectionZoneRectangle(scaledBbox), "#5abced", 0);
      });

      // Draw table area if needed
      drawTableArea(pageIndex);
    }

    // Check if the cursor is inside an actionable area
    if (isInside({ x: Number(x), y: Number(y) }, labellingState.focusedTable?.annotationZone?.activeRowButtonCoordinates)) {
      // Transform cursor into pointer
      document.body.style.cursor = "pointer";
    } else {
      // Transform cursor back to default
      document.body.style.cursor = "default";
    }

    return;
  };

  // When the user push left click of the mouse, then we open the inforect
  const onMouseDown = (evt: MouseEvent, pageIndex: number) => {
    const { x, y } = getMousePos(evt, pageIndex);
    infoRect.current.x = Number(x);
    infoRect.current.y = Number(y);

    infoRect.current.pageIndex = pageIndex;
    infoRect.current.active = true;
  };

  // When the user release the mouse, then we trigger the onSelect callback.
  const onMouseUp = (evt: any, pageIndex: number) => {
    if (!infoRect.current.active) return;

    const { x, y } = getMousePos(evt, pageIndex);
    infoRect.current.active = false;
    infoRect.current.w = Number(x) - infoRect.current.x;
    infoRect.current.h = Number(y) - infoRect.current.y;

    clearCanvas(pageIndex);

    // If there is no docType selected, don't trigger anything on mouseUp
    if (currentDocument && currentDocument.doc_type && currentDocument.status !== DocumentStatus.Processing) {
      // Retrieve selected words within the zone
      let words = getWordsInSelectedZone(pageIndex);
      let mappedFields = getMappedFieldsForPage(currentDocument, pageIndex).flat();

      // Check if some words selected are already mapped in another field
      // If it's the case, then we trigger an error
      let isAnyWordAlreadyMapped = false;
      if (mappedFields?.length > 0) {
        for (const word of words) {
          const sameWord = mappedFields.find(
            (f: IDocumentMappedField) =>
              // Check with focusedField
              ((labellingState.focusedField &&
                (labellingState.focusedField.technicalName !== f.field_id || (labellingState.focusedField.technicalName === f.field_id && labellingState.focusedField.row !== f.row))) ||
                // Checks in focusedTable mode
                (labellingState.focusedTable?.focusedCell &&
                  (labellingState.focusedTable.focusedCell.cell.technicalName !== f.field_id ||
                    (labellingState.focusedTable.focusedCell.cell.technicalName === f.field_id && labellingState.focusedTable.focusedCell.row !== f.row))) ||
                // Checks in focusedColumn mode
                (labellingState.focusedTable?.focusedColumn && labellingState.focusedTable.focusedColumn.column.technicalName !== f.field_id)) &&
              f.mapping?.find((w) => {
                return w.page === word.page && w.bbox.x0 === word.bbox.x0 && w.bbox.x1 === word.bbox.x1 && w.bbox.y0 === word.bbox.y0 && w.bbox.y1 === word.bbox.y1;
              })
          );

          if (sameWord && (labellingState.focusedField || labellingState.focusedTable?.focusedCell?.edition || labellingState.focusedTable?.focusedColumn)) {
            isAnyWordAlreadyMapped = true;
            let errorMessage = `${t("labelling_panel.toaster_failed_mapping_already_mapped")} ${t(`documentType.${sameWord.field_id}`, `${sameWord.field_id}`)} ${typeof sameWord.row === "number" && Number.isInteger(sameWord.row) ? `- ${t("mapping_popup.table_row", { row: sameWord.row + 1 })}` : ""
              }`;
            toast.error(errorMessage);
            break;
          }
        }
      }

      // If some words are selected and none are already mapped, then proceed
      if (!isAnyWordAlreadyMapped && words.length > 0) {
        // Dispatch the selected zone into redux state

        // If we are in table column mapping mode
        if (labellingState.focusedTable?.focusedColumn) dispatch(addColumnMappingWords(words));
        // Note: if a field is focused, the mapping will be automatically saved for this field
        else {
          // If a field is focused, the mapping will be automatically saved for this field
          if (labellingState.focusedField || labellingState.focusedTable?.focusedCell?.edition) {
            dispatch(selectMappingWords(words));
          }
          // Else, if one field is already mapped with the selection, focus it
          else {
            const mappedWord = mappedFields?.find((f: IDocumentMappedField) =>
              f.mapping?.find((w) => {
                return w.page === words[0].page && w.bbox.x0 === words[0].bbox.x0 && w.bbox.x1 === words[0].bbox.x1 && w.bbox.y0 === words[0].bbox.y0 && w.bbox.y1 === words[0].bbox.y1;
              })
            );
            // If a mapped word has been found on this position, focus the associated field
            if (mappedWord) {
              if (mappedWord.table) {
                const tableField = currentDocType?.fields.find((f) => f.technicalName === mappedWord.table && f.type === DocumentTypeFieldType.table);
                if (tableField) {
                  const column = tableField.columns?.find((c) => c.technicalName === mappedWord.field_id);
                  if (column && mappedWord.row !== undefined) {
                    dispatch(setFocusedTable(tableField));
                    let tableFocusedCell: TableFocusedCell = { cell: column, row: mappedWord.row, edition: false };
                    dispatch(setFocusedTableCell(tableFocusedCell));
                  }
                }
              } else {
                const field = currentDocType?.fields.find((f) => f.technicalName === mappedWord.field_id);
                if (field) dispatch(setFocusedField({ field }));
              }
            }
            // Else just trigger the classic words selection (and annotation modal display)
            else {
              dispatch(selectMappingWords(words));
            }
          }
        }
      }
    }

    // Detect that cursor is inside the row button and trigger next row action
    if (isInside({ x: Number(x), y: Number(y) }, labellingState.focusedTable?.annotationZone?.activeRowButtonCoordinates)) {
      const focusedTable = labellingState.focusedTable?.table.technicalName ?? null;
      const documentTable = focusedTable ? labellingState.currentDocument?.extraction?.data[focusedTable] : null;
      // Check if the target row already exists
      // If not, don't do anything to avoid having to create a new row in the table from here
      // WIP: Do it with the unified state
      if (documentTable && labellingState.focusedTable?.annotationZone && labellingState.focusedTable?.annotationZone?.activeRow + 1 < documentTable.value.length) {
        dispatch(setTableAnnotationZoneActiveRow((labellingState.focusedTable.annotationZone.activeRow ?? 0) + 1));
      }
    }

    // Re-draw mapped fields
    drawMappedFields(pageIndex);
    drawSelectedMappingWords(pageIndex);
    drawTableArea(pageIndex);
  };

  // Draw all mapped fields on the canvas
  const drawMappedFields = (pageIndex: number) => {
    if (!currentDocument) return;
    const refKeyPageIndex = `page_${pageIndex}`;

    let canvas = pagesAnnotationsCanvas.current[refKeyPageIndex];
    if (!canvas) return;

    const pageMetadata = labellingState.pagesMetadata.find((pm) => pm.page === pageIndex);
    if (!pageMetadata) return;

    let allMappedFields = getMappedFieldsForPage(currentDocument, pageIndex);
    if (allMappedFields.length === 0) return;

    for (let field of allMappedFields) {
      if (!field.mapping) continue;
      for (const word of field.mapping) {
        if (!word.bbox || word.page !== pageIndex) continue;

        // Check if a field is currently focused
        let isFieldFocused = false;
        // #1 Key value field behaviour i.e. original simple behaviour
        if (labellingState.focusedField && !field.table) {
          isFieldFocused = labellingState.focusedField.technicalName === field.field_id;
        }
        // #2 Table annotation cell behaviour i.e. need to get table id and row number to check if the field is focused
        else if (labellingState.focusedTable?.focusedCell) {
          isFieldFocused = labellingState.focusedTable.focusedCell.cell.technicalName === field.field_id && labellingState.focusedTable.focusedCell.row === field.row;
        }
        // #3 Column mapping behaviour i.e. focus all fields of the current column focused
        else if (labellingState.focusedTable?.focusedColumn) {
          isFieldFocused = labellingState.focusedTable.focusedColumn.column.technicalName === field.field_id && field.row !== undefined;
        }

        const mappingColor = isFieldFocused ? ANNOTATION_ORIGIN_COLORS.HIGHLIGHTING : getColorForFieldOrigin(field.origin, field.row);
        const forceOpaque = isFieldFocused;
        const lineWidth = isFieldFocused ? 2 : 1;

        const scaledBbox = convertBboxToCanvasScale(canvas, word.bbox, pageMetadata);
        drawWordOverlay(canvas, convertOcrBoxToSelectionZoneRectangle(scaledBbox), mappingColor, lineWidth, forceOpaque);
      }
    }
  };

  // Draw selected mapping words (if needed)
  const drawSelectedMappingWords = (pageIndex: number) => {
    const refKeyPageIndex = `page_${pageIndex}`;
    let canvas = pagesAnnotationsCanvas.current[refKeyPageIndex];
    const pageMetadata = labellingState.pagesMetadata.find((pm) => pm.page === pageIndex);

    if (!canvas || !pageMetadata) return;

    // If there is no focused field but selected words, we display them
    if (!labellingState.focusedField && !labellingState.focusedTable?.focusedCell && labellingState.selectedMappingWords && labellingState.selectedMappingWords.length > 0) {
      // Highlight selected words
      // We are on the canvas of a particular page we will then select only the words of this page to display
      const selectedMappingWordsInPage = labellingState.selectedMappingWords.filter((word) => word.page === pageIndex);
      for (const word of selectedMappingWordsInPage) {
        const mappingColor = getColorForFieldOrigin(DocumentFieldOrigin.Manual);
        const scaledBbox = convertBboxToCanvasScale(canvas, word.bbox, pageMetadata);
        drawWordOverlay(canvas, convertOcrBoxToSelectionZoneRectangle(scaledBbox), mappingColor, 1, true);
      }

      // Note: An annotation modal will be automatically displayed based on the selected words
    }

    // If we are in column mapping mode
    if (labellingState.focusedTable?.focusedColumn) {
      // Highlight selected words
      // We are on the canvas of a particular page we will then select only the words of this page to display
      for (const words of labellingState.focusedTable.focusedColumn.selectedMappingWords) {
        for (const word of words) {
          if (word.page !== pageIndex) continue;

          const mappingColor = getColorForFieldOrigin(DocumentFieldOrigin.Manual);
          const scaledBbox = convertBboxToCanvasScale(canvas, word.bbox, pageMetadata);
          drawWordOverlay(canvas, convertOcrBoxToSelectionZoneRectangle(scaledBbox), mappingColor, 1, true);
        }
      }
    }
  };

  // Draw table annotation zone (if enabled)
  const drawTableArea = (pageIndex: number) => {
    if (currentDocument && labellingState.focusedTable?.annotationZone && labellingState.focusedTable.annotationZone.pageIndex === pageIndex) {
      const refKeyPageIndex = `page_${labellingState.focusedTable.annotationZone.pageIndex}`;
      let canvas = pagesAnnotationsCanvas.current[refKeyPageIndex];
      if (canvas) {
        // Check if canvas width has changed since the last table zone
        // To avoid complex rescaling of table zone, we reset table zone
        // And force the user to draw it again
        if (canvas.width !== labellingState.focusedTable.annotationZone.originalCanvasWidth) {
          toast.error(t("page_viewer.canvas_width_changed"));
          dispatch(setTableAnnotationZone(null));
        }
        let ctx = canvas?.getContext("2d") || null;
        if (ctx) {
          const { x, y, w, h } = {
            x: labellingState.focusedTable.annotationZone.position.x,
            y: labellingState.focusedTable.annotationZone.position.y,
            w: labellingState.focusedTable.annotationZone.position.w,
            h: labellingState.focusedTable.annotationZone.position.h,
          };
          // Draw rectangle to hide page area not in table area
          // Transparent white
          ctx.fillStyle = "#ffffffE6";
          // Draw in top, bottom, left then right
          // Adapt coordinates to mouse movement (down-right, up-right, down-left, up-left)
          ctx.fillRect(0, 0, canvas.width, Math.min(y, y + h));
          ctx.fillRect(0, Math.max(y, y + h), canvas.width, canvas.height - Math.max(y, y + h));
          ctx.fillRect(0, Math.min(y, y + h), Math.min(x, x + w), Math.abs(h));
          ctx.fillRect(Math.max(x, x + w), Math.min(y, y + h), canvas.width - Math.max(x, x + w), Math.abs(h));

          // Draw boundaries of the table with orange dashed lines
          const lineWidth = 2;
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = "#facc15";
          ctx.setLineDash([10, 5]);
          ctx.beginPath();
          ctx.rect(x - lineWidth, y - lineWidth, w + lineWidth * 2, h + lineWidth * 2);
          ctx.stroke();

          // Implement action rectangles in canvas
          // Draw active row cursor
          const activeRowRect = labellingState.focusedTable.annotationZone.activeRowButtonCoordinates;
          if (activeRowRect) {
            // Draw rectangle
            // Get alternate table row colors
            const color = getColorForFieldOrigin(DocumentFieldOrigin.Mapping, labellingState.focusedTable.annotationZone.activeRow);
            // Draw rectangle
            ctx.fillStyle = `${color}90`;
            ctx.fillRect(activeRowRect.x, activeRowRect.y, activeRowRect.w, activeRowRect.h);
            // Draw borders
            ctx.lineWidth = 2;
            ctx.setLineDash([]);
            ctx.strokeStyle = color;
            ctx.beginPath();
            ctx.rect(activeRowRect.x, activeRowRect.y, activeRowRect.w, activeRowRect.h);
            ctx.stroke();

            // Draw text
            ctx.font = `bold ${activeRowRect.w / 2}px helvetica`;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "black";
            ctx.fillText(`${labellingState.focusedTable.annotationZone.activeRow + 1}`, activeRowRect.x + activeRowRect.w / 2, activeRowRect.y + activeRowRect.h / 2);
          }

          // Draw columns headers above tableArea based one columns centers coordinates
          if (labellingState.focusedTable.table.technicalName) {
            // This returns the center of gravity for each column of the table
            const columnsCenters = getColumnsCentersForPageForTable(currentDocument, pageIndex, labellingState.focusedTable.table.technicalName);
            const pageMetadata = labellingState.pagesMetadata.find((pm) => pm.page === pageIndex);
            if (columnsCenters && pageMetadata) {
              // Scale position based on canvas dimensions
              const scaledColumnsCenters: OcrTableRowsCenters = {};
              for (const column in columnsCenters) {
                scaledColumnsCenters[column] = convertColumnsCentersToCanvasScale(canvas, columnsCenters[column], pageMetadata);
              }
              // Normalize the scaled columns centers
              // This means we re-compute x coordinate when column headers are too close to each other
              const normalizedScaledColumnsCenters = normalizeScaledColumnsCenters(scaledColumnsCenters, canvas);

              // Draw column headers
              for (const column in normalizedScaledColumnsCenters) {
                // Create a restore point for the current context
                // In order to get back here after scrambling it with rotation and translation
                ctx.save();

                // Translate and rotate the context to write text at 45°
                // The origin (0, 0) of the context is shifted where we want to write
                ctx.translate(normalizedScaledColumnsCenters[column].x, labellingState.focusedTable.annotationZone.position.y - 20);
                ctx.rotate(-Math.PI / 4);

                // Draw text
                ctx.fillStyle = "black";
                // WIP: Responsive font size
                const fontSize = canvas.width / 60;
                ctx.font = `bold ${fontSize}px courier`;
                ctx.textAlign = "left";
                // Write at the origin (0, 0) of the translated context
                ctx.fillText(`${t(`documentType.${column}`, column)}`, 0, 0);

                // Go back to saved context above to reset translation and rotation
                ctx.restore();
              }
            }
          }
        }
      }
    }
  };

  const isInside = (pos: Coord, rect: SelectionZoneRectangle | null | undefined): boolean => {
    if (!rect) return false;
    return pos.x >= rect.x && pos.x <= rect.x + rect.w && pos.y >= rect.y && pos.y <= rect.y + rect.h;
  };

  // Function that draw a Rectangle based on a position
  const drawWordOverlay = (canvas: HTMLCanvasElement | null, rect: SelectionZoneRectangle, color: string, lineWidth: number = 1, forceOpaque: Boolean = false) => {
    let ctx = canvas?.getContext("2d") || null;
    if (!ctx) return null;

    const { x, y, w, h } = rect;

    ctx.fillStyle = forceOpaque ? `${color}90` : labellingState.focusedField ? `${color}2A` : `${color}40`;
    ctx.fillRect(x, y, w, h);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = labellingState.focusedField && !forceOpaque ? `${color}BB` : color;
    ctx.setLineDash([]);
    ctx.beginPath();
    ctx.rect(x - lineWidth, y - lineWidth, w + lineWidth * 2, h + lineWidth * 2);
    ctx.stroke();
  };

  // Remove all rect drawn on the canvas
  const clearCanvas = (pageIndex: number) => {
    let canvas = pagesAnnotationsCanvas.current[`page_${pageIndex}`];
    if (canvas) {
      canvas.getContext("2d")?.clearRect(0, 0, canvas.width, canvas.height);
    }
    return canvas;
  };

  // Get all words currently selected by the user with the mapping zone
  const getWordsInSelectedZone = (pageIndex: number): OcrWord[] => {
    // The key to find data in the Ref dict
    const refKeyPageIndex = `page_${pageIndex}`;

    const canvas = pagesAnnotationsCanvas.current[refKeyPageIndex];
    const pageWords = getPagesWords(pageIndex);
    const pageMetadata = labellingState.pagesMetadata.find((pm) => pm.page === pageIndex);

    if (!canvas || !pageMetadata) return [];

    const location: OcrBbox = {
      x0: infoRect.current.x,
      x1: infoRect.current.x + infoRect.current.w,
      y0: infoRect.current.y,
      y1: infoRect.current.y + infoRect.current.h,
    };

    // reset coordinates of bounding box if inverted:
    if (location.x0 > location.x1) [location.x0, location.x1] = [location.x1, location.x0];
    if (location.y0 > location.y1) [location.y0, location.y1] = [location.y1, location.y0];

    // Find words in this zone
    let words = [];
    for (const word of pageWords) {
      const scaledBbox = convertBboxToCanvasScale(canvas, word.bbox, pageMetadata);
      if (scaledBbox && !(scaledBbox.x0 > location.x1 || scaledBbox.x1 < location.x0 || scaledBbox.y0 > location.y1 || scaledBbox.y1 < location.y0)) {
        words.push(word);
      }
    }

    return words;
  };

  // Convert bbox to document scale
  const convertBboxToCanvasScale = (canvas: HTMLCanvasElement, bbox: OcrBbox, pageMetadata: PageMetadata) => {
    let { width, height } = pageMetadata;
    if (!(width * height > 0)) throw new Error(`Invalid page size ${pageMetadata}`);

    //console.log("--------------");
    //console.log("Page width", width, " / Page height", height);
    //console.log("Canvas width", canvas.width, " / Canvas height", canvas.height);
    //console.log("Page metadata", pageMetadata)
    //console.log("Source bbox", bbox);

    let rotatedBbox = rotateBbox(bbox, width, height, -pageMetadata.rotationAngle);
    //console.log("Rotated bbox", rotatedBbox);

    // If there is a rotation of 90 or 270 degrees, we need to switch layout width and height
    if (Math.abs(pageMetadata.rotationAngle) === 90 || Math.abs(pageMetadata.rotationAngle) === 270) [width, height] = [height, width];

    let scaledBbox = {
      x0: (rotatedBbox.x0 * canvas.width) / width,
      x1: (rotatedBbox.x1 * canvas.width) / width,
      y0: (rotatedBbox.y0 * canvas.height) / height,
      y1: (rotatedBbox.y1 * canvas.height) / height,
    };

    if (scaledBbox.x0 > scaledBbox.x1) [scaledBbox.x0, scaledBbox.x1] = [scaledBbox.x1, scaledBbox.x0];
    if (scaledBbox.y0 > scaledBbox.y1) [scaledBbox.y0, scaledBbox.y1] = [scaledBbox.y1, scaledBbox.y0];

    //console.log("Scaled bbox", scaledBbox);

    return scaledBbox;
  };

  const convertColumnsCentersToCanvasScale = (canvas: HTMLCanvasElement, coord: Coord, pageMetadata: PageMetadata) => {
    let { width, height } = pageMetadata;
    if (!(width * height > 0)) throw new Error(`Invalid page size ${pageMetadata}`);
    let rotatedCoord = rotateCoord(coord, width, height, pageMetadata.rotationAngle);
    // If there is a rotation of 90 or 270 degrees, we need to switch layout width and height
    if (Math.abs(pageMetadata.rotationAngle) === 90 || Math.abs(pageMetadata.rotationAngle) === 270) [width, height] = [height, width];
    return {
      x: (rotatedCoord.x * canvas.width) / width,
      y: (rotatedCoord.y * canvas.height) / height,
    };
  };

  // Normalize means adapt the x coordinate of the columns headers so that they are not too close to each other
  const normalizeScaledColumnsCenters = (columnsCenters: OcrTableRowsCenters, canvas: HTMLCanvasElement): OcrTableRowsCenters => {
    const sortedColumnsCenters = Object.fromEntries(Object.entries(columnsCenters).sort((a, b) => a[1].x - b[1].x));
    const columnsEntries = Object.entries(sortedColumnsCenters);
    for (let i = 0; i < columnsEntries.length - 1; i++) {
      let currentX = columnsEntries[i][1].x;
      let nextX = columnsEntries[i + 1][1].x;

      // If the next 'x' is too close to the current 'x', adjust it
      if (nextX - currentX < canvas.width / 80) {
        // Nominal canvas width is 800px => 10px distance in full width
        columnsEntries[i + 1][1].x = currentX + canvas.width / 40; // Move 5px to the right in full width
      }
    }
    let normalizedScaledColumnsCenters: OcrTableRowsCenters = {};

    for (const [key, value] of columnsEntries) {
      normalizedScaledColumnsCenters[key] = value;
    }
    return normalizedScaledColumnsCenters;
  };

  /**
   * Computes the coordinates of the active row in a table based on the row coordinates, canvas size, and table annotation zone.
   * Triggers when no bboxes are available for the active row.
   *
   * @param {(OcrBbox | null)[]} rowCoordinates - An array of row coordinates, where each element is either an OcrBbox object or null.
   * null means empty row or only MANUAL annotations
   * @param {HTMLCanvasElement} canvas - The canvas element on which the table is drawn.
   * @param {PageMetadata} pageMetadata - The metadata of the page containing the table.
   * @return {Position | null} The coordinates of the active row, or null if the active row is not within the table area.
   */
  const computeActiveRowCoordinates = (rowCoordinates: (OcrBbox | null)[], canvas: HTMLCanvasElement, pageMetadata: PageMetadata): Position | null => {
    if (rowCoordinates && rowCoordinates.length > 2 && labellingState.focusedTable?.annotationZone?.position) {
      // Handle x axis
      // Active row on the right
      // X-axis: On the right of the table area OR on the right of the canvas
      const { x, w } = labellingState.focusedTable.annotationZone.position;
      const activeRowRectX = Math.min(Math.max(x, x + w) + ACTIVE_ROW_OFFSET, canvas.width - ACTIVE_ROW_SIZE);
      // // Active row on the left
      // X-axis: On the left of the table area OR on the left of the canvas
      // const activeRowRectX = Math.max((Math.min(x, x + w) - (ACTIVE_ROW_SIZE + ACTIVE_ROW_OFFSET)), 0);

      // Get first & last row with actual coordinates
      const firstRowWithMappingIndex = rowCoordinates.findIndex((row) => row);
      const lastRowWithMappingIndex = rowCoordinates.findLastIndex((row) => row);
      if (firstRowWithMappingIndex !== -1 && lastRowWithMappingIndex !== -1) {
        // Get y position for both rows
        const { y0: firstY0, y1: firstY1 } = rowCoordinates[firstRowWithMappingIndex]!;
        const originY = (firstY0 + firstY1) / 2;
        const { y0: lastY0, y1: lastY1 } = rowCoordinates[lastRowWithMappingIndex]!;
        const lastY = (lastY0 + lastY1) / 2;
        // Compute the average distance between rows based on first and last row
        const averageY = (lastY - originY) / lastRowWithMappingIndex;
        // Active row is x times the average distance from origin
        const offset = labellingState.focusedTable.annotationZone.activeRow - firstRowWithMappingIndex;
        const activeRowY = originY + averageY * offset;
        // Scale to canvas
        const scaledActiveRowY = (activeRowY * canvas.height) / pageMetadata.height;

        if (
          scaledActiveRowY <= labellingState.focusedTable.annotationZone.position.y + labellingState.focusedTable.annotationZone.position.h &&
          scaledActiveRowY >= labellingState.focusedTable.annotationZone.position.y
        ) {
          // Offset the y position by half the size of the active row
          return { x: activeRowRectX, y: scaledActiveRowY - ACTIVE_ROW_SIZE / 2, w: ACTIVE_ROW_SIZE, h: ACTIVE_ROW_SIZE };
        }
      }
    }
    return null;
  };

  /**
   * Converts an OCR bounding box (x0, y0, x1, y1) to a selection zone rectangle (x, y, w, h).
   *
   * @param {OcrBbox} bbox - The OCR bounding box to convert.
   * @return {SelectionZoneRectangle} The converted selection zone rectangle.
   */
  const convertOcrBoxToSelectionZoneRectangle = (bbox: OcrBbox): SelectionZoneRectangle => {
    const rect: SelectionZoneRectangle = {
      x: bbox.x0,
      y: bbox.y0,
      w: bbox.x1 - bbox.x0,
      h: bbox.y1 - bbox.y0,
    };
    return rect;
  };

  const haveEqualCoordinates = (a: Position | null, b: Position | null): boolean => {
    return a?.x === b?.x && a?.y === b?.y && a?.w === b?.w && a?.h === b?.h;
  };

  const extractMappings = (documentField: IDocumentField): OcrWord[] => {
    const mappings: OcrWord[] = [];

    documentField.value.forEach((item: { [key: string]: IDocumentField }) => {
      Object.values(item).forEach((field) => {
        if (field.mapping) {
          mappings.push(...field.mapping);
        }
      });
    });

    return mappings;
  };
  const onPdfScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (labellingState.currentDocument && labellingState.currentDocument.meta.num_pages) {
      // Calculate the active page "float" index
      const p = (labellingState.currentDocument.meta.num_pages * e.currentTarget.scrollTop) / e.currentTarget.scrollHeight + 1;
      // Round to the nearest page
      if (p !== activePageNumber) setActivePageNumber(Math.round(p));
    }
  };

  const handlePageZoomClick = (zoom: PageZoom) => {
    setPageZoom(zoom);
    localStorage.setItem("docloop.pageZoom", zoom.toString());
  };

  // Download source PDF
  const handleDocumentDownload = async () => {
    if (!currentDocument) return;
    const doc = await documentsServices.getDocument(currentDocument._id);
    if (doc) {
      const link = document.createElement("a");
      link.href = doc.file_url!;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  //
  // Rendering
  //
  return (
    <>
      <div id="page-viewer" onScroll={onPdfScroll} className={"bg-gray-500 relative z-0 flex grow flex-col p-3 items-center overflow-x-hidden overflow-y-scroll h-full"} ref={setContainerRef}>
        {currentDocument && currentDocumentFileUrl && currentDocumentLayoutMeta && (
          <div className={"w-full px-2 "}>
            <Document
              className={"flex flex-col items-center w-full px-2"}
              file={currentDocumentFileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loading className="bg-white px-10 py-6 rounded-md shadow-md mt-10" />}
            >
              {Array.from(new Array(numPages || 0), (el, index) => {
                // if numPages is undefined put 0
                const pageNumber = index + 1;
                return (
                  <CustomPage
                    key={`page-${pageNumber}`}
                    pageNumber={pageNumber}
                    pagesCanvas={pagesCanvas}
                    pageZoom={pageZoom}
                    pageDisplayWidth={pageDisplayWidth}
                    renderingOrientation={pagesRenderingOrientation.current[`page_${pageNumber}`] ?? 0}
                    onPageVisible={() => {
                      if (!pagesAnnotationsCanvas.hasOwnProperty(`page_${pageNumber}`)) {
                        initAnnotationCanvas(pageNumber);
                      }
                    }}
                  />
                );
              })}
            </Document>
          </div>
        )}

        <AnnotationModal selectedZone={infoRect.current} />
      </div>
      {numPages && (
        <div className="absolute top-1 right-5 text-right" style={{ textShadow: "1px 1px 1px #FFFFFFB3" }}>
          {!disableAnnotation ? (
            <div className="mb-0.5 block">
              <Button
                className={`${pageZoom === PageZoom.DEFAULT ? "bg-green-700" : ""}`}
                leftIcon={<TbZoomReplace className="inline text-lg" />}
                tooltip={t("labelling_panel.zoom_default")}
                text=""
                onClick={() => handlePageZoomClick(PageZoom.DEFAULT)}
                color={ButtonStyle.Saving}
                small={true}
              />
              <Button
                className={`${pageZoom === PageZoom.FIT_TO_PAGE ? "bg-green-700" : ""} ml-1`}
                leftIcon={<MdOutlineZoomInMap className="inline text-lg" />}
                tooltip={t("labelling_panel.zoom_fit_page")}
                text=""
                onClick={() => handlePageZoomClick(PageZoom.FIT_TO_PAGE)}
                color={ButtonStyle.Saving}
                small={true}
              />
              <Button
                className={`${pageZoom === PageZoom.FIT_TO_WIDTH ? "bg-green-700" : ""} ml-1`}
                leftIcon={<MdOutlineZoomOutMap className="inline text-lg" />}
                tooltip={t("labelling_panel.zoom_fit_width")}
                text=""
                onClick={() => handlePageZoomClick(PageZoom.FIT_TO_WIDTH)}
                color={ButtonStyle.Saving}
                small={true}
              />
            </div>
          ) : (
            <div className="mb-0.5 block">
              <Button
                leftIcon={<FiDownload className="inline text-lg" />}
                tooltip={t("labelling_panel.document_download")}
                text=""
                onClick={() => handleDocumentDownload()}
                color={ButtonStyle.Saving}
                small={true}
              />
            </div>
          )}
          <div className="bg-white bg-opacity-80 rounded-sm text-xs mx-auto px-1 py-0.5 shadow clear-both absolute right-0 -bottom-[22px] select-none">
            {activePageNumber} / {numPages}
          </div>
        </div>
      )}
    </>
  );
};

// Custom page component that use react-intersection-observer
// This observer will allow us to detect when the page is displayed on the screen and trigger OCR loading
// Note: this is a requirement to prevent the load of all pages layout at page loading
type CustomPageProps = {
  pageNumber: number;
  pagesCanvas: React.MutableRefObject<{ [key: string]: HTMLCanvasElement | null }>;
  pageZoom: PageZoom;
  pageDisplayWidth: number | undefined;
  renderingOrientation: number;

  onPageVisible: () => void;
};
const CustomPage: React.FC<CustomPageProps> = ({ pageNumber, pagesCanvas, pageZoom, pageDisplayWidth, renderingOrientation, onPageVisible }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { pagesMetadata } = useAppSelector((state) => state.labelling);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && isLoaded) onPageVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, isLoaded, pageNumber]);

  // Based on current pageMetadata and ReactPDF component autoRotation, calculcate the real rotation needed
  const currentPageMetadata = pagesMetadata.find((pm) => pm.page === pageNumber);
  let rotation = 0;
  if (currentPageMetadata) {
    if (renderingOrientation === 0) rotation = -currentPageMetadata.rotationAngle;
    else rotation = renderingOrientation - currentPageMetadata.rotationAngle;
  }

  //console.log(`Custom page rotation ${currentPageMetadata?.rotationAngle} / Rendering orientation: ${renderingOrientation} / Final page orientation: ${rotation}`);
  return (
    <div ref={ref}>
      <Page
        canvasRef={(ref) => {
          pagesCanvas.current[`page_${pageNumber}`] = ref;
        }}
        className={"bg-white shadow-lg mx-2 my-2 relative z-0"}
        key={`page_${pageNumber}`}
        pageNumber={pageNumber}
        renderTextLayer={false}
        onLoadSuccess={() => setIsLoaded(true)}
        width={pageDisplayWidth}
        rotate={rotation}
      />
    </div>
  );
};
