import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocument } from "models/document";
import { IDocumentType } from "models/document_type";
import { IJobFile } from "models/jobfile";

import DocumentService from "services/documents.service";
import { loadJobfileAndDocuments } from "./jobfile";
import { MAX_UPLOAD_SIZE, MAX_UPLOAD_FILES_NB } from "config/constants";
import { t } from "i18next";

const JSZip = require("jszip");

export type UploadDocumentsProgression = {
  uploading: boolean;
  filesCount: number;
  filesUploaded: number;
  fileInProgress?: string;
};

export type DocumentsState = {
  documentsTypes: IDocumentType[]; // List of all available documents types in Docloop

  uploadProgression: UploadDocumentsProgression;
};

export const initialState: DocumentsState = {
  documentsTypes: [],
  uploadProgression: { uploading: false, filesCount: 0, filesUploaded: 0 },
};

export const uploadDocuments = createAsyncThunk("documents/uploadDocuments", async ({ files, jobFile }: { files: File[]; jobFile?: IJobFile }, { dispatch }) => {
  // Init initial state
  let filesUploaded = 0;
  let lastDocUploaded: IDocument | null = null;
  dispatch(updateUploadProgression({ uploading: true, filesCount: files.length, filesUploaded }));

  const validFiles: File[] = [];
  // List all valid attachments
  // This requires to recursively handle .zip archives
  for (const file of files) {
    if (file.type === "application/pdf") {
      validFiles.push(file);
    } else if (file.type === "application/x-zip-compressed") {
      const pdfAttachments = await getPdfFilesFromArchive(file);
      validFiles.push(...pdfAttachments);
    }
  }

  // File size and file number verification are done again in case of archives being processed
  if (validFiles.reduce((acc, file) => acc + file.size, 0) > MAX_UPLOAD_SIZE) {
    // Max upload exceeded
    throw new Error(t("document_upload_size_exceeded") + ` ${Math.round(MAX_UPLOAD_SIZE / 1024 / 1024 * 10) / 10} MB.`)
  } else if (validFiles.length > MAX_UPLOAD_FILES_NB) {
    // Max number of files exceeded
    throw new Error(t("document_upload_file_number_exceeded") + ` ${MAX_UPLOAD_FILES_NB}.`)
  } else if (validFiles && validFiles.length > 0) {
    // Upload all valid files one by one
    // Update upload progression with files count
    const filesCount = validFiles.length;

    // Call upload service
    for (const file of validFiles) {
      dispatch(updateUploadProgression({ uploading: true, filesCount, filesUploaded, fileInProgress: file.name }));
      try {
        lastDocUploaded = await DocumentService.uploadDocument(file, jobFile);
        filesUploaded++;
        dispatch(updateUploadProgression({ uploading: true, filesCount, filesUploaded }));
        if (jobFile) dispatch(loadJobfileAndDocuments({ jobFileId: jobFile._id }))
      } catch (error) {
        dispatch(updateUploadProgression({ uploading: false, filesCount, filesUploaded }));
        throw error;
      }
    }
  }
  // Upload completed
  dispatch(updateUploadProgression({ uploading: false, filesCount: 0, filesUploaded: 0 }));

  // Return the documentId
  return lastDocUploaded?._id ?? "";
});

async function getPdfFilesFromArchive(archive: File): Promise<File[]> {
  const zip = new JSZip();
  const loadedZip = await zip.loadAsync(archive);
  const pdfAttachments: File[] = [];

  async function processZip(zip: any, path: string = "") {
    for (const filename in zip.files) {
      const file = zip.files[filename];
      if (file.dir) {
        // If it's a directory, don't do anything since the directories are already flattened by JSZip
        console.log(`Skipping directory: ${filename}`);
      } else if (filename.endsWith(".pdf")) {
        // If it's a PDF file, add to the list
        const fileData = await file.async("blob");
        const pdfFile = new File([fileData], path + filename, { type: "application/pdf" });
        console.log(`Adding file: ${filename}`);
        pdfAttachments.push(pdfFile);
      } else if (filename.endsWith(".zip")) {
        // If it's an embedded zip file, load and process it
        const nestedZipData = await file.async("arraybuffer");
        const nestedZip = await JSZip.loadAsync(nestedZipData);
        console.log(`Processing embedded zip file: ${filename}`);
        await processZip(nestedZip, path + filename + "/");
      } else {
        console.log(`Skipping file with invalid extension: ${filename}`);
      }
    }
  }

  await processZip(loadedZip);
  return pdfAttachments;
}

export const getDocumentsTypes = createAsyncThunk("documents/getDocumentsTypes", async () => {
  const docsTypes = await DocumentService.getDocumentsTypes();
  return docsTypes;
});

// Core
const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    updateUploadProgression(state, action: PayloadAction<UploadDocumentsProgression>) {
      state.uploadProgression = action.payload;
    },
  },
  extraReducers: (builder) => {
    // On upload failed, reset the uploadProgression
    builder.addCase(uploadDocuments.rejected, (state, action) => {
      state.uploadProgression = { uploading: false, filesCount: 0, filesUploaded: 0 };
    });
    builder.addCase(getDocumentsTypes.fulfilled, (state, action: PayloadAction<IDocumentType[]>) => {
      state.documentsTypes = action.payload;
    });
  },
});

export const { updateUploadProgression } = documentsSlice.actions;
export const reducer = documentsSlice.reducer;
