import React, { useState, useRef, useEffect, TextareaHTMLAttributes } from "react";

interface AutoGrowingInputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  onEnterPressed?: () => void;
}

const AutoGrowingInput: React.FC<AutoGrowingInputProps> = ({ value, onChange, onFocus, onBlur, onEnterPressed, ...rest }) => {
  const textareRef = useRef<HTMLTextAreaElement>(null);

  // Define component state variables
  const [rows, setRows] = useState(1);
  const [minRows] = useState(1);
  const [maxRows] = useState(10);
  const [fontSize, setFontSize] = useState<string>("");
  const [lineHeight, setLineHeight] = useState<string>("");
  const [padding, setPadding] = useState<number>(0);

  // On component mount, detect the fontSize and lineHeight of the DOM element
  useEffect(() => {
    if (textareRef.current) {
      const fontSize = window.getComputedStyle(textareRef.current, null).getPropertyValue("font-size");
      const lineHeight = window.getComputedStyle(textareRef.current, null).getPropertyValue("line-height");

      const paddingTop = window.getComputedStyle(textareRef.current, null).getPropertyValue("padding-top");
      const paddingBottom = window.getComputedStyle(textareRef.current, null).getPropertyValue("padding-bottom");
      const elemPadding = parseInt(paddingTop.replace("px", "")) + parseInt(paddingBottom.replace("px", ""));

      setFontSize(fontSize);
      setLineHeight(lineHeight);
      setPadding(elemPadding);
    }
  }, []);

  // When lineHeight or value is updated, re-calculate the number of rows
  useEffect(() => {
    if(!textareRef.current) return;
    updateRowsCount(textareRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineHeight, value]);

  // Update the number of rows based on input content and element size
  const updateRowsCount = (input: HTMLTextAreaElement) => {
    if (!lineHeight) return;

    const previousRows = input.rows;
    input.rows = minRows; // reset number of rows in textarea

    const scrollHeight = input.scrollHeight - padding;
    const lineHeightInt = parseInt(lineHeight.replace("px", ""));
    const currentRows = ~~(scrollHeight / lineHeightInt);

    if (currentRows === previousRows) {
      input.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      input.rows = maxRows;
      input.scrollTop = input.scrollHeight;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  // Handle keydown to allow line jump with alt + enter and not just enter
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Prevent line jump with just enter key pressed
    if (e.key === "Enter" && !e.altKey) {
      // If there is onEnterPressed prop, it's override the default input behaviour
      if (onEnterPressed) onEnterPressed();
      // Else we prevent the usage of enter key
      else {
        e.stopPropagation();
        e.preventDefault();
      }
    }

    // When Alt + Enter are pressed, trigger a line jump
    if (e.key === "Enter" && e.altKey && textareRef.current) {
      e.stopPropagation();
      e.preventDefault();

      // In order to trigger onChange properly, we must use the native event dispatching
      // Check https://stackoverflow.com/a/46012210/906046
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value")?.set;
      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(textareRef.current, textareRef.current.value + "\n");
        const event = new Event("input", { bubbles: true });
        textareRef.current.dispatchEvent(event);
      }
    }
  };

  //
  // Render
  //
  return (
    <textarea
      ref={textareRef}
      rows={rows}
      value={value}
      onChange={onChange}
      onFocus={onFocus ?? undefined}
      onBlur={onBlur ?? undefined}
      style={{
        overflow: rows >= maxRows ? "auto" : "hidden",
        height: "auto",
        resize: "none",
        alignSelf: "center",
        fontSize: fontSize,
        lineHeight: lineHeight,
      }}
      onKeyDown={handleKeyDown}
      {...rest}
    />
  );
};

export default AutoGrowingInput;
