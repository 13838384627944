import React from 'react';
import TableActionButton from 'components/TableActionButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { UserAccountStateEnum } from 'models/user';
import { t } from 'i18next';

type TableRowValue = {
  value: React.ReactNode;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

type TenantUserTableRowComponentProps = {
  values: TableRowValue[];
  onDelete: (id: string) => void;
};

const getAccountStateChipColor = (accountState: UserAccountStateEnum) => {
  switch (accountState) {
    case UserAccountStateEnum.ACTIVE:
      return 'success';
    case UserAccountStateEnum.INACTIVE:
      return 'default';
    case UserAccountStateEnum.PENDING_INVITATION:
      return 'warning';
  }
}

export const TenantUserTableRowComponent: React.FC<TenantUserTableRowComponentProps> = ({ values, onDelete }) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {values.map((item, index) => {
        if (index < 2 ) {
          return (
            <TableCell key={index} align={index === 0 ? "inherit" : "center"}>
              {item.value}
            </TableCell>
          );

        } else if (index === 2) {
          return (
            <TableCell key={index} align="center">
              {t(`user_role.${item.value}`)}
            </TableCell>
          );

        // Handle Account State cell
        } else if (index === 3) {
          return (
            <TableCell key={index} align="center">
              <Stack direction="row" spacing={1} justifyContent="center">
                <Chip 
                  label={t(`user_account_state.${item.value}`)} 
                  size="small" 
                  color={getAccountStateChipColor(item.value as UserAccountStateEnum)} 
                  variant="outlined"
                />
              </Stack>
            </TableCell>
          );

        // Handle user delete button cell
        } else {
          return (
            <TableCell key={index} align="right">
              {item.value && (
                <TableActionButton 
                  Icon={DeleteIcon} 
                  onClick={() => onDelete('')}
                  tooltip={t("user_list_view.delete")}
                  color="default" 
                />
              )}
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
};

type TenantUserTableHeaderComponentProps = {
  headers: string[];
}

const TableHeaderComponent: React.FC<TenantUserTableHeaderComponentProps> = ({ headers }) => {
  return (
    <TableHead>
      <TableRow>
      {headers.map((header, index) => {
        return (
          <TableCell key={index} sx={{ fontWeight: 'bold' }} align={index === 0 ? "left" : "center"}>
            {header}
          </TableCell>
        )})}
      </TableRow>
    </TableHead>
  );
};

type TenantUserTableComponentProps = {
  headers: string[];
  children: React.ReactNode;
};

export const TenantUserTableComponent: React.FC<TenantUserTableComponentProps> = ({ headers, children }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHeaderComponent headers={headers} />
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
