import _ from "lodash";

import { EnhancedOcrWord, OcrBbox, OcrTableRowsCenters, OcrWord, WordsLine } from "types/labelling";
import { DocumentFieldOrigin, IDocument, IDocumentField, IDocumentMappedField, IDocumentTableRow } from "models/document";
import { ANNOTATION_ORIGIN_COLORS } from "config/constants";
import { FocusedTable, PageMetadata } from "redux/labelling";
import { DocumentTypeFieldType, IDocumentTypeField } from "models/document_type";

/**
 * Sorting words with lines detection
 * Note: Custom sort with top-left => bottom-right logic
 * @param {OcrWord[]} words List of words to sort by lines
 * @param {PageMetadata[]} pagesMetadata Pages metadata
 * @returns {WordsLine[]} Lines of words detected
 */

export const getWordsSortedByLines = (words: OcrWord[], pagesMetadata: PageMetadata[], concat: Boolean = true): WordsLine[] => {
  // 1) Generated a new array with original words (and bbox) but also rotated ones depending of the page rotation
  const enhancedWords: EnhancedOcrWord[] = words
    .reduce((arr, w) => {
      let pageMetadata = pagesMetadata.find((pm) => pm.page === w.page);
      if (pageMetadata) {
        arr.push({ ...w, rotatedBbox: rotateBbox(w.bbox, pageMetadata.width, pageMetadata.height, -pageMetadata.rotationAngle) });
      }
      return arr;
    }, [] as EnhancedOcrWord[])
    .sort((a, b) => a.page - b.page);

  // 2) Calculate the lineDetection threshold
  const heightThreshold = getMedian(enhancedWords.map((w) => w.rotatedBbox.y1 - w.rotatedBbox.y0)) * 0.3; // 30% is the height tolerance

  // 3) Split them by page
  const pagesWords: Map<number, EnhancedOcrWord[]> = enhancedWords.reduce((entryMap, w) => entryMap.set(w.page, [...(entryMap.get(w.page) || []), w]), new Map());

  // 4) For each page, merge these words by lines
  const allLines: WordsLine[] = [];

  pagesWords.forEach((words) => {
    const pageLines: WordsLine[] = [];
    // 4.1) Merge words per line
    words.forEach((word) => {
      const lineCenter = (word.rotatedBbox.y0 + word.rotatedBbox.y1) / 2;
      const line = pageLines.find((l) => _.inRange(lineCenter, l.y - heightThreshold, l.y + heightThreshold));
      if (line) line.words.push(word);
      else pageLines.push({ y: lineCenter, words: [word] });
    });

    // 4.2) Sort words for each line
    pageLines.sort((l1, l2) => l1.y - l2.y);
    pageLines.forEach((line) => {
      line.words.sort((w1, w2) => w1.rotatedBbox.x0 - w2.rotatedBbox.x0);
    });

    // 4.3) Concat current page lines to allLines
    allLines.push(...pageLines);
  });

  // 5) Return all lines
  return allLines;
};

/**
 * Get all fields mapped on a page
 * @param {IDocument} doc Document
 * @param {number} pageIndex Page index
 * @returns {WordsLine[]} Lines of words detected
 */
export const getMappedFieldsForPage = (doc: IDocument, pageIndex: number): IDocumentMappedField[] => {
  const extraction = doc.extraction?.data;
  if (!extraction) return [];
  // Handle key/value fields
  const allMappedFieldsKv =
    Object.values(extraction).filter((f: IDocumentField) => {
      // Value is a string, not an array
      return (
        typeof f.value === "string" &&
        // Field is mapped from PREDICTION or MAPPING
        f.mapping && f.mapping.length > 0 &&
        // Mapping coordinates on this page
        f.mapping?.some((m) => m.page === pageIndex) === true
      );
    }) ?? [];

  // Handle table fields
  const allMappedFieldsTb: IDocumentMappedField[] = [];
  // Filter on tables i.e. field whose value is an array of IDocumentField
  Object.values(extraction)
    .filter((f: IDocumentField) => f.field_type === DocumentTypeFieldType.table)
    .forEach((table, table_index: number) => {
      // Iterate on row
      table.value.forEach((row: IDocumentTableRow, row_index: number) => {
        // This can happen if the row was badly deleted in DB
        // FIXME: also to be tested elsewhere
        if (!row) {
          console.warn(`Table contains a null row at ${row_index}, Skipping it`);
          return;
        }
        const mappedFields = Object.values(row).filter((f: IDocumentField) => {
          // Field value is a string
          return (
            f &&
            typeof f.value === "string" &&
            // Field is mapped from PREDICTION or MAPPING
            f.mapping && f.mapping.length > 0 &&
            // Mapping coordinates on this page
            f.mapping?.some((m) => m.page === pageIndex) === true
          );
        });
        // Now we enhance the mappedFields array with table and row data
        // This is used for the drawMappedField and sameWord logics
        if (mappedFields.length > 0) {
          const enhancedMappedFields = mappedFields.map((f) => {
            return { ...f, table: Object.values(extraction).filter((f: IDocumentField) => Array.isArray(f.value))?.[table_index]?.field_id, row: row_index };
          });
          allMappedFieldsTb.push(...enhancedMappedFields);
        }
      });
    });
  return [...allMappedFieldsKv, ...allMappedFieldsTb];
};

/**
 * Retrieves rows coordinates for a specific page in a document.
 * Used to draw the canvas rectangles for each row.
 *
 * @param {IDocument} doc - The document containing the data
 * @param {number} pageIndex - The index of the page to retrieve mapped rows for
 * @return {OcrBbox[][]} An array of arrays of mapped rows. Outter array is the table, inner array represents the rows
 */
export const getMappedRowsForPageForTable = (doc: IDocument, pageIndex: number, tableId: string): (OcrBbox | null)[] => {
  const extractions = doc.extraction?.data;
  if (!extractions) return [];
  const documentTable = extractions[tableId].field_type === DocumentTypeFieldType.table ? extractions[tableId] : null;
  if (!documentTable) return [];
  const rowsCoordinates: (OcrBbox | null)[] = [];
  documentTable.value.forEach((row: IDocumentTableRow) => {
    // Just get mappings to find min and max coordinates
    const mappings: OcrBbox[] = Object.values(row)
      .filter((field: IDocumentField) => field.mapping && field.mapping.length > 0 && field.mapping?.some((m) => m.page === pageIndex) === true) // Only take field that contains at least one mapping bbox of the page
      .map((field: any) => field.mapping)
      .flat()
      .filter((m) => m.page === pageIndex) // Remove mapping bbox not on this page
      .map((mapping: any) => mapping.bbox);
    if (mappings.length === 0) {
      rowsCoordinates.push(null);
    } else {
      const rowCoordinates = mappings.reduce(
        (acc: OcrBbox, bbox: OcrBbox) => ({
          x0: Math.min(acc.x0, bbox.x0),
          x1: Math.max(acc.x1, bbox.x1),
          y0: Math.min(acc.y0, bbox.y0),
          y1: Math.max(acc.y1, bbox.y1),
        }),
        mappings[0]
      );

      rowsCoordinates.push(rowCoordinates);
    }
  });
  return rowsCoordinates;
};

export const getColumnsCentersForPageForTable = (doc: IDocument, pageIndex: number, tableId: string): OcrTableRowsCenters => {
  const documentTable = doc.extraction?.data[tableId];
  if (!documentTable || documentTable.field_type !== DocumentTypeFieldType.table) return {};
  // Use an object to store the coordinates of each column
  const columnsCoordinates: { [column: string]: { x0: number; x1: number; y0: number; y1: number }[] } = {};
  // Store all the column coordinates into the object for further calculation
  // Iterate on table value which is an array of rows
  documentTable.value.forEach((row: IDocumentTableRow) => {
    // Iterate on table rows
    Object.values(row).forEach((field: IDocumentField) => {
      // Use only MAPPING fields
      if (field.mapping && field.mapping.length > 0 && field.mapping && field.mapping[0].page === pageIndex) {
        // Init empty array for this column
        if (!columnsCoordinates[field.field_id]) columnsCoordinates[field.field_id] = [];
        // Then push all the mapping coordinates
        field.mapping.forEach((mapping: OcrWord) => {
          columnsCoordinates[field.field_id].push({ x0: mapping.bbox.x0, x1: mapping.bbox.x1, y0: mapping.bbox.y0, y1: mapping.bbox.y1 });
        });
      }
    });
  });
  // Compute center x & y of each column
  // WIP: y is not used anywhere for now but could be used later for positioning activeRow cursor
  const columnsCenters: OcrTableRowsCenters = {};
  for (const column in columnsCoordinates) {
    const columnCoordinates = columnsCoordinates[column];
    const columnCenter = columnCoordinates.reduce(
      (acc, coord) => ({
        x: acc.x + (coord.x0 + coord.x1) / 2,
        y: acc.y + (coord.y0 + coord.y1) / 2,
      }),
      { x: 0, y: 0 }
    );
    columnsCenters[column] = { x: columnCenter.x / columnCoordinates.length, y: columnCenter.y / columnCoordinates.length };
  }

  // Sort by x coordinates
  const sortedColumnsCenters = Object.fromEntries(Object.entries(columnsCenters).sort((a, b) => a[1].x - b[1].x));

  return sortedColumnsCenters;
};

/**
 * Get color for a field origin
 * @param {DocumentFieldOrigin} origin Field origin (manual, mapping, prediction)
 * @returns {string} Lines of words detected
 */
export const getColorForFieldOrigin = (origin: DocumentFieldOrigin | null | undefined, row?: number | undefined): string => {
  switch (origin) {
    //TODO: Implement row % 2 color logic for all origins
    case DocumentFieldOrigin.Prediction:
      // return ANNOTATION_ORIGIN_COLORS.PREDICTION;
      if (typeof row === "number" && row >= 0) {
        if (row % 2 === 0) {
          return ANNOTATION_ORIGIN_COLORS.PREDICTION_EVEN;
        } else {
          return ANNOTATION_ORIGIN_COLORS.PREDICTION_ODD;
        }
      } else {
        return ANNOTATION_ORIGIN_COLORS.PREDICTION;
      }
    case DocumentFieldOrigin.Manual:
      return ANNOTATION_ORIGIN_COLORS.MANUAL;
    case DocumentFieldOrigin.Mapping:
      if (typeof row === "number" && row >= 0) {
        if (row % 2 === 0) {
          return ANNOTATION_ORIGIN_COLORS.MAPPING_EVEN;
        } else {
          return ANNOTATION_ORIGIN_COLORS.MAPPING_ODD;
        }
      } else {
        return ANNOTATION_ORIGIN_COLORS.MAPPING;
      }
    default:
      return ANNOTATION_ORIGIN_COLORS.DEFAULT;
  }
};

/**
 * Get median value inside an array of number
 */
export const getMedian = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const formatTextFromMapping = (words: OcrWord[], pagesMetadata: PageMetadata[]): string => {
  // Detect lines and format the output string
  const lines = getWordsSortedByLines(words, pagesMetadata, false);
  let str = "";
  lines.forEach((line, index) => {
    if (index > 0) str += "\n";
    str += line.words.map((w) => w.content).join(" ");
  });

  return str;
};

/**
 * Functions that cast the data to be usable in table component
 *
 * @param {IDocument} doc - The document containing the data
 * @param {FocusedTable} focusedTable - The current focused table
 * @return {IDocumentField[][]} An array of arrays of fields
 */
export const getTableRows = (currentDocument: IDocument, focusedTable: FocusedTable): IDocumentTableRow[] => {
  if (!currentDocument || !focusedTable) return [];

  // Check if the focusedTable field already exists in document
  const documentField = currentDocument.extraction?.data[focusedTable.table.technicalName];
  // Sanity checks
  if (!documentField) return [];
  if (documentField.field_type !== DocumentTypeFieldType.table) {
    console.error(`DocumentField ${documentField.field_id} should be of type table`);
    return [];
  }
  if (!Array.isArray(documentField.value)) {
    console.error(`DocumentField ${documentField.field_id} is of type table but value is not an array`);
    return [];
  }
  return documentField.value as IDocumentTableRow[];
};

/**
 * Format a field id
 */
export const formatFieldId = (field: IDocumentTypeField): string => {
  return `doc-field-${field.technicalName}-input`;
};

/* Rotations functions */

export function rotateBbox(bbox: OcrBbox, docWidth: number, docHeight: number, rotation: number): OcrBbox {
  const { x0, x1, y0, y1 } = bbox;

  let newX0: number, newY0: number, newX1: number, newY1: number;

  // If the rotation is below 0, add 360 to always have a positive rotation angle for the switch case below
  if (rotation < 0) rotation += 360;

  switch (rotation) {
    case 90:
      newX0 = docHeight - y1;
      newY0 = x0;
      newX1 = docHeight - y0;
      newY1 = x1;
      break;
    case 180:
      newX0 = docWidth - x1;
      newY0 = docHeight - y1;
      newX1 = docWidth - x0;
      newY1 = docHeight - y0;
      break;
    case 270:
      newX0 = y0;
      newY0 = docWidth - x1;
      newX1 = y1;
      newY1 = docWidth - x0;
      break;
    case 0:
    default:
      // If the rotation is 0 degrees or an invalid value, return the original bbox
      newX0 = x0;
      newY0 = y0;
      newX1 = x1;
      newY1 = y1;
      break;
  }

  return {
    x0: newX0,
    x1: newX1,
    y0: newY0,
    y1: newY1,
  };
}

export function rotateCoord(coord: { x: number; y: number }, docWidth: number, docHeight: number, rotation: number): { x: number; y: number } {
  const { x, y } = coord;

  let newX: number, newY: number;

  // If the rotation is below 0, add 360 to always have a positive rotation angle for the switch case below
  if (rotation < 0) rotation += 360;
  switch (rotation) {
    case 90:
      newX = y;
      newY = docWidth - x;
      break;
    case 180:
      newX = docWidth - x;
      newY = docHeight - y;
      break;
    case 270:
      newX = docHeight - y;
      newY = x;
      break;
    case 0:
    default:
      // If the rotation is 0 degrees or an invalid value, return the original bbox
      newX = x;
      newY = y;
      break;
  }

  return {
    x: newX,
    y: newY,
  };
}

// Function that return the current page rotation.
// Used to automatically rotate pages and re-calculate bounding boxes
// Round rotation to a number with modulo 90 equals to 0
export const getPageRotation = (rotation: number): number => {
  if (Number.isNaN(rotation)) return 0;

  const roundedRotation = 90.0 * Math.round(rotation / 90.0);
  return roundedRotation;
};

/**
 * Based on a selected field, focus the correct page (if needed)
 *
 * @param {PageMetadata[]} pagesMetadata Pages metadata
 * @param {OcrWord[]} mapping - The mapped zone
 */
export const scrollToPageFromField = (pagesMetadata: PageMetadata[], mapping: OcrWord[]) => {
  if (mapping && mapping.length > 0) {
    makeMappedZoneVisible(pagesMetadata, mapping);
  }
};

/**
 * Based on a selected table cell, focus the correct page (if needed)
 *
 * @param {IDocument} doc - The document containing the data
 * @param {PageMetadata[]} pagesMetadata Pages metadata
 * @param {FocusedTable} focusedTable - The current focused table
 * @param {number} rowIndex - The row index
 * @param {IDocumentTypeField} column - The column selected
 */
export const scrollToPageFromTableCell = (doc: IDocument, pagesMetadata: PageMetadata[], table: FocusedTable, rowIndex: number, column: IDocumentTypeField) => {
  if (doc && table) {
    const tableRows = getTableRows(doc, table);
    const tableRow = tableRows[rowIndex];
    if (tableRow) {
      const cell = tableRow[column.technicalName];
      if (cell && cell.mapping && cell.mapping.length > 0) {
        makeMappedZoneVisible(pagesMetadata, cell.mapping);
      }
    }
  }
};

// Internal function that scroll document to make a mapped zone visible
const makeMappedZoneVisible = (pagesMetadata: PageMetadata[], mapping: OcrWord[]) => {
  const focusedPageElement = document.querySelector(`div[data-page-number="${mapping[0].page}"]`) as HTMLElement | null;
  if (focusedPageElement) {
    let top = focusedPageElement.offsetTop;

    // Scroll if not visible
    const pageViewer = document.getElementById("page-viewer");
    if (pageViewer) {
      const pageViewerRect = pageViewer.getBoundingClientRect();
      const pageRect = focusedPageElement.getBoundingClientRect();

      // Get the relative position of the mapped element inside the page
      let pageMetadata = pagesMetadata.find((pm) => pm.page === mapping?.[0].page);
      if (pageMetadata) {
        // 1) Calculate position of the mappedField and scale it
        let { width, height } = pageMetadata;
        let rotatedBbox = rotateBbox(mapping[0].bbox, width, height, -pageMetadata.rotationAngle);
        // If there is a rotation of 90 or 270 degrees, we need to switch layout width and height
        if (Math.abs(pageMetadata.rotationAngle) === 90 || Math.abs(pageMetadata.rotationAngle) === 270) [width, height] = [height, width];

        let scaledBbox = {
          x0: (rotatedBbox.x0 * pageRect.width) / width,
          x1: (rotatedBbox.x1 * pageRect.width) / width,
          y0: (rotatedBbox.y0 * pageRect.height) / height,
          y1: (rotatedBbox.y1 * pageRect.height) / height,
        };

        if (scaledBbox.x0 > scaledBbox.x1) [scaledBbox.x0, scaledBbox.x1] = [scaledBbox.x1, scaledBbox.x0];
        if (scaledBbox.y0 > scaledBbox.y1) [scaledBbox.y0, scaledBbox.y1] = [scaledBbox.y1, scaledBbox.y0];

        // 2) Based on that, detect if the bbox is visible or not
        const currentPageOffset = pageRect.top - pageViewerRect.top;
        const cond = pageViewerRect.height - currentPageOffset;

        // If we reach here, the element is not visible.
        // Apply the correct scrolling
        if (cond < scaledBbox.y0) {
          top += scaledBbox.y1 - pageViewerRect.height + 10;
        } else if (cond - pageViewerRect.height > scaledBbox.y0) {
          top += scaledBbox.y0 - 10;
        }
        // Else stop here
        else return;
      }

      // 3) Apply the scroll (if needed)
      pageViewer.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    }
  }
};
