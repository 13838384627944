import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { MdSearch } from "react-icons/md";
import { AiOutlineStar } from "react-icons/ai";
import { FcClearFilters } from "react-icons/fc";
import { t } from "i18next";
import { Button, Popover } from "@mui/material";
import toast from "react-hot-toast";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setSearchDocumentsTypes, setSearchText } from "redux/search";
import { ISavedQuery } from "models/user";
import { SEARCHING_DEBOUNCE_TIME } from "config/constants";
import { saveSearchQuery } from "redux/users";
import _ from "lodash";

type SearchBarProps = {};

export const SearchBar: React.FC<SearchBarProps> = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.user);
  const { searchText, searchDocumentsTypes } = useAppSelector((state) => state.search);

  const [inputText, setInputText] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [inputQueryName, setInputQueryName] = useState<string>("");

  useEffect(() => {
    // If the search text is updated in redux store, update the local input to reflect that change
    setInputText(searchText);
  }, [searchText]);

  // Declare onChange function with a debouncing feature
  // Debounce is used to prevent too much requets to backend
  // Explanation here: https://www.developerway.com/posts/debouncing-in-react
  const debouncedSearch = React.useRef(
    debounce(async (text: string) => {
      dispatch(setSearchText(text));
    }, SEARCHING_DEBOUNCE_TIME)
  ).current;

  // Return true if the query in params is currently selected
  const getQuerySelected = (): ISavedQuery | null => {
    const existingSavedQuery = currentUser?.saved_queries?.find(
      (query) => query.search_text?.toLowerCase().trim() === searchText.toLowerCase().trim() && _.isEqual([...searchDocumentsTypes]?.sort(), query.search_documents_types?.sort())
    );
    return existingSavedQuery ?? null;
  };

  //
  // UI Actions
  //

  // Triggered when the user click on the favorite icon (the star)
  const handleFavoriteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setInputQueryName("");
  };

  // Triggered when the "save query" popover is about to be closed
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Triggerd when the user click on the reset icon
  const handleResetFilters = () => {
    dispatch(setSearchText(""));
    dispatch(setSearchDocumentsTypes([]));
    setAnchorEl(null);
  };

  // Triggered when the user click on "Save" action inside the popover
  const handleSaveSearchQuery = async () => {
    // Validations
    if (searchText.length === 0 && searchDocumentsTypes.length === 0) return toast.error(t("home.saved_queries.validation.missing_criterias"));

    try {
      await dispatch(saveSearchQuery({ queryName: inputQueryName, searchText, searchDocumentsTypes }));
      toast.success(t("home.saved_queries.save_successful"));
    } catch (err) {
      toast.error(t("home.saved_queries.save_error"));
    } finally {
      setAnchorEl(null);
    }
  };

  //
  // Rendering
  //
  const selectedSavedQuery = getQuerySelected();

  return (
    <div className="my-3 relative grow">
      <button className="absolute left-4 top-1/2 -mt-3 cursor-pointer" onClick={handleFavoriteClick}>
        <AiOutlineStar className={`text-2xl ${selectedSavedQuery ? "text-yellow-500 hover:text-yellow-600" : "text-gray-500"}`} />
      </button>
      <MdSearch className="absolute left-12 top-1/2 -mt-3 text-2xl" />
      <input
        type="text"
        id="search"
        value={inputText}
        className="block w-full pl-20 px-12 py-4 bg-gray-100 shadow border-0 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400"
        placeholder={t("home.search_bar.placeholder")}
        onChange={(e) => {
          setInputText(e.target.value);
          debouncedSearch(e.target.value);
        }}
      />
      {(searchText.length > 0 || searchDocumentsTypes.length > 0) && (
        <FcClearFilters className="absolute right-4 top-1/2 -mt-3 text-2xl cursor-pointer opacity-70 hover:opacity-100" onClick={handleResetFilters} title={t("home.search_bar.reset")} />
      )}

      {/* Popover for query save / update */}
      <Popover
        id={`save-query-popover`}
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="px-3 pt-2 pb-3">
          <span className="text-xs font-semibold">{t("home.saved_queries.save_popover_desc")}</span>
          <input
            type="text"
            value={inputQueryName}
            className="block w-60 mt-1 px-3 py-2 bg-gray-100 border-gray-200 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400"
            onChange={(e) => setInputQueryName(e.target.value)}
          />
          <Button onClick={handleSaveSearchQuery} disabled={inputQueryName.length === 0} className="float-right" style={{ textTransform: "none", marginTop: 3, marginBottom: 3 }}>
            {t("home.saved_queries.save_cta")}
          </Button>
        </div>
      </Popover>
    </div>
  );
};
