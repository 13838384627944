import React from "react";

import { DocumentFieldOrigin } from "models/document";
import { IDocumentTypeField } from "models/document_type";

import { useAppDispatch } from "redux/hooks";
import { getColorForFieldOrigin } from "utils/labelling";
import { setHoveredField } from "redux/labelling";

type FieldOriginProps = {
  field: IDocumentTypeField;
  origin: DocumentFieldOrigin | null | undefined;
  small?: boolean;
};

const FieldOrigin: React.FC<FieldOriginProps> = ({ field, origin, small = false }) => {
  const dispatch = useAppDispatch();

  const getBackgroundStyleForOrigin = (): React.CSSProperties => {
    const color = getColorForFieldOrigin(origin);

    switch (origin) {
      case "mapping":
        return { backgroundColor: `${color}4D`, border: `1px solid ${color}80`, borderRadius: 2, cursor: "context-menu" };
      case "manual":
        return { backgroundColor: `${color}4D`, border: `1px solid ${color}80`, borderRadius: 2 };
      case "prediction":
        return { backgroundColor: `${color}4D`, border: `1px solid ${color}80`, borderRadius: 2, cursor: "context-menu" };

      default:
        return { backgroundColor: `#dceaf54D`, border: `1px solid #dceaf580`, borderRadius: 2 };
    }
  };

  const getOriginLabel = () => {
    if (small) return origin?.slice(0, 1) || "";
    else return origin || "";
  };

  if (!origin) return null;

  return (
    <div
      className="mx-1 font-medium bg-opacity-40 px-[3px] text-[9px] uppercase"
      style={getBackgroundStyleForOrigin()}
      onMouseEnter={() => {
        dispatch(setHoveredField(field));
      }}
      onMouseLeave={() => dispatch(setHoveredField(null))}
    >
      {getOriginLabel()}
    </div>
  );
};

export default FieldOrigin;
