import { IDocumentType } from "models/document_type";
import { useAppSelector } from "redux/hooks";

export const useDocumentTypes = () => {
  const { documentsTypes } = useAppSelector((state) => state.documents);
  const { currentDocument } = useAppSelector((state) => state.labelling);
  const { currentJobfile } = useAppSelector((state) => state.jobfile);
  
  // Get allowed documents types based on a context
  const getDocumentsTypesFromContext = (): IDocumentType[] => {
    // If we are in the context of a jobfile with a usecase
    // we give precedence to the usecase docTypes and not the generic ones
    const useCaseDocTypes = currentJobfile?.usecase?.docTypes;
    return useCaseDocTypes && useCaseDocTypes.length > 0 ? useCaseDocTypes : documentsTypes;

  };

  // Get document type from context (global docType or specific docType defined in the usecase)
  const getCurrentDocumentDocType = (): IDocumentType | null => {
    if (!currentDocument?.doc_type) return null;
    // If we are in the context of a jobfile with a usecase
    // we give precedence to the usecase docTypes and not the generic ones
    // and extract the displayable fields from the usecase config
    let docType: IDocumentType | undefined;

    const useCaseDocTypes = currentJobfile?.usecase?.docTypes;
    
    if (useCaseDocTypes && useCaseDocTypes.length > 0) {
      docType = useCaseDocTypes.find((dt) => dt.name === currentDocument?.doc_type);
      return docType ?? null;
    }
    // If there is no usecase context, use global document types
    docType = documentsTypes.find((dt) => dt.name === currentDocument?.doc_type);
    return docType ?? null;
  };

  // Return variables
  return {
    allowedDocumentsTypes: getDocumentsTypesFromContext(),
    currentDocType: getCurrentDocumentDocType(),
  };
};
