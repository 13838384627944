import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SearchState = {
  searchText: string;
  searchDocumentsTypes: string[]
};

export const initialState: SearchState = {
    searchText: "",
    searchDocumentsTypes: []
};

// Core
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setSearchDocumentsTypes(state, action: PayloadAction<string[]>) {
      state.searchDocumentsTypes = action.payload;
    },
  },
});

export const { setSearchText, setSearchDocumentsTypes } = searchSlice.actions;
export const reducer = searchSlice.reducer;
