import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import * as Yup from 'yup';
import logo from "assets/logotext-white.png";
import background from "assets/bg-auth.png";
import { Formik, Form, FormikHelpers } from "formik";
import { Box, Button, CircularProgress, TextField, Typography, styled } from "@mui/material";
import registrationService from "services/registration.service";
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "redux/hooks";
import { login } from "redux/users";
import api from "services/api";
import authService from "services/auth.service";
import { ReactComponent as MicrosoftLogo } from "assets/svg/microsoft_icon.svg";
import { AuthenticationResult } from "@azure/msal-browser";

interface InvitationData {
  email: string;
  firstname: string;
  lastname: string;
}

interface FormValues {
  useMicrosoft: boolean;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const CustomButton = styled(Button)({
  backgroundColor: '#2F2F2F',
  color: 'white',
  paddingTop: '0.625rem',
  paddingBottom: '0.625rem',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: 'darkblue',
  },
  '&.Mui-disabled': {
    backgroundColor: 'grey',
    color: 'lightgrey',
  },
  textTransform: "none",
});

export default function Registration() {
  const [loading, setLoading] = useState(true);
  const [invitationData, setInvitationData] = useState<InvitationData | null>(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const [useMicrosoft, setUseMicrosoft] = useState(false);

  useEffect(() => {
    const fetchInvitationData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      const tenantId = searchParams.get('tenant');

      if (!tenantId || !token) {
        setError('Invalid invitation link');
        setLoading(false);
        return;
      }

      try {
        const response = await registrationService.registerByInviteToken(tenantId, token);
        setInvitationData(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchInvitationData();
  }, [location.search]);

  const initialValues: FormValues = {
    useMicrosoft: false,
    email: invitationData ? invitationData.email : '',
    firstname: invitationData ? invitationData.firstname : '',
    lastname: invitationData ? invitationData.lastname : '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string(),
    lastname: Yup.string(),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: useMicrosoft ? Yup.string() : Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least one number, one uppercase, one lowercase letter, one special character, and at least 8 or more characters"
      )
      .required('Password is required'),
    confirmPassword: useMicrosoft ? Yup.string() : Yup.string().when("password", (password, schema) => {
      return password && password.length > 0
        ? schema.oneOf([Yup.ref('password')], 'Passwords must match').required('Confirm Password is required')
        : schema.notRequired();
    })
  });

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true);

    try {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      const tenantId = searchParams.get('tenant');

      if (!tenantId || !token) {
        setError('Invalid invitation link');
        setLoading(false);
        return;
      }

      if (values.useMicrosoft) {  
        let res: AuthenticationResult | null = null;
        try {
          res = await instance.loginPopup({
            scopes: ["openid", "profile", "email"],
            prompt: "select_account",
          });
        } catch (err) {
          console.error("Microsoft Popup closed of failed");
          return
        }

        if (!res || !res.idToken) {
          console.error("Microsoft authentication result failed");
          return;
        }
        const idToken = res.idToken;
        console.log("Successfully authenticated to Microsoft");
        // we check registration AFTER trying to open the popup because the popup can be blocked by some browsers
        // so we need the user to un-block the popup before going to the next step
        await registrationService.validateRegistration(tenantId, token, values);

        if (!idToken) {
          console.error("Msal response has no id token");
          console.log(res);
          // toast.error(t("signin_view.microsoft.error"));
          return;
        }
        const data = await sendMsalTokenToBackend(idToken);
        dispatch(login({
          tenant_id: data?.tenant_id,
          email: data?.email,
        }));

      } else {
        await registrationService.validateRegistration(tenantId, token, values);
        // Validate passwords are not empty and match
        if (!values.password || values.password !== values.confirmPassword) {
          setError('Passwords must not be empty and must match.');
          setSubmitting(false);
          return;
        }
        const data = await authService.login(values.email, values.password);
    
        dispatch(login({
          tenant_id: data.tenant_id,
          email: data.email,
        }));
      }

      setSubmitting(false);
      navigate('/'); // Redirect to login page after successful registration
      
    } catch (err) {
      console.error(err);
      setError('Registration failed');
      setSubmitting(false);
    }
  };

  // API Call to backend to validate the authentication
  const sendMsalTokenToBackend = async (token: string) => {
    try {
      const response = await api.post("/auth/oauth2", {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        email: response.data.email,
        tenant_id: response.data.tenant_id,
      };
    
    } catch (error) {
      console.error("Error sending access token to backend:", error);
      throw error;
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
      <div className="flex overflow-hidden rounded-xl bg-white">
        <div className="flex items-center bg-gradient px-20">
          <img className="h-32" src={logo} alt="" />
        </div>
        <div className="p-10 text-center">
          <h3 className="text-xl font-semibold text-black">{t("registration_view.title")}</h3>
          <div className="h-[1px] bg-gray-100 w-50 mt-6 mb-6 block" />
          <div className="text-center w-72">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ isSubmitting, handleChange, values, errors, touched, setFieldValue, submitForm }) => (
                <Form onKeyDown={function(e) {if (e.key === 'Enter') e.preventDefault();}}>
                  <Box mb={2}>
                    <TextField
                      disabled={true}
                      fullWidth
                      name="email"
                      label={t("registration_view.form.email")}
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      name="firstname"
                      label={t("registration_view.form.firstname")}
                      value={values.firstname}
                      onChange={handleChange}
                      error={touched.firstname && Boolean(errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      name="lastname"
                      label={t("registration_view.form.lastname")}
                      value={values.lastname}
                      onChange={handleChange}
                      type="text"
                      error={touched.lastname && Boolean(errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                      autoComplete="new-lastname"
                    />
                  </Box>
                  <div className="h-[1px] bg-gray-100 w-50 mt-6 mb-6 block" />
                  <CustomButton
                    type="submit"
                    variant="contained"
                    color="inherit"
                    fullWidth
                    onClick={() => {
                      setFieldValue("useMicrosoft", true);
                      setUseMicrosoft(true);
                      submitForm();
                    }}
                    startIcon={<MicrosoftLogo className="w-5 inline mr-3 -mt-0.5 py-1"/>}
                    disabled={isSubmitting}
                  >
                    {t("signin_view.microsoft.cta")}
                  </CustomButton>
                  <div className="h-[1px] bg-gray-100 w-50 mt-6 mb-6 block" />
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      name="password"
                      label={t("registration_view.form.password")}
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      disabled={values.useMicrosoft}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      name="confirmPassword"
                      label={t("registration_view.form.confirm_password")}
                      type="password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      disabled={values.useMicrosoft}
                    />
                  </Box>
                  {error && (
                    <Box mb={2}>
                      <Typography color="error">{error}</Typography>
                    </Box>
                  )}
                  <Button 
                    type="button" 
                    variant="contained" 
                    fullWidth 
                    onClick={() => {
                      setFieldValue("useMicrosoft", false);
                      setUseMicrosoft(false);
                      submitForm();
                    }}
                    disabled={isSubmitting}>
                    Connect with password
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
