import api from "./api";

const REGISTRATION_URL = "/registration";

interface ValidateRegistrationData {
  useMicrosoft: boolean;  
  firstname: string;
  lastname: string;
  password?: string;
}

interface BodyValidateRegistration {
  tenantId: string;
  token: string;
  firstname: string;
  lastname: string;
  password?: string;
}

class RegistrationService {

  async registerByInviteToken(tenantId: string, token: string) {
    return api.post(REGISTRATION_URL, { 
        tenantId,
        token
    }, { withCredentials: false });
  }

  async validateRegistration(tenantId: string, token: string, registrationData: ValidateRegistrationData) {
    const body: BodyValidateRegistration = {
      tenantId: tenantId,
      token: token,
      firstname: registrationData.firstname,
      lastname: registrationData.lastname,
    };

    if (!registrationData.useMicrosoft)
      body.password = registrationData.password;

    return api.post(REGISTRATION_URL + "/validate", body);
  }

}

const registrationService = new RegistrationService();

export default registrationService;
