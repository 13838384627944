import React from "react";
import { t } from "i18next";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { LuFiles, LuFolders } from "react-icons/lu";

import { styled } from "@mui/material/styles";

const CustomToggleButton = styled(ToggleButton)({
  padding: "6px 10px",
  textTransform: "none",
  backgroundColor: "white",
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: '#c2ddff',
  }
});

export enum SearchType {
  Documents = "documents",
  Jobfiles = "jobfiles",
}

type SearchTypeProps = {
  type: SearchType;
  onChange: (type: SearchType) => void;
};

export const SearchTypeSelector: React.FC<SearchTypeProps> = ({ type, onChange }) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newType: SearchType) => {
    if(newType !== null) onChange(newType)
  };

  return (
    <ToggleButtonGroup value={type} exclusive onChange={handleChange} sx={{display: "block"}}>
      <CustomToggleButton value={SearchType.Documents}>
        <LuFiles className="inline mr-2" />
        {t("home.filters.search_type.documents")}
      </CustomToggleButton>
      <CustomToggleButton value={SearchType.Jobfiles}>
        <LuFolders className="inline mr-2" />
        {t("home.filters.search_type.jobfiles")}
      </CustomToggleButton>
    </ToggleButtonGroup>
  );
};
