import React from "react";
import { IoDocumentsOutline } from "react-icons/io5";
import { t } from "i18next";

import Loading from "components/Loading";
import InfiniteScrollList from "components/InfiniteScrollList";
import { DocumentStatus } from "models/document";
import { DocumentListItem } from "./DocumentListItem";
import { JobfileDocument } from "models/jobfile";

type DocumentsSelectorProps = {
  documents: Array<JobfileDocument>;
  selectedDocumentId: string | null;
  loading: boolean;
  onClick: (doc: JobfileDocument) => void;
  visible: boolean;
  onDocumentStateChange: () => void;
};

export const DocumentsSelector: React.FC<DocumentsSelectorProps> = ({ documents, selectedDocumentId, loading, onClick, visible, onDocumentStateChange }) => {
  if (!visible) return null;
  if (loading) return <Loading />;

  if (!documents || documents.length === 0)
    return (
      <div className="flex flex-col w-full grow items-center justify-center">
        <IoDocumentsOutline className="block text-4xl" />
        <span className="block mt-4 font-medium">{t("jobfile_documents_list.documents_empty")}</span>
      </div>
    );

  return (
    <InfiniteScrollList className="w-full grow overflow-y-scroll">
      {documents.map((doc, index) => {
        const disabled = [DocumentStatus.Processing].includes(doc.status);

        return (
          <DocumentListItem
            key={`document-${doc._id}`}
            document={doc}
            onClick={() => {
              if (!disabled) onClick(doc);
            }}
            disabled={disabled}
            selected={selectedDocumentId === doc._id}
            onDocumentStateChange={onDocumentStateChange}
          />
        );
      })}
    </InfiniteScrollList>
  );
};
