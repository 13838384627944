export enum DocumentTypeFieldType {
  number = "number",
  date = "date",
  text = "text",
  table = "table",
}

export interface IDocumentTypeField {
  technicalName: string;
  type: DocumentTypeFieldType;
  formatter?: string;
  columns?: IDocumentTypeField[];
  // TODO: remove this guy, it should appear in a IDocumentField indeed but not in the schema definition
  row?: number;
  table?: string;
}

export interface IDocumentType {
  name: string;
  displayName: string;
  fields: IDocumentTypeField[];
}
