/* eslint-disable react-hooks/exhaustive-deps */
import { TABLE_COLUMN_MAX_WIDTH, TABLE_COLUMN_MIN_WIDTH } from "config/constants";
import React, { ReactNode } from "react";

type ResizableTableColumnProps = {
  children: (ref: any) => ReactNode;
  onSizeChange?: (width: number) => void;
};

export const ResizableTableColumn: React.FC<ResizableTableColumnProps> = ({ children, onSizeChange = null }) => {
  const [node, setNode] = React.useState<HTMLElement>();

  const ref = React.useCallback((nodeEle: HTMLElement) => {
    setNode(nodeEle);
  }, []);

  const handleMouseDown = React.useCallback(
    (e: MouseEvent) => {
      if (!node) {
        return;
      }

      const parent = node.parentElement;
      if (!parent) return null;

      const startPos = {
        x: e.clientX,
        y: e.clientY,
      };
      const styles = window.getComputedStyle(parent);
      const w = parseInt(styles.width, 10);

      const handleMouseMove = (e: MouseEvent) => {
        const dx = e.clientX - startPos.x;
        const newWidth = w + dx;
        if (newWidth > TABLE_COLUMN_MIN_WIDTH && newWidth <= TABLE_COLUMN_MAX_WIDTH) {
          parent.style.width = `${newWidth}px`;
          updateCursor();
          if (onSizeChange) onSizeChange(newWidth);
        }
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        resetCursor();
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [node]
  );

  const handleTouchStart = React.useCallback(
    (e: TouchEvent) => {
      if (!node) {
        return;
      }
      const parent = node.parentElement;
      if (!parent) return null;

      const touch = e.touches[0];
      const startPos = {
        x: touch.clientX,
        y: touch.clientY,
      };
      const styles = window.getComputedStyle(parent);
      const w = parseInt(styles.width, 10);

      const handleTouchMove = (e: TouchEvent) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const newWidth = w + dx;
        if (newWidth > TABLE_COLUMN_MIN_WIDTH && newWidth <= TABLE_COLUMN_MAX_WIDTH) {
          parent.style.width = `${newWidth}px`;
          updateCursor();
          if (onSizeChange) onSizeChange(newWidth);
        }
      };

      const handleTouchEnd = () => {
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
        resetCursor();
      };

      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [node]
  );

  const updateCursor = () => {
    document.body.style.cursor = "col-resize";
    document.body.style.userSelect = "none";
  };

  const resetCursor = () => {
    document.body.style.removeProperty("cursor");
    document.body.style.removeProperty("user-select");
  };

  React.useEffect(() => {
    if (!node) return;

    node.addEventListener("mousedown", handleMouseDown);
    node.addEventListener("touchstart", handleTouchStart);

    return () => {
      node.removeEventListener("mousedown", handleMouseDown);
      node.removeEventListener("touchstart", handleTouchStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  return children({ ref });
};
