import React, { useEffect } from "react";

import { DocumentFieldOrigin, DocumentFieldUpdateRequest } from "models/document";
import { useAppSelector } from "redux/hooks";

import AutoGrowingInput from "components/AutoGrowingInput";
import { InputFieldProps } from ".";
import { OcrWord } from "types/labelling";
import { getWordsSortedByLines } from "utils/labelling";
import FieldOrigin from "./FieldOrigin";
import { getUniqueArrayOfObjects } from "utils/helpers";

const TextField: React.FC<InputFieldProps> = ({ id, field, value, origin, mapping, isFocused, onChange, onFocus, autoFocus, onEnterPressed }) => {
  const { focusedField, selectedMappingWords, multiMapping, focusedTable, pagesMetadata } = useAppSelector((state) => state.labelling);

  useEffect(() => {
    if (
      field &&
      ((!focusedTable?.focusedCell && focusedField && field.technicalName === focusedField.technicalName) ||
        (focusedTable && !focusedField && isFocused && focusedTable.focusedCell?.cell.technicalName === field.technicalName))
    ) {
      if (selectedMappingWords && selectedMappingWords.length > 0) {
        let newMapping = selectedMappingWords;
        if (multiMapping && mapping && mapping?.length > 0) {
          newMapping = getUniqueArrayOfObjects([...mapping, ...newMapping]);
        }
        let str = formatTextFromMapping(newMapping);

        const fieldUpdate: DocumentFieldUpdateRequest = {
          field_id: field.technicalName,
          field_type: field.type,
          value: str,
          origin: DocumentFieldOrigin.Mapping,
          mapping: newMapping,
        };

        onChange(fieldUpdate);

        // After this we need to refocus the field
        // Note: we delay this to let onChange event execute first, before the focus again
        setTimeout((id) => {
          var domElement = window.document.getElementById(id);
          if (domElement) domElement.focus({ preventScroll: true });
        }, 50, id)
       
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, focusedField, focusedTable, selectedMappingWords]);

  // Helper function to format multi-lines text from mapping zones
  const formatTextFromMapping = (words: OcrWord[]): string => {
    // Detect lines and format the output string
    const lines = getWordsSortedByLines(words, pagesMetadata, false);
    let str = "";
    lines.forEach((line, index) => {
      if (index > 0) str += "\n";
      str += line.words.map((w) => w.content).join(" ");
    });

    return str;
  };

  const isActiveTableCell = focusedTable?.focusedCell?.cell.technicalName === field.technicalName && isFocused;

  return (
    <div className={`flex w-full items-center justify-between`}>
      <AutoGrowingInput
        id={id}
        value={value ?? ""}
        onChange={(e) => onChange({ field_id: field.technicalName, field_type: field.type, value: e.target.value, origin: DocumentFieldOrigin.Manual, mapping: [] })}
        onFocus={onFocus ?? undefined}
        className={`flex-1 border-0 ${isActiveTableCell ? "text-[10px] py-0 px-0.5 font-semibold" : "text-xs p-2  font-medium"} focus:ring-0 text-black`}
        autoFocus={autoFocus}
        onEnterPressed={onEnterPressed}
      />
      {origin !== DocumentFieldOrigin.None && !isActiveTableCell && <FieldOrigin field={field} origin={origin} />}
    </div>
  );
};

export default TextField;
