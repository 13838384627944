import React from 'react';
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";

import App from './App';

import './i18n';

import "./style.css";

import { Provider } from "react-redux";
import { store } from './redux/store';
import { injectStoreToApi } from 'services/api';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

// we need to insure that the store singleton is up and inject it to API interceptor that needs it
// this in order to avoid circular dependency 
// see: https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
injectStoreToApi(store)

export const msalConfig: Configuration = {
  auth: {
      clientId: "f49acca4-5cf5-4821-be4b-66f7ffb53e8d",
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: `${window.location.origin}/auth-callback`
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

console.log('NODE_ENV:', process.env.NODE_ENV)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
      <Toaster position="top-right" />
    </MsalProvider>
  </Provider>
)
