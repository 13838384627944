import { CatalogSearchResponse } from "models/catalog";
import api from "./api";

const CATALOG_API_URL = "/catalog";

class CatalogService {
  async search(searchText: string, searchDocumentsTypes: string[]): Promise<CatalogSearchResponse> {
    try {
      const res = await api.post(`${CATALOG_API_URL}/search`, { search_text: searchText, doc_types: searchDocumentsTypes })
      if (!res.data) throw new Error("No payload in response while success");
      return res.data
    } catch(err: any) {
      console.error(err);
      return {
        searchQuery: searchText,
        hits: {
          documents: {
            total: 0,
            hits: [],
          },
          jobfiles: {
            total: 0,
            hits: []
          }
        }
      }
    }
  }
}

const catalogServices = new CatalogService();
export default catalogServices;