import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab } from "@mui/material";
import { MdDelete } from "react-icons/md";

import JobFileService from "services/jobfiles.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setCurrentDocument } from "redux/labelling";
import { JobfileDocument } from "models/jobfile";

type DocumentDeleteButtonProps = {
  document: JobfileDocument;
  onDeletion: () => void;
};

export const DocumentDeleteButton: React.FC<DocumentDeleteButtonProps> = ({ document, onDeletion }) => {
  const dispatch = useAppDispatch();
  const [confirmationDelete, setConfirmationDelete] = React.useState<boolean>(false);
  const { currentDocument } = useAppSelector((state) => state.labelling);

  const handleDeleteDocument = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      console.warn(document);
      if (document._id && document.jobfile) {
        await JobFileService.unlinkDocument(document.jobfile, document._id);
      }

      // If the document deleted is the current one, then unfocus it
      if (document._id === currentDocument?._id) {
        dispatch(setCurrentDocument({ doc: null }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      onDeletion();
      setConfirmationDelete(false);
    }
  };

  return (
    <>
      <Fab
        variant="extended"
        size="small"
        className="button-red-reverse"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setConfirmationDelete(true);
        }}
        sx={{ zIndex: "0", marginLeft: -1, marginRight: 0.5 }}
      >
        <MdDelete />
      </Fab>
      <Dialog
        open={confirmationDelete}
        onClose={() => setConfirmationDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        slotProps={{ backdrop: { sx: { bgcolor: "rgba(0, 0, 0, .4)" } } }}
      >
        <DialogTitle id="alert-dialog-title">Delete document</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Do you confirm the deletion of {document.name ? <strong>{document.name}</strong> : "the document"}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="button-cancel"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setConfirmationDelete(false);
            }}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button className="button-red" onClick={handleDeleteDocument} sx={{ textTransform: "capitalize" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
