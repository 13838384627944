import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'models/user';
import userService from 'services/user.service';

export interface TenantUsersState {
    entities: IUser[];
    loading: boolean;
    error: string | undefined;
}

export const initialState: TenantUsersState = {
    entities: [],
    loading: false,
    error: undefined,
};

export const getAllUsers = createAsyncThunk<IUser[]>('users/getAllUsers', async () => {
    const tenantUsers = await userService.getAllUsers();
    return tenantUsers;
});

const tenantUserSlice = createSlice({
    name: "tenantUsers",
    initialState,
    reducers: {
        addUser(state, action: PayloadAction<IUser>) {
            state.entities.push(action.payload);
        },
        removeUser(state, action: PayloadAction<IUser>) {
            state.entities = state.entities.filter(user => user._id !== action.payload._id);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.entities = action.payload as IUser[];
                state.loading = false;
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { addUser, removeUser } = tenantUserSlice.actions;
export const reducer = tenantUserSlice.reducer;
