import React, { useEffect, useState } from "react";

import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { t } from "i18next";
import { useMsal } from "@azure/msal-react";
import { PopupRequest } from "@azure/msal-browser";
import { MdOutlineEmail, MdLockOutline } from "react-icons/md";
import { InputAdornment, TextField } from "@mui/material";

import { login, logout } from "redux/users";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import api from "services/api";
import authService from "services/auth.service";

import logo from "assets/logotext-white.png";
import background from "assets/bg-auth.png";
import { ReactComponent as MicrosoftLogo } from "assets/svg/microsoft_icon.svg";
import Button, { ButtonStyle } from "components/Button";

type UserLoginInfo = {
  email: string;
  password: string;
};

export const loginRequest: PopupRequest = {
  scopes: ["openid", "profile", "email"],
  prompt: "select_account",
};

export default function Login() {
  let navigate: NavigateFunction = useNavigate();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);
  const { instance } = useMsal();

  const [userLoginInfo, setUserLoginInfo] = useState<UserLoginInfo>({ email: "", password: "" });
  const [isClassicSigninDisplayed, setIsClassicSigninDisplayed] = useState<boolean>(false);

  // On userState load / change, redirect to homepage if the user is logged
  useEffect(() => {
    if (userState.loggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [userState]);

  // Handle click on classic signin button
  const handleClassicLogin = () => {
    setIsClassicSigninDisplayed(true);
  };

  // Handle input field changes
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserLoginInfo({
      ...userLoginInfo,
      [event.target.name]: event.target.value,
    });
  };

  // Submit classic login form
  const onClassicLoginFormSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const { email, password } = userLoginInfo;
    try {
      const data = await authService.login(email, password);

      const payload = {
        email: data.email,
        tenant_id: data.tenant_id,
      };
      dispatch(login(payload));
    } catch (err) {
      toast.error(t("signin_view.classic_login.error"));
      dispatch(logout());
    }

    return false;
  };

  //
  // Microsoft signin related functions
  //

  // Handle click on microsoft signin button and trigger the MS login process
  const handleMicrosoftLogin = async () => {
    try {
      const res = await instance.loginPopup(loginRequest);
      console.log("Successfully authenticated to Microsoft");
      const idToken = res.idToken;
      if (!idToken) {
        console.error("Msal response has no id token");
        console.log(res);
        toast.error(t("signin_view.microsoft.error"));
        return;
      }
      const data = await sendMsalTokenToBackend(idToken);
      const payload = {
        email: data?.email,
        tenant_id: data?.tenant_id,
      };
      dispatch(login(payload));
    } catch (err) {
      toast.error(t("signin_view.microsoft.error"));
      dispatch(logout());
    }
  };

  // API Call to backend to validate the authentication
  const sendMsalTokenToBackend = async (token: string) => {
    try {
      const response = await api.post(
        "/auth/oauth2",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return {
        email: response.data.email,
        tenant_id: response.data.tenant_id,
      };
    } catch (error) {
      console.error("Error sending access token to backend:", error);
      throw error;
    }
  };

  //
  // Render
  //
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 bg-cover bg-center bg-no-repeat px-4" style={{ backgroundImage: `url(${background})` }}>
      <div className="flex overflow-hidden rounded-xl bg-white">
        <div className="flex items-center bg-gradient px-20">
          <img className="h-32" src={logo} alt="" />
        </div>
        <div className="p-10 text-center">
          <h3 className="text-xl font-semibold text-black">{t("signin_view.title")}</h3>

          <div className="h-[1px] bg-gray-100 w-50 mt-6 mb-6 block" />
          <div className="text-center w-72">
            <Button
              text={t("signin_view.microsoft.cta")}
              leftIcon={<MicrosoftLogo className="w-5 inline mr-3 -mt-0.5 py-1" />}
              color={ButtonStyle.DarkGray}
              className="border w-full"
              onClick={handleMicrosoftLogin}
            />
            <p className="my-6 font-semibold text-sm text-gray-800">{t("signin_view.separator_text")}</p>
            {!isClassicSigninDisplayed && (
              <Button
                text={t("signin_view.classic_login.cta")}
                leftIcon={<MdOutlineEmail className="inline mr-3 -mt-0.5 py-1 text-2xl" />}
                color={ButtonStyle.White}
                className="border w-full"
                onClick={handleClassicLogin}
              />
            )}
          </div>

          {isClassicSigninDisplayed && (
            <form className="w-72 space-y-6" onSubmit={onClassicLoginFormSubmit}>
              <TextField
                type="email"
                name="email"
                autoFocus={true}
                label={t("global_field.email")}
                variant="outlined"
                onChange={(event) => handleInputChange(event)}
                size={"small"}
                className="w-full"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdOutlineEmail />
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: 13,
                    paddingTop: 3,
                    paddingBottom: 3
                  }
                }}
              />
              <TextField
                type="password"
                name="password"
                label={t("global_field.password")}
                variant="outlined"
                onChange={(event) => handleInputChange(event)}
                size={"small"}
                className="w-full"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdLockOutline />
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: 13,
                    paddingTop: 3,
                    paddingBottom: 3
                  }
                }}
              />

              <Button className="w-full" type="submit" color={ButtonStyle.Blue} text={t("signin_view.main_cta")} />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
