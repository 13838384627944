import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Backdrop, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import { FaRegSave } from "react-icons/fa";
import { CiRead, CiUnread } from "react-icons/ci";
import { FiDownload, FiEye, FiSend } from "react-icons/fi";
import { HiRefresh } from "react-icons/hi";

import { useAppDispatch } from "redux/hooks";
import { setBreadcrumbsMenuItems } from "redux/navigation";
import JobfilesService from "services/jobfiles.service";
import DocumentsService from "services/documents.service";
import { IJobFile, IJobFileDataEntry, JobfileDocument, JobfileStatus } from "models/jobfile";
import { IUseCase, IUsecaseConnectorDataField, IUsecaseConnectorSection } from "models/usecase";
import { IDocument } from "models/document";

import SectionBloc from "components/SectionBloc";
import ValidationField from "./components/ValidationField";
import Loading from "components/Loading";
import Button, { ButtonStyle } from "components/Button";
import { PageViewer } from "components/document/PageViewer";
import DropdownMenu, { DropdownMenuItem } from "components/DropdownMenu";
import TableSectionBloc from "./components/TableSectionBloc";

/*
 * JobFileValidation screen has four responsibilities
 * (1) [READ] Display jobfile content - WIP for table data
 * (2) [UPDATE] Enable user to update jobfile content - WIP for table data
 * (3) [DOWNLOAD] Download available export formats
 * (4) [SEND] Trigger connector to send transaction to target application (API, FTP...)
 */
export default function JobFileValidation() {
  const dispatch = useAppDispatch();

  const [useCase, setUseCase] = useState<IUseCase | null>(null);
  const [jobfile, setJobfile] = useState<IJobFile | null>(null);
  const [exportFormatters, setExportFormatters] = useState<string[] | null>([]);
  const [rawOutput, setRawOutput] = useState<string | null>(null);
  const [hasConfigChanged, setHasConfigChanged] = useState<boolean>(false);
  const [documentDisplayed, setDocumentDisplayed] = useState<IDocument | null>(null);
  const [documents, setDocuments] = useState<JobfileDocument[]>([]);
  const [isActionProcessing, setIsActionProcessing] = useState<boolean>(false);

  let { jobFileId } = useParams();

  useEffect(() => {
    if (!jobFileId) return;
    loadJobfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobFileId]);

  // If jobfile is in PostProcessing statestate, force reload after few seconds
  useEffect(() => {
    if (jobfile?.status === JobfileStatus.Processing) {
      setTimeout(() => loadJobfile(), 5000);
    }

    dispatch(
      setBreadcrumbsMenuItems([
        { text: t("breadcrumbs_menu.jobfiles"), path: "/", state: { search_type: "jobfiles" }, icon: "folders" },
        { text: jobfile?.name ?? "", path: `/jobfile/${jobfile?._id}/documents`, icon: "folder" },
        { text: t("jobfile_validation.breadcrumb_item") ?? "" },
      ])
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobfile]);

  // If raw output is updated, scroll automatically to bottom of the screen
  useEffect(() => {
    if (!rawOutput) return;

    document.getElementById("preview_output")?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [rawOutput]);

  // Retrieve jobfile from API
  const loadJobfile = async () => {
    if (!jobFileId) return;

    try {
      const result = await JobfilesService.getJobfile(jobFileId);
      if (result) {
        setJobfile(result);

        // WIP: Display the configurator for the first connector only as for now, we only have one by usecase
        // const jobfileConnector = result.usecase?.connectors?.[0] ?? null;
        const jobfileUseCase = result.usecase;
        setUseCase(jobfileUseCase);

        const exportFormatters = result.usecase?.exportFormatters ?? null;
        setExportFormatters(exportFormatters);

        // Load associated documents (for preview feature and maybe more)
        const result2 = await JobfilesService.getJobfileDocuments(jobFileId);
        setDocuments(result2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //
  // UI Callbacks
  //

  const onFieldValueChange = (fieldId: string, value: any) => {
    const updatedJobfile = { ...jobfile! };
    updatedJobfile.data![fieldId].value = value;
    setJobfile(updatedJobfile);

    setHasConfigChanged(true);
    setRawOutput(null);
  };

  const onSaveClick = async () => {
    if (!jobfile) return;
    try {
      await JobfilesService.updateData(jobfile._id, jobfile.data);
      toast.success(t("usecase_configuration.updated_popup"));
      setHasConfigChanged(false);
    } catch (error) {
      console.error(error);
      toast.error(t("usecase_configuration.update_error"));
    }
  };

  // Execute the connector action
  const onDownloadClick = async (exportFormatter?: string, previewMode = false) => {
    if (!jobfile?.usecase) return;

    // If there is change(s) not saved, display a confirm popup first
    if (hasConfigChanged) {
      if (!window.confirm(t("jobfile_validation.sending_changes_not_saved_override"))) return;
    }

    // Validation
    const jobfileData = jobfile.data;

    // Case no data
    if (Object.keys(jobfileData).length === 0) alert("No data to send !");

    const errors: string[] = [];
    // For each section and each field in the usecase, check if it's required and if all jobfile fields are filled
    jobfile.usecase.sections.forEach((section) => {
      section.data.forEach((field) => {
        // Key values: Usecase requires the field but jobfile key/value field is not filled => ERROR
        if (!section.table_id && field.required && (!jobfileData[field.id] || !jobfileData[field.id].value)) errors.push(`${section.name} - ${field.label}`);
        // Table values: Usecase requires the field but jobfile table column has at least one empty cell => ERROR
        else if (section.table_id && jobfileData[section.table_id]) {
          const columnValues = jobfileData[section.table_id].value.map((row: any) => row[field.id].value);
          // Get positions of empty values
          const indexes = columnValues.filter((value: any) => !value).map((value: any, index: number) => index + 1);
          if (field.required && indexes.length > 0) errors.push(`${section.name} - ${field.label} (${indexes.join(", ")})`);
        } else if (section.table_id && !jobfileData[section.table_id]) {
          // Table values: jobfile has no table content so we check if at least one column is required
          if (field.required) errors.push(`${section.name} - ${field.label}`);

        }
      });
    });

    if (errors.length > 0) {
      toast.error(t("jobfile_validation.required_field_missing") + "\n" + errors.join("\n"));
      return;
    }

    try {
      setIsActionProcessing(true);
      if (exportFormatter) {
        const res = await downloadExportFormat(jobfile, exportFormatter, previewMode);
        if (res && previewMode) {
          // If we are in preview mode, display raw output
          setRawOutput(res);
        }
      } else if (exportFormatters && exportFormatters.length > 0) {
        // Async Iterate over exportFormatters
        for (const exportFormatter of exportFormatters) {
          await downloadExportFormat(jobfile, exportFormatter);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsActionProcessing(false);
    }
  };

  const downloadExportFormat = async (jobfile: IJobFile, exportFormatter: string, previewMode: boolean = false) => {
    try {
      setRawOutput(null);
      const { success, error, output } = await JobfilesService.download(jobfile._id, exportFormatter);
      if (success && output) {
        const { raw, raw_filename, raw_mimetype } = output;
        // If we are in preview mode, don't trigger the download
        if (!previewMode) {
          let input: any = raw;
          // If export_formatter returns a .xlsx encoded in base64 string
          if (isBase64encoded(raw)) input = base64toBlob(raw);
          const blob = new Blob([input], { type: raw_mimetype });
          // Create a link for the Blob
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = raw_filename;
          document.body.appendChild(link);
          link.click();

          // Clean up by revoking the Blob URL and removing the link
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
          toast.success(`${t("jobfile_validation.download_success")}\n${exportFormatter}\n${raw_filename}`);
        }
        return raw;
      } else {
        toast.error(`${t(error ?? "jobfile_validation.download_error")} \n${exportFormatter}`, { style: { maxWidth: 800 } });
        return;
      }
    } catch (error: any) {
      if (error.response.data.error) {
        // Resolved AxiosError
        toast.error(`${t("jobfile_validation.download_error")} \n${t(error.response.data.error)} \n${exportFormatter}`);
      } else {
        // Error
        toast.error(`${t("jobfile_validation.download_error")} \n${error} \n${exportFormatter}`);
      }
      return;
    }
  };

  const isBase64encoded = (str: string) => {
    // Use regex
    return str.match(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/);
    // // Alternative
    // try {
    //   return btoa(atob(str)) === str;
    // } catch (err) {
    //   return false;
    // }
  };

  const base64toBlob = (base64: string) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  const onRefreshJobfileClick = async () => {
    if (!jobfile) return;
    try {
      if (window.confirm(t("jobfile_validation.refresh_jobfile_confirm"))) {
        const updatedJobfile = await JobfilesService.validateJobfile(jobfile._id);
        setJobfile(updatedJobfile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onSendClick = async () => {
    // If no connector, button should not even be displayed
    if (!jobfile?.usecase || !useCase?.connector) return;

    // If there is change(s) not saved, display a confirm popup first
    if (hasConfigChanged) {
      if (!window.confirm(t("jobfile_validation.sending_changes_not_saved_override"))) return;
    }

    // Validation
    const jobfileData = jobfile.data;

    // Case no data
    if (Object.keys(jobfileData).length === 0) alert("No data to send !");

    // Flatten all jobfile fields
    const fields = jobfile.usecase.sections.reduce((acc: IUsecaseConnectorDataField[], curr: IUsecaseConnectorSection) => [...acc, ...curr.data], []);

    // Check required fields
    const errors = [];
    for (const key in jobfileData) {
      const connectorField = fields.find((f) => f.id === key);
      if (connectorField && connectorField.required && !jobfileData[key].value) {
        errors.push(t(`${useCase?.connector}.${connectorField.id}`, connectorField.label));
      }
    }
    if (errors.length > 0) {
      toast.error(t("jobfile_validation.required_field_missing") + "\n" + errors.join("\n"));
      return;
    }
    try {
      const { success, error, output } = await JobfilesService.export(jobfile._id, useCase?.connector);
      if (success && output) {
        toast.success(t("jobfile_validation.send_success"));
        if (output.raw) setRawOutput(output.raw);
      } else {
        toast.error(`${t("jobfile_validation.send_error")} \n ${error}`);
      }
    } catch (error: any) {
      if (error.response.data.error) {
        // Resolved AxiosError
        toast.error(`${t("jobfile_validation.send_error")} \n ${t(error.response.data.error)}`);
      } else {
        // Error
        toast.error(`${t("jobfile_validation.send_error")} \n ${error}`);
      }
    }
  };

  const handleDocumentDisplayAction = async () => {
    try {
      if (documentDisplayed) setDocumentDisplayed(null);
      else if (documents.length > 0) {
        const doc = await DocumentsService.getDocument(documents[0]._id);
        setDocumentDisplayed(doc);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //
  // Rendering
  //

  const PROCESSING = jobfile?.status === JobfileStatus.Processing;

  const exportFormattersOptionsDownload: DropdownMenuItem[] = exportFormatters
    ? [
      ...exportFormatters.map((exportFormatter, index) => ({
        text: exportFormatter,
        leftIcon: <FiDownload className="text-lg mr-2 opacity-60" />,
        onClick: () => onDownloadClick(exportFormatter),
        separator: exportFormatters.length > 1 && index === 0 ? true : false, // Add a top separator for the first download action
      })),
      ...exportFormatters.map((exportFormatter, index) => ({
        text: `${t("jobfile_validation.preview")} ${exportFormatter}`,
        leftIcon: <FiEye className="text-lg mr-2 opacity-60" />,
        onClick: () => onDownloadClick(exportFormatter, true),
        separator: index === 0 ? true : false, // Add a top separator for the first preview action
      })),
    ]
    : [];

  if (exportFormatters && exportFormatters.length > 1)
    exportFormattersOptionsDownload.unshift({
      text: t("jobfile_validation.download_all"),
      leftIcon: <FiDownload className="text-lg mr-2 opacity-60" />,
      onClick: () => onDownloadClick(),
    });

  return (
    <div className="h-full">
      <Helmet>
        <title>Jobfile - Validation</title>
      </Helmet>

      <div className="h-full flex flex-col overflow-hidden">
        <div className="absolute top-14 right-0 z-20 px-2 flex flex-row items-center rounded-bl-md">
          <div className="flex items-center mt-1">
            {/* Compute jobfile */}
            <div className="grow-0">
              <Button
                leftIcon={<HiRefresh className="inline text-xl" />}
                tooltip={t("jobfile_validation.recompute_jobfile")}
                text=""
                onClick={onRefreshJobfileClick}
                color={ButtonStyle.Primary}
                small={true}
              />
            </div>
            {/* Display document */}
            <div className="grow-0 ml-3">
              <Button
                leftIcon={documentDisplayed ? <CiUnread className="inline text-xl" /> : <CiRead className="inline text-xl" />}
                tooltip={documentDisplayed ? t("jobfile_validation.hide_document") : t("jobfile_validation.display_document")}
                text=""
                onClick={handleDocumentDisplayAction}
                color={ButtonStyle.Blue}
                small={true}
              />
            </div>

            {/* Primary actions */}
            {!PROCESSING && (
              <>
                {/* Save changes */}
                <div className="grow-0 ml-3">
                  {hasConfigChanged && <span className="text-xs font-medium italic text-gray-500 mr-3">{t("usecase_configuration.changes_detected")}</span>}
                  <Button
                    leftIcon={<FaRegSave className="inline text-xl" />}
                    text=""
                    tooltip={t("usecase_configuration.save_button")}
                    onClick={onSaveClick}
                    color={ButtonStyle.Gray}
                    disabled={hasConfigChanged === false}
                    small={true}
                  />
                </div>
                {/* Trigger connector action */}
                {useCase && useCase.connector && (
                  <div className="grow-0 ml-3">
                    <Button
                      text=""
                      tooltip={t(`jobfile_validation.send_button`)}
                      disabled={hasConfigChanged}
                      leftIcon={<FiSend className="inline text-xl" />}
                      color={ButtonStyle.Primary}
                      onClick={onSendClick}
                      small={true}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {PROCESSING && (
          <div className="mt-20">
            <span className="text-center text-gray-600 block mb-10 whitespace-pre-line">{t("jobfile_validation.post_processing")}</span>
            <Loading />
          </div>
        )}

        {jobfile && !PROCESSING && (
          <Grid container spacing={1} className="grow overflow-hidden relative z-0">
            <Grid item xs={documentDisplayed ? 6 : 12} sm={documentDisplayed ? 6 : 12} lg={documentDisplayed ? 5 : 12} xl={documentDisplayed ? 4 : 12} className="h-full w-full overflow-scroll">
              <Grid container spacing={1} className="p-3">
                {jobfile.usecase?.sections?.map((section) => {
                  return (
                    <Grid key={`section - ${section.name}`} item xs={12} md={documentDisplayed || section.table_id ? 12 : 4}>
                      {!section.table_id && (
                        <SectionBloc title={section.name} className="mb-1">
                          {section.data.map((field) => (
                            <ValidationField
                              key={`section_field_${field.id}`}
                              field={field}
                              value={jobfile.data[field.id]?.value ?? ""}
                              overrided={jobfile.data[field.id]?.value !== jobfile.data[field.id]?.original_value}
                              onChange={(value: any) => onFieldValueChange(field.id, value)}
                              onReset={() => onFieldValueChange(field.id, jobfile.data[field.id]?.original_value)}
                            />
                          ))}
                        </SectionBloc>
                      )}

                      {section.table_id && (
                        <TableSectionBloc
                          section={section}
                          data={jobfile.data[section.table_id]?.value as { [key: string]: IJobFileDataEntry }[]}
                          onChange={(value: any) => onFieldValueChange(section.table_id!, value)}
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
              {rawOutput && (
                <Grid container spacing={1} className="px-3">
                  <Grid item xs={12} md={documentDisplayed ? 12 : 24} className="w-full">
                    <SectionBloc title={t(`usecase_configuration.preview_section`)} className="w-full font-mono whitespace-pre break-all text-medium text-[#64748B] alwaysShowScrollbar">
                      <div className="overflow-auto max-h-[300px]" id="preview_output">
                        {rawOutput}
                      </div>
                    </SectionBloc>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {documentDisplayed && (
              <Grid item xs={6} sm={6} md={6} lg={7} xl={8} className="h-full overflow-scroll relative z-0">
                <PageViewer disableAnnotation={true} doc={documentDisplayed} />
              </Grid>
            )}
            {/* Secondary actions */}
            <DropdownMenu
              button={
                <Button
                  text=""
                  tooltip={t("jobfile_validation.download_file")}
                  disabled={hasConfigChanged}
                  leftIcon={<FiDownload className="inline text-2xl" />}
                  className="absolute bottom-3 right-5 z-50"
                  color={ButtonStyle.Primary}
                />
              }
              items={exportFormattersOptionsDownload}
            />
          </Grid>
        )}
      </div>
      <Backdrop className="z-20 text-white flex-col" open={isActionProcessing}>
        <span className="text-lg mb-10 block">{t("jobfile_validation.processing_action")}</span>
        <CircularProgress color="inherit" size={80} />
      </Backdrop>
    </div>
  );
}
