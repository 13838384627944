import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJobFile, JobfileDocument } from "models/jobfile";
import JobFileService from "services/jobfiles.service";

export type JobfileState = {
  currentJobfile: IJobFile | null;
  currentJobfileDocuments: JobfileDocument[];
  loading: boolean;
  updating: boolean;
  isLeftPanelVisible: boolean; // Is the left panel visible or collapsed
};

export const initialState: JobfileState = {
  currentJobfile: null,
  currentJobfileDocuments: [],
  loading: false,
  updating: false,
  isLeftPanelVisible: true,
};

// Declare all async tasks
type JobfileLoadingPayload = {
  jobfile: IJobFile;
  documents: JobfileDocument[];
};
export const loadJobfileAndDocuments = createAsyncThunk("jobfile/loadJobfile", async ({ jobFileId, displayLoader }: { jobFileId: string; displayLoader?: boolean }): Promise<JobfileLoadingPayload> => {
  const jobfile = await JobFileService.getJobfile(jobFileId);
  const documents = await JobFileService.getJobfileDocuments(jobFileId);

  return { jobfile, documents };
});

export const selectUsecaseForJobfile = createAsyncThunk("jobfile/selectUsecase", async ({ jobFileId, useCaseId }: { jobFileId: string; useCaseId: string }, { dispatch }) => {
  const jobFile = await JobFileService.setUseCase(jobFileId, useCaseId);

  return jobFile;
});

export const resetUsecaseForJobfile = createAsyncThunk("jobfile/resetUsecase", async ({ jobFileId }: { jobFileId: string }, { dispatch }) => {
  const jobFile = await JobFileService.resetUsecase(jobFileId);

  return jobFile;
})

export const setNameForJobfile = createAsyncThunk("jobfile/setName", async ({ jobFileId, name }: { jobFileId: string; name: string }, { dispatch }) => {
  const jobFile = await JobFileService.setName(jobFileId, name);

  return jobFile;
});

// Core
const jobfileSlice = createSlice({
  name: "jobfile",
  initialState,
  reducers: {
    setCurrentJobFile(state, action: PayloadAction<IJobFile | null>) {
      state.currentJobfile = action.payload;
    },
    setLeftPanelVisibility(state, action: PayloadAction<boolean>) {
      state.isLeftPanelVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadJobfileAndDocuments.pending, (state, action) => {
      if (action.meta.arg.displayLoader === true) state.loading = true;
    });
    builder.addCase(loadJobfileAndDocuments.fulfilled, (state, action: PayloadAction<JobfileLoadingPayload>) => {
      state.currentJobfile = action.payload.jobfile;
      state.currentJobfileDocuments = action.payload.documents;
      state.loading = false;
    });
    builder.addCase(loadJobfileAndDocuments.rejected, (state, action) => {
      state.currentJobfile = null;
      state.currentJobfileDocuments = [];
      state.loading = false;
    });

    builder.addCase(selectUsecaseForJobfile.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(selectUsecaseForJobfile.fulfilled, (state, action: PayloadAction<IJobFile>) => {
      state.currentJobfile = action.payload;
      state.updating = false;
    });
    builder.addCase(selectUsecaseForJobfile.rejected, (state, action) => {
      state.updating = false;
    });

    builder.addCase(setNameForJobfile.pending, (state) => {
      state.updating = true;
    });
    builder.addCase(setNameForJobfile.fulfilled, (state, action: PayloadAction<IJobFile>) => {
      state.currentJobfile = action.payload;
      state.currentJobfile.name = action.payload.name;
      state.updating = false;
    });
    builder.addCase(setNameForJobfile.rejected, (state, action) => {
      state.updating = false;
    });
  },
});

export const { setCurrentJobFile, setLeftPanelVisibility } = jobfileSlice.actions;
export const reducer = jobfileSlice.reducer;
