import React, { useState } from "react";
import Select from "react-select";
import { t } from "i18next";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { loadJobfileAndDocuments } from "redux/jobfile";
import { IDocumentType } from "models/document_type";

import { useDocumentTypes } from "hooks/DocumentTypesHook";
import { selectCustomStyles } from "components/SelectSearch";
import { DocumentStatus } from "models/document";

type DocumentTypeSelectorProps = {
  selectedDocumentTypeId: string | undefined;
  onChange: (docType: IDocumentType | null) => Promise<void>;
};

export const DocumentTypeSelector: React.FC<DocumentTypeSelectorProps> = ({ selectedDocumentTypeId, onChange }) => {
  const dispatch = useAppDispatch();
  const { currentJobfile } = useAppSelector((state) => state.jobfile);
  const { currentDocument } = useAppSelector((state) => state.labelling);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { allowedDocumentsTypes } = useDocumentTypes();

  // Format documentsTypes for the select component
  const formatDocumentsTypesSelectOptions = () => {
    return allowedDocumentsTypes.map((dt) => ({ value: dt.name, label: t(`document_type.${dt.name}`, dt.displayName) }));
  };

  // Triggering when a doc type is selected
  const onDocumentTypeSelection = async (option: any) => {
    const docType = option?.value ? allowedDocumentsTypes.find((dt) => dt.name === option.value) : null;
    setIsUpdating(true);
    try {
      await onChange(docType ?? null);
    } catch (error) {
      console.error(error);
    }
    setIsUpdating(false);

    // If we are inside a jobfile, refresh documents list
    if (currentJobfile) {
      dispatch(loadJobfileAndDocuments({ jobFileId: currentJobfile._id }));
    }
  };

  const selectedDocType = allowedDocumentsTypes.find((dt) => dt.name === selectedDocumentTypeId);
  const isProcessing = currentDocument?.status === DocumentStatus.Processing ?? false;
  const hasUsecase = currentJobfile?.usecase ? true : false;

  return (
    <div className="px-2 py-3 border-b border-docloop_borderColor">
      <Select
        classNamePrefix="docType_select"
        isSearchable={true}
        isClearable={true}
        name="doc_type"
        value={selectedDocType ? { value: selectedDocType.name, label: t(`document_type.${selectedDocType.name}`, selectedDocType.displayName) } : null}
        placeholder={hasUsecase ? t("labelling_panel.doc_type_select_placeholder") : t("labelling_panel.doc_type_select_placeholder_no_usecase")}
        options={formatDocumentsTypesSelectOptions()}
        onChange={onDocumentTypeSelection}
        styles={selectCustomStyles}
        isDisabled={isUpdating || isProcessing || !hasUsecase}
        isLoading={isUpdating || isProcessing}
        tabSelectsValue={false}
        tabIndex={-1}
      />
    </div>
  );
};
