import React from "react";
import { GiBroccoli } from "react-icons/gi";

type LoadingProps = {
  className?: string;
};

const Loading: React.FC<LoadingProps> = ({ className = "" }) => {
  return (
    <div className={`${className} flex h-full w-full items-center justify-center`}>
      <GiBroccoli className="animate-spin text-5xl text-[#38aa12]" />
    </div>
  );
}

export default Loading;