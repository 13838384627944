import React from "react";

type UserBadgeProps = {
  email: string;
  firstName?: string;
  lastName?: string;
  className?: string;
};

const backgroundColors = ["#fdae61", "#fee08b", "#e6f598", "#abdda4", "#66c2a5", "#3288bd", "#3288bd", "#9e0142", "#d53e4f", "#f46d43"];

function hashString(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function getColorForUserName(user: string): string {
  const hash = hashString(user);
  const colorIndex = Math.abs(hash) % backgroundColors.length; // Use modulus to ensure the index is within bounds
  return backgroundColors[colorIndex];
}

const UserBadge: React.FC<UserBadgeProps> = ({ email, firstName, lastName, className = "" }) => {
  if (!email || email.length === 0) return;

  const emailInitial = email[0];
  const firstNameInitial = firstName && firstName.length > 0 ? firstName[0] : null;
  const lastNameInitial = lastName && lastName.length > 0 ? lastName[0] : null;

  let initials = "";
  if (firstNameInitial) initials.concat(firstNameInitial);
  if (lastNameInitial) initials.concat(lastNameInitial);

  // If firstname AND lastname are empty, then use email first letter as user initial
  if (initials.length === 0) initials = emailInitial;

  return (
    <div
      className={`shadow-base flex h-8 w-8 cursor-pointer select-none items-center justify-center rounded-full text-xs font-medium tracking-widest text-white ${className ?? ""}`}
      style={{ backgroundColor: getColorForUserName(initials), textShadow: "1px 1px 1px #444444" }}
      title={`${email}`}
    >
      {initials.toUpperCase()}
    </div>
  );
};

export default UserBadge;
