import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export type DropdownMenuItem = {
  leftIcon?: React.ReactNode;
  text?: string;
  onClick: () => void;
  separator?: boolean;
};

type DropdownMenuProps = {
  button: React.ReactNode;
  items: DropdownMenuItem[];
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ button, items }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div data-dropdown-menu={1} className="cursor-pointer" onClick={handleClick}>
        {button}
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} autoFocus={false} MenuListProps={{ sx: { py: 0 } }}>
        {items.map((item, index) => (
          <MenuItem
            key={`menu-${index}`}
            sx={{
              fontSize: 13,
              borderTop: item.separator ? "1px solid #EFEFEF" : null,
            }}
            onClick={() => {
              handleClose();
              item.onClick();
            }}
          >
            {item.leftIcon}
            {item.text ?? ""}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
