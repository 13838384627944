import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

import { LuUsers, LuFiles, LuFile, LuFolders, LuFolder } from "react-icons/lu";
import { IoHomeOutline } from "react-icons/io5";

const NavigationBreadcrumbs: React.FC = () => {
  const { navItems } = useAppSelector((state) => state.navigation);

  const renderIcon = (icon: string): React.ReactNode => {
    switch (icon) {
      case "home":
        return <IoHomeOutline className="inline mr-2 -mt-0.5" />;
      case "users":
        return <LuUsers className="inline mr-2 -mt-0.5" />;
      case "documents":
        return <LuFiles className="inline mr-2 -mt-0.5" />;
      case "document":
        return <LuFile className="inline mr-2 -mt-0.5" />;
      case "folders":
        return <LuFolders className="inline mr-2 -mt-0.5" />;
      case "folder":
        return <LuFolder className="inline mr-2 -mt-0.5" />;

      default:
        return null;
    }
  };

  if (!navItems || navItems.length === 0) return;

  return (
    <div className="flex flex-row w-full grow-0 px-3 py-2 bg-white border-b border-docloop-borderColor shadow relative z-10 select-none">
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        className="grow self-center"
        sx={{
          ".MuiBreadcrumbs-separator": { marginTop: "-1px" },
        }}
      >
        {navItems.map((menuItem, index) => {
          if (menuItem.path)
            return (
              <Link key={`breadcrumb-link-${index}`} component={RouterLink} underline="hover" color="inherit" to={menuItem.path} state={menuItem.state ?? null} fontSize={12} className="float-left">
                {menuItem.icon && renderIcon(menuItem.icon)}
                {menuItem.text}
              </Link>
            );
          else
            return (
              <Typography key={`breadcrumb-text-${index}`} color="text.primary" fontSize={12}>
                {menuItem.icon && renderIcon(menuItem.icon)}
                {menuItem.text}
              </Typography>
            );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default NavigationBreadcrumbs;
