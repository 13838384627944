import React, { useState } from "react";
import { IUsecaseConnectorSection } from "models/usecase";
import { IJobFileDataEntry } from "models/jobfile";
import SectionBloc from "components/SectionBloc";
import ValidationField from "./ValidationField";

type TableSectionBlocProps = {
  section: IUsecaseConnectorSection;
  data: { [key: string]: IJobFileDataEntry }[];

  onChange: (value: any) => void;
};
const TableSectionBloc: React.FC<TableSectionBlocProps> = ({ section, data, onChange }) => {
  const [referentialLookupDisplayedAtLine, setReferentialLookupDisplayedAtLine] = useState<number | null>(null);

  const onTableFieldValueChange = (row: number, field_id: string, value: string) => {
    const updatedData = [...data];
    updatedData[row][field_id].value = value;

    onChange(updatedData);
  };

  const onReferentialLookupMenuOpen = (rowIndex: number) => {
    setReferentialLookupDisplayedAtLine(rowIndex);
  }

  const onReferentialLookupMenuClose = (rowIndex: number) => {
    setReferentialLookupDisplayedAtLine(null);
  }

  return (
    <SectionBloc title={section.name} className="mb-1 w-full alwaysShowScrollbar">
      <div className="overflow-auto max-h-[300px]">
        <table className="mb-3 table-fixed w-full">
          <thead className="bg-slate-100 sticky top-0" style={{ zIndex: 10 }}>
            <tr>
              <th scope="col" className="bg-slate-100 sticky z-10 left-0 w-10 text-[11px] leading-tight font-medium text-gray-900 px-2 py-1 text-left border-r border-b" />
              {section.data?.map((field, index) => (
                <th
                  key={`pretty-output-header-${index}`}
                  className="bg-slate-100 text-[11px] px-2 py-2 w-[100px] break-words leading-tight font-medium text-gray-900 text-left border-r border-b relative group"
                >
                  {field.label} {field.required && <span className="text-red-500">*</span>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border-b">
            {data?.map((row, rowIndex) => (
              <tr key={`${section.table_id!}-${rowIndex}`} style={{ position: "relative", zIndex: referentialLookupDisplayedAtLine === rowIndex ? "50" : "0" }}>
                <td className={`sticky z-0 left-0 text-[10px] text-center font-semibold px-0.5 py-1 select-none bg-white`}>
                  {rowIndex + 1}
                  <div className="w-[1px] absolute top-0 bottom-0 -right-[0.5px] bg-slate-200" />
                </td>
                {section.data.map((field, index) => (
                  <td key={`${section.table_id!}-${rowIndex}-${index}`} className="text-[10px] border-r border-b p-0 whitespace-pre-line break-words relative z-0">
                    <ValidationField
                      field={field}
                      value={row[field.id]?.value ?? ""}
                      overrided={row[field.id]?.value !== row[field.id]?.original_value}
                      onChange={(value: string) => onTableFieldValueChange(rowIndex, field.id, value)}
                      onReset={() => onTableFieldValueChange(rowIndex, field.id, row[field.id]?.original_value)}
                      tableMode={true}
                      onReferentialLookupMenuOpen={() => onReferentialLookupMenuOpen(rowIndex)}
                      onReferentialLookupMenuClose={() => onReferentialLookupMenuClose(rowIndex)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </SectionBloc>
  );
};

export default TableSectionBloc;
