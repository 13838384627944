import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import UserService from "services/user.service";
import { IUser } from "models/user";

export interface ILogin {
  email: string;
  tenant_id: string;
}

export type UserState = {
  email: string | undefined;
  loggedIn: boolean | undefined;
  currentUser: IUser | null;
};

export type SaveSearchQueryRequest = {
  queryName: string;
  searchText: string;
  searchDocumentsTypes: string[];
}

// Helpers to get / set login status locally
const getLoginStatus = () => {
  const status = localStorage.getItem("LoginStatus");
  if (!status) return false;
  return status === "true" ? true : false;
};

const setLoginStatus = (state: boolean) => {
  localStorage.setItem("LoginStatus", state ? "true" : "false");
  return state;
};

export const initialState: UserState = {
  email: undefined,
  loggedIn: getLoginStatus(),
  currentUser: null
};

export const retrieveUserProfile = createAsyncThunk("users/getUserProfile", async () => {
  const user = await UserService.getCurrentUser();
  return user;
});

export const saveSearchQuery = createAsyncThunk("users/saveSearchQuery", async (req: SaveSearchQueryRequest) => {
  const updatedUser = await UserService.saveSearchQuery(req.queryName, req.searchText, req.searchDocumentsTypes);
  return updatedUser;
});

export const deleteSearchQuery = createAsyncThunk("users/deleteSearchQuery", async (queryName: string) => {
  const updatedUser = await UserService.deleteSearchQuery(queryName);
  return updatedUser;
});


const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    login(state, action: PayloadAction<ILogin>) {
      state.loggedIn = setLoginStatus(true);
      state.email = action.payload.email;
    },
    logout(state) {
      // do not reinit to initialState as it can be loggedIn = true
      state.loggedIn = setLoginStatus(false);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveUserProfile.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });

    builder.addCase(saveSearchQuery.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(saveSearchQuery.rejected, (state, action) => {
      console.error("Failed to save the query" + action.error.message);
      throw new Error("Failed to save the query");
    });

    builder.addCase(deleteSearchQuery.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(deleteSearchQuery.rejected, (state, action) => {
      console.error("Failed to delete the query" + action.error.message);
      throw new Error("Failed to delete the query");
    });
  },
});

export const { login, logout } = usersSlice.actions;
export const reducer = usersSlice.reducer;
