import { IDocumentType } from "./document_type";

export interface IProcessor {
  type: string;
  [key: string]: any;
}

export interface IUsecaseConnectorDataField {
  id: string; // Id that will be used to access the field inside the connector. Ex: "dispatch.customerCode", "dispatch.mainSellPrice",
  label: string; // Label that will be display in the configuration screen and in the jobfile screen also
  processor: IProcessor; // Processor used to compute the value
  required: boolean; // Required means that the field must not be empty when connector is triggered from jobfile validation
}

export interface IUsecaseConnectorSection {
  id: string; // Internal section name
  name: string; // Section name used for display
  table_id?: string; // Optional: table field technicalName
  data: IUsecaseConnectorDataField[]; // List of fields
}
export interface IUsecaseConnectorConfig {
  [key: string]: any;
}

export interface IUsecaseConnector {
  id: string; // The name of the .ts file that contains the connector custom function  e.g. "jdi"
  action: string; // Name of the function that will be run in the connector custom function e.g. "upload_ftp" or "create_mission"
  config: IUsecaseConnectorConfig; // Config object with key/value pairs e.g. FTP creds
}
export interface IUseCase {
  _id: string;
  name: string;
  exportFormatters: string[];
  connector?: IUsecaseConnector;
  sections: IUsecaseConnectorSection[]; // List of sections
  docTypes: IDocumentType[];
}

// Available processors
export const AVAILABLE_PROCESSORS = ["fixed_value", "doc_field", "format_date", "format_number", "substring", "referential_lookup"];
