import React, { FocusEvent } from "react";

import TextField from "./TextField";
import { DocumentFieldOrigin, DocumentFieldUpdateRequest } from "models/document";
import { IDocumentTypeField } from "models/document_type";
import { getColorForFieldOrigin } from "utils/labelling";
import { OcrWord } from "types/labelling";
import { useAppSelector } from "redux/hooks";

export type InputFieldProps = {
  id: string;
  type: string;
  value: any;
  field: IDocumentTypeField;
  origin: DocumentFieldOrigin | null;
  mapping?: OcrWord[] | null;
  isFocused: boolean;
  onChange: (fieldUpdate: DocumentFieldUpdateRequest) => void;
  onFocus: (e: FocusEvent<HTMLElement>) => void;
  autoFocus?: boolean;

  onEnterPressed?: () => void;
};

const InputField: React.FC<InputFieldProps> = ({ ...rest }) => {
  const { focusedTable } = useAppSelector((state) => state.labelling);

  const isActiveTableCell = focusedTable?.focusedCell?.cell.technicalName === rest.field.technicalName && rest.isFocused;

  return (
    <div
      className={`grow gap-3 content-center rounded-sm bg-white ${!isActiveTableCell ? "mx-2 my-1" : ""} border border-docloop_borderColor`}
      style={rest.isFocused && rest.origin ? { borderColor: `${getColorForFieldOrigin(rest.origin)}BB` } : {}}
    >
      <TextField {...rest} />
    </div>
  );
};
export default InputField;
