import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type NavigationItem = {
  text: string;
  icon?: string;
  path?: string;
  state?: object; // Pass data during navigation
};

export type NavigationState = {
  navItems: NavigationItem[];
};

export const initialState: NavigationState = {
  navItems: [],
};

// Core
const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setBreadcrumbsMenuItems(state, action: PayloadAction<NavigationItem[] | null>) {
      state.navItems = action.payload ?? [];
    },
  },
});

export const { setBreadcrumbsMenuItems } = navigationSlice.actions;
export const reducer = navigationSlice.reducer;
