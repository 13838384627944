import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Backdrop, Checkbox, IconButton, Modal, TablePagination, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlineContentPasteSearch, MdPlayCircleOutline } from "react-icons/md";
import { CiFolderOn } from "react-icons/ci";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi2";
import { VscNewFolder } from "react-icons/vsc";

import { useAppSelector } from "redux/hooks";
import { formatDate, paginate, toggleValueInArray } from "utils/helpers";
import { IDocument } from "models/document";
import { IUseCase } from "models/usecase";
import { DocumentSearchHit } from "models/catalog";
import DocumentsService from "services/documents.service";
import JobFilesService from "services/jobfiles.service";

import { DocumentStatusLabel } from "views/scenes/jobfile/documents/components/DocumentStatusLabel";
import Loading from "components/Loading";
import UserBadge from "components/UserBadge";
import { PageViewer } from "components/document/PageViewer";
import DropdownMenu from "components/DropdownMenu";

const NB_ITEMS_PER_PAGE = 10;

type DocumentsTableProps = {
  loading: boolean;
  documents: DocumentSearchHit[];
  reloadSearch: () => void;
};

export const DocumentsTable: React.FC<DocumentsTableProps> = ({ loading, documents, reloadSearch }) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [multipleSelectionEnabled, setMultipleSelectionEnabled] = useState<boolean>(false);
  const [expandSelectionEnable, setExpandSelectionEnable] = useState<boolean>(false);

  const navigate = useNavigate();

  // Un-select rows if loading state change
  useEffect(() => {
    setSelectedRows([]);
  }, [loading]);

  // Handle multiple key listeners to enable / disable multiple documents selection
  useEffect(() => {
    // Listen key down events
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.metaKey || event.ctrlKey) setMultipleSelectionEnabled(true);
      if (event.shiftKey) setExpandSelectionEnable(true);
    };
    document.addEventListener("keydown", keyDownHandler);

    const keyUpHandler = (event: KeyboardEvent) => {
      if (!event.metaKey && !event.ctrlKey) setMultipleSelectionEnabled(false);
      if (!event.shiftKey) setExpandSelectionEnable(false);
    };
    document.addEventListener("keyup", keyUpHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      document.removeEventListener("keyup", keyUpHandler);
    };
  }, [multipleSelectionEnabled, expandSelectionEnable]);

  //
  // UI Actions
  //
  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  // Handle table row selection
  const handleRowClick = (index: number) => {
    let updatedSelection = [...selectedRows];
    // If multiple selection is not enabled then we only get the current index
    if (!multipleSelectionEnabled && !expandSelectionEnable) {
      updatedSelection = updatedSelection.filter((v) => v === index);
      // If there was multiple rows selected and now only the current clicked row => keep current row selected
      if ((selectedRows.length > 1 && updatedSelection.length === 1) === false) {
        toggleValueInArray(updatedSelection, index);
      }
    } else {
      // Multiple selection
      let minIndex = Math.min(...updatedSelection);
      let maxIndex = Math.max(...updatedSelection);
      if (expandSelectionEnable && !(index === minIndex || index === maxIndex)) {
        // Shift + Click => Expand selection
        if (index < minIndex) minIndex = index;
        else if (index > minIndex) maxIndex = index;
        updatedSelection = Array.from({ length: maxIndex - minIndex + 1 }, (_, i) => minIndex + i);
      } else {
        // Ctrl + Click => toggle value
        // Shift + Click on boundaries => toggle value
        toggleValueInArray(updatedSelection, index);
      }
    }
    setSelectedRows(updatedSelection);
  };

  // Trigger a bulk deletion of documents
  const bulkDocumentsDeletion = async () => {
    if (selectedRows.length === 0) return;

    const documentsToDelete = documents.filter((_doc, index) => selectedRows.includes(index));

    if (window.confirm(t("home.list_item.actions.delete_docs_multiple_confirm"))) {
      // Simply loop over all selected documents and call the single deletion endpoint
      // Note: Should be improved with a proper bulk deletion endpoint but not needed for now
      const toastId = toast.loading(t("home.list_item.actions.docs_multiple_deletion_processing"));
      try {
        for (const document of documentsToDelete) {
          await DocumentsService.deleteDocument(document._id);
        }

        toast.dismiss(toastId);
        toast.success(t("home.list_item.actions.docs_multiple_deleted"));
      } catch (error) {
        toast.dismiss(toastId);
        toast.error(t("home.list_item.actions.docs_multiple_deletion_error"));
      } finally {
        reloadSearch();
      }
    }
  };

  // Create a new jobfile with selected documents
  const createJobfileWithSelectedDocuments = async () => {
    const selectedDocuments = documents.filter((_doc, index) => selectedRows.includes(index));
    const selectedDocumentsIds = selectedDocuments.map((docHit: DocumentSearchHit) => docHit._id);
    const jobfile = await JobFilesService.createJobfileFromDocuments(selectedDocumentsIds);
    if (jobfile) {
      navigate(`/jobfile/${jobfile._id}/documents`);
    }
  };

  const onDocumentClick = (doc: DocumentSearchHit) => {
    const jobfile = doc.jobfile;
    if (jobfile) {
      return navigate(`/jobfile/${jobfile._id}/documents/${doc._id}`);
    }
  };

  //
  // Render
  //
  return (
    <>
      {selectedRows.length > 0 && (
        <ActionsToolbar selectedCount={selectedRows.length} onClickDelete={bulkDocumentsDeletion} onClickJobfileCreation={createJobfileWithSelectedDocuments} onClose={() => setSelectedRows([])} />
      )}
      <TableContainer component={Paper} id="home_documents_table">
        <Table
          sx={{
            "& .MuiTableCell-sizeMedium": {
              padding: "10px 15px",
            },
          }}
        >
          <TableHead style={{ padding: "2px 4px !important" }}>
            <TableRow sx={{ userSelect: "none" }}>
              <TableCell component="th" width={30} />
              <TableCell component="th" />
              <TableCell component="th" width={90}>
                <b>{t("home.table.columns.creation_date")}</b>
              </TableCell>
              <TableCell component="th" width={200}>
                <b>{t("home.table.columns.status")}</b>
              </TableCell>
              <TableCell component="th" width={100}>
                <b>{t("home.table.columns.createdBy")}</b>
              </TableCell>
              <TableCell component="th" width={100}>
                <b>{t("home.table.columns.actions")}</b>
              </TableCell>
              <TableCell component="th" width={30} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={4}>
                  <Loading className="my-10" />
                </TableCell>
              </TableRow>
            )}
            {!loading && documents.length === 0 && (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell colSpan={4}>
                  <span className="my-10 block text-center font-semibold">{t("home.table.no_result")}</span>
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              documents.length > 0 &&
              (paginate(documents, currentPage, NB_ITEMS_PER_PAGE) as DocumentSearchHit[]).map((doc, index) => {
                const isSelected = selectedRows.includes(index);

                return (
                  <TableRow
                    key={`doc-${doc._id}`}
                    hover={true}
                    sx={{
                      userSelect: "none",
                      cursor: "pointer",
                      "&:last-of-type td, &:last-of-type th": { border: 0 },
                      "td:first-of-type": { padding: "0px 2px" },
                      "td:nth-of-type(2)": { paddingLeft: "2px" },
                      "td:last-of-type": { padding: 0, opacity: 0 },
                      "&.MuiTableRow-root:hover td:last-of-type": { opacity: 1 },
                      "&.MuiTableRow-root:hover": {
                        backgroundColor: isSelected ? "rgba(193,231,254,0.7)" : "rgba(140,140,140,0.08)",
                      },
                      backgroundColor: isSelected ? "rgba(193,231,254,0.5)" : "white",
                    }}
                    onClick={() => onDocumentClick(doc)}
                  >
                    <TableCell onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
                      <Checkbox size="small" title={t("home.list_item.actions.select_row")} onChange={() => handleRowClick(index)} checked={isSelected} style={{ opacity: isSelected ? 1 : 0.6 }} />
                    </TableCell>
                    <TableCell>
                      <span className="font-bold float-left">{doc.name}</span>
                      {doc.doc_type && (
                        <div className="float-left clear-both text-[11px] px-2 py-1 mt-1 bg-slate-100 rounded-md">
                          <IoDocumentTextOutline className="inline mr-2 -mt-0.5 text-sm" />
                          {t(`document_type.${doc.doc_type}`)}
                        </div>
                      )}
                      {doc.jobfile && (
                        <div className={`float-left text-[11px] px-2 py-1 mt-1 ml-1 bg-slate-100 rounded-md ${!doc.doc_type ? "clear-both" : ""}`}>
                          <CiFolderOn className="inline mr-2 -mt-0.5 text-sm" />
                          {doc.jobfile.name}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={formatDate(doc.createdAt, "DD/MM/YYYY - HH:mm")}>
                        <span className="text-xs">{formatDate(doc.createdAt, "D MMM.")}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <DocumentStatusLabel status={doc.status} />
                    </TableCell>
                    <TableCell>{doc.createdBy && <UserBadge email={doc.createdBy.email} firstName={doc.createdBy.firstname} lastName={doc.createdBy.lastname} />}</TableCell>
                    <TableCell onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
                      <DocumentActions doc={doc} />
                    </TableCell>
                    <TableCell onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
                      <Tooltip title={t("home.list_item.actions.preview_doc")}>
                        <button onClick={() => setPreviewDocumentId(doc._id)} className="cursor-pointer opacity-30 hover:opacity-100 p-2 mr-1">
                          <MdOutlineContentPasteSearch className="text-xl" />
                        </button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination component="div" count={documents.length} rowsPerPage={NB_ITEMS_PER_PAGE} rowsPerPageOptions={[]} page={currentPage} onPageChange={handlePageChange} />

      <DocumentPreviewModal documentId={previewDocumentId} onClose={() => setPreviewDocumentId(null)} />
    </>
  );
};

//
// Document preview modal component
//

type DocumentPreviewModalProps = {
  documentId: string | null;
  onClose: () => void;
};

const DocumentPreviewModal: React.FC<DocumentPreviewModalProps> = ({ documentId, onClose }) => {
  const [document, setDocument] = useState<IDocument | null>(null);

  useEffect(() => {
    if (!documentId) setDocument(null);

    loadDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  // Load document from API
  const loadDocument = async () => {
    if (!documentId) return;

    let doc = await DocumentsService.getDocument(documentId);
    if (doc) setDocument(doc);
  };

  // If there is no documentId selected, then we return nothing
  if (!documentId) return;

  return (
    <Modal open={true} onClose={onClose} slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { onClick: onClose } }}>
      <div className="flex h-full items-center justify-center">
        {document ? (
          <div className="h-full py-4 w-[800px]">
            <div className="h-full w-full bg-white rounded overflow-y-scroll">
              <PageViewer disableAnnotation={true} doc={document} />
            </div>
          </div>
        ) : (
          <div className="bg-white p-8 rounded-xl">
            <Loading />
          </div>
        )}
      </div>
    </Modal>
  );
};

//
// Actions toolbar component
//
type ActionsToolbarProps = {
  selectedCount: number;
  onClickDelete: () => void;
  onClickJobfileCreation: () => void;
  onClose: () => void;
};

const ActionsToolbar: React.FC<ActionsToolbarProps> = ({ selectedCount, onClickDelete, onClickJobfileCreation, onClose }) => {
  return (
    <div className="mb-4 w-full bg-gray-100 rounded-3xl px-2 py-0.5 shadow flex flex-row items-center">
      <Tooltip title={t("global.close")}>
        <div className="text-lg cursor-pointer inline mr-3 p-1 text-black hover:text-slate-600" onClick={onClose}>
          <AiOutlineCloseCircle />
        </div>
      </Tooltip>
      <span className="text-sm inline mr-4 opacity-70">{t("home.toolbar_actions.selected_items_count_ordinal", { count: selectedCount ?? 0 })}</span>
      <Tooltip title={t("home.list_item.actions.delete_docs_multiple")} onClick={onClickDelete}>
        <IconButton sx={{ fontSize: 16, color: "black" }}>
          <HiOutlineTrash />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("home.list_item.actions.create_jobfile_from_doc")} onClick={onClickJobfileCreation}>
        <IconButton sx={{ fontSize: 16, color: "black" }}>
          <VscNewFolder />
        </IconButton>
      </Tooltip>
    </div>
  );
};

//
// Document actions component
//
type DocumentActionsProps = {
  doc: DocumentSearchHit;
};

const DocumentActions: React.FC<DocumentActionsProps> = ({ doc }) => {
  const { useCases } = useAppSelector((state) => state.usecases);

  const navigate = useNavigate();

  const createJobfileWithUsecase = async (usecase: IUseCase) => {
    const jobfile = await JobFilesService.createJobfileFromDocuments([doc._id], usecase);
    if (jobfile) {
      navigate(`/jobfile/${jobfile._id}/documents`);
    }
  };

  // If document already have a jobfile associated, then display nothing
  if (doc.jobfile) return null;

  return (
    <DropdownMenu
      button={
        <Tooltip title={t("home.list_item.actions.export_doc")} placement="top" arrow={true}>
          <IconButton className="exportAction" sx={{ fontSize: 24, color: "#4ab04d" }}>
            <MdPlayCircleOutline />
          </IconButton>
        </Tooltip>
      }
      items={useCases.map((usecase) => ({ text: usecase.name, leftIcon: <MdPlayCircleOutline className="text-lg mr-2 opacity-60" />, onClick: () => createJobfileWithUsecase(usecase) }))}
    />
  );
};
