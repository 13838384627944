import React, { createRef, useEffect, useState } from "react";

import { MdDragHandle } from "react-icons/md";

const MIN_PANEL_WIDTH = 400;
const MAX_PANEL_WIDTH = 1000;
const MIN_PANEL_HEIGHT = 180;
const MAX_PANEL_HEIGHT = 500;

export enum SplitViewOrientation {
  Horizontal = 1,
  Vertical = 2,
}

//
// Resizable pane component
//
type ResizablePaneProps = {
  length: number;
  setLength: (length: number) => void;
  orientation: SplitViewOrientation;
  children: React.ReactNode;
};

const ResizablePane: React.FC<ResizablePaneProps> = ({ children, orientation, length, setLength }) => {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (ref.current) {
      if (!length) {
        setLength(orientation === SplitViewOrientation.Horizontal ? ref.current.clientWidth : ref.current.clientHeight);
        return;
      }

      if (orientation === SplitViewOrientation.Horizontal) ref.current.style.width = `${length}px`;
      else ref.current.style.height = `${length}px`;
    }
  }, [ref, orientation, length, setLength]);

  return (
    <div ref={ref} className="relative" style={orientation === SplitViewOrientation.Horizontal ? { height: "100%" } : { width: "100%" }}>
      {children}
    </div>
  );
};

//
// Core split view componenet
//
type SplitViewProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  orientation?: SplitViewOrientation;

  className?: string;
  defaultPanelLength?: number;
  onDraggingStateChange?: (dragging: boolean) => void;
  onPanelLengthChange?: (length: number) => void;
};

const SplitView: React.FC<SplitViewProps> = ({
  left,
  right,
  orientation = SplitViewOrientation.Horizontal,
  defaultPanelLength,
  className = null,
  onDraggingStateChange = null,
  onPanelLengthChange,
}) => {
  // If specified, use defaultPanelLength. Else use the minimum panel size depending of orientation
  const [panelLength, setPanelLength] = useState<number>(defaultPanelLength ? defaultPanelLength : orientation === SplitViewOrientation.Horizontal ? MIN_PANEL_WIDTH : MIN_PANEL_HEIGHT);
  const [maxLengthPanel, setMaxLengthPanel] = useState<number>(orientation === SplitViewOrientation.Horizontal ? MAX_PANEL_WIDTH : MAX_PANEL_HEIGHT);
  const [separatorPosition, setSeparatorPosition] = useState<number | undefined>(undefined); // Can be a X or Y position depending of orientation
  const [dragging, setDragging] = useState<boolean>(false);

  const splitPaneRef = createRef<HTMLDivElement>();

  // Init all listeners
  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  const updateDraggingState = (newState: boolean) => {
    if (newState === dragging) return;

    setDragging(newState);
    if (onDraggingStateChange) onDraggingStateChange(newState);
  };

  //
  // Mouse listeners
  //
  const onMove = (clientCursorPosition: number) => {
    if (dragging && panelLength && separatorPosition) {
      let newPanelLength = panelLength + separatorPosition - clientCursorPosition;
      setSeparatorPosition(clientCursorPosition);

      if (orientation === SplitViewOrientation.Horizontal) {
        if (splitPaneRef.current?.clientWidth) setMaxLengthPanel(splitPaneRef.current.clientWidth - MIN_PANEL_WIDTH);

        newPanelLength = Math.min(Math.max(newPanelLength, MIN_PANEL_WIDTH), maxLengthPanel);
      } else {
        if (splitPaneRef.current?.clientHeight) setMaxLengthPanel(splitPaneRef.current.clientHeight - MIN_PANEL_HEIGHT);

        newPanelLength = Math.min(Math.max(newPanelLength, MIN_PANEL_HEIGHT), maxLengthPanel);
      }

      setPanelLength(newPanelLength);
      if (onPanelLengthChange) onPanelLengthChange(newPanelLength);
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    // Check if the mouse event is event the splitPanel
    // If not, we stop there
    const panelRefPosition = splitPaneRef.current?.getBoundingClientRect();
    if (
      panelRefPosition &&
      (e.clientX < panelRefPosition.x || e.clientX > panelRefPosition.x + panelRefPosition.width || e.clientY < panelRefPosition.y || e.clientY > panelRefPosition.y + panelRefPosition.height)
    )
      return;

    // If we reach here, stop event propagation
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    onMove(orientation === SplitViewOrientation.Horizontal ? e.clientX : e.clientY);
  };

  const onTouchMove = (e: TouchEvent) => {
    // Check if the mouse event is event the splitPanel
    // If not, we stop there
    const panelRefPosition = splitPaneRef.current?.getBoundingClientRect();
    if (
      panelRefPosition &&
      (e.touches[0].clientX < panelRefPosition.x ||
        e.touches[0].clientX > panelRefPosition.x + panelRefPosition.width ||
        e.touches[0].clientY < panelRefPosition.y ||
        e.touches[0].clientY > panelRefPosition.y + panelRefPosition.height)
    )
      return;

    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    onMove(orientation === SplitViewOrientation.Horizontal ? e.touches[0].clientX : e.touches[0].clientY);
  };

  const onMouseUp = () => {
    updateDraggingState(false);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setSeparatorPosition(orientation === SplitViewOrientation.Horizontal ? e.clientX : e.clientY);
    updateDraggingState(true);
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setSeparatorPosition(orientation === SplitViewOrientation.Horizontal ? e.touches[0].clientX : e.touches[0].clientY);
    updateDraggingState(true);
  };

  // Rendering
  return (
    <div
      className={`splitView h-full flex align-items-start ${className ?? ""} ${orientation === SplitViewOrientation.Horizontal ? "flex-row overflow-x-hidden" : "flex-col overflow-y-hidden"}`}
      ref={splitPaneRef}
    >
      <div className={`leftPane flex-1 relative ${orientation === SplitViewOrientation.Horizontal ? "overflow-x-hidden h-full" : "overflow-y-hidden w-full"}`} style={{ zIndex: 100 }}>
        {left}
      </div>
      {right && (
        <>
          <div
            className={`align-self-strech flex align-items-center relative shadow-lg ${orientation === SplitViewOrientation.Horizontal ? "cursor-col-resize" : "cursor-row-resize"}`}
            style={{ zIndex: 200 }}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            onTouchEnd={onMouseUp}
          >
            <div className={`absolute divider ${orientation === SplitViewOrientation.Horizontal ? " h-full -left-0.5 w-0.5" : "w-full -top-0.5 h-0.5"}`}>
              <div
                className={`absolute flex items-center justify-center rounded-sm border border-slate-200 bg-white text-center shadow-md ${
                  orientation === SplitViewOrientation.Horizontal ? "h-6 w-3 top-1/2 left-[-5px] mt-[-5px]" : "h-3 w-6 left-1/2 top-[-5px] ml-[-5px]"
                }`}
              >
                <MdDragHandle className={`shrink-0 text-slate-400 ${orientation === SplitViewOrientation.Horizontal ? "rotate-90" : ""}`} />
              </div>
            </div>
          </div>
          <ResizablePane length={panelLength} setLength={setPanelLength} orientation={orientation}>
            {right}
          </ResizablePane>
        </>
      )}
    </div>
  );
};

export default SplitView;
