import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { SvgIconComponent } from '@mui/icons-material';

type TableActionButtonProps = {
  Icon: SvgIconComponent;
  onClick: () => void;
  tooltip: string;
  className?: string;
  color: "default" | "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
};

const TableActionButton: React.FC<TableActionButtonProps> = ({ 
    Icon, onClick, tooltip, className = '', color = 'default'
}: TableActionButtonProps) => {
  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} className={className} color={color}>
        <Icon />
      </IconButton>
    </Tooltip>
  );
};

export default TableActionButton;
