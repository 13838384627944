import api from "./api";
import { IConnectorExecutionResult, IJobFile, IJobFileDataEntry, JobfileDocument } from "models/jobfile";
import { IUseCase, IUsecaseConnectorConfig } from "models/usecase";

const JOBFILE_API_URL = "/jobfile";

class JobFilesService {
  // Retrieve a single jobfile
  async getJobfile(jobfileId: string): Promise<IJobFile> {
    return api.get(`${JOBFILE_API_URL}/${jobfileId}`).then((response) => {
      return response.data;
    });
  }

  // Retrieve documents related to a jobfile
  async getJobfileDocuments(jobfileId: string): Promise<JobfileDocument[]> {
    return api.get(`${JOBFILE_API_URL}/${jobfileId}/documents`).then((response) => {
      return response.data;
    });
  }

  // Refresh preview in usecase configuration screen with selected jobfile data
  async getJobfilePreview(useCaseId: string, jobfileId: string): Promise<IJobFile> {
    return api.post(`${JOBFILE_API_URL}/${jobfileId}/preview`, { useCaseId }).then((response) => {
      return response.data;
    });
  }

  // set the name of the jobfile
  async setName(jobFileId: string, name: string): Promise<IJobFile> {
    return api.post(`${JOBFILE_API_URL}/${jobFileId}`, { name: name }).then((response) => {
      return response.data;
    });
  }

  // Update document type for a single document
  async setUseCase(jobFileId: string, useCaseId: string): Promise<IJobFile> {
    return api.post(`${JOBFILE_API_URL}/${jobFileId}/usecase/${useCaseId}`, { usecase_id: useCaseId }).then((response) => {
      return response.data;
    });
  }

  async resetUsecase(jobFileId: string): Promise<IJobFile> {
    return api.delete(`${JOBFILE_API_URL}/${jobFileId}/usecase`).then((response) => {
      return response.data;
    });
  }

  // Update jobfile data change
  async updateData(jobfileId: string, data: { [key: string]: IJobFileDataEntry }) {
    return api.post(`${JOBFILE_API_URL}/${jobfileId}`, { data }).then((response) => {
      return response.data;
    });
  }

  // Validate a jobfile and trigger data computing serverside
  async validateJobfile(jobfileId: string): Promise<IJobFile> {
    return api.post(`${JOBFILE_API_URL}/${jobfileId}/validate`).then((response) => {
      return response.data;
    });
  }

  // Execute connector action
  async export(jobfileId: string, connector: IUsecaseConnectorConfig): Promise<IConnectorExecutionResult> {
    return api.post(`${JOBFILE_API_URL}/${jobfileId}/export`, { connector }).then((response) => {
      return response.data;
    });
  }

  async download(jobfileId: string, exportFormatter: string): Promise<IConnectorExecutionResult> {
    return api.post(`${JOBFILE_API_URL}/${jobfileId}/download`, { exportFormatter }).then((response) => {
      return response.data;
    });
  }

  // Create empty jobfile
  async createJobfile(): Promise<IJobFile> {
    return api.post(`${JOBFILE_API_URL}/create`).then((response) => {
      return response.data;
    });
  }

  // Create a jobfile from documents
  async createJobfileFromDocuments(doc_ids: string[], usecase?: IUseCase): Promise<IJobFile> {
    return api.post(`${JOBFILE_API_URL}/create`, { doc_ids: doc_ids, usecase_id: usecase ? usecase._id : null }).then((response) => {
      return response.data;
    });
  }

  // Delete jobfile
  async deleteJobfile(jobFileId: string): Promise<IJobFile> {
    return api.delete(`${JOBFILE_API_URL}/${jobFileId}`).then((response) => {
      return response.data;
    });
  }

  // Unlink document from jobfile
  async unlinkDocument(jobFileId: string, docId: string): Promise<IJobFile> {
    return api.delete(`${JOBFILE_API_URL}/${jobFileId}/unlink_doc/${docId}`).then((response) => {
      return response.data;
    });
  }
}

const jobFilesServices = new JobFilesService();
export default jobFilesServices;
