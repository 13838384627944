import React from "react";
import { t } from "i18next";
import { IoIosArrowDropright } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import JobFileService from "services/jobfiles.service";
import { DocumentStatus } from "models/document";
import { useAppSelector } from "redux/hooks";

const ValidateJobfileButton: React.FC = () => {
  const { currentJobfile, currentJobfileDocuments } = useAppSelector((state) => state.jobfile);
  const navigate = useNavigate();

  const areAllDocsValidated = currentJobfileDocuments?.length > 0 && currentJobfileDocuments.every((d) => d.status === DocumentStatus.None);

  const onClickValidate = async () => {
    // if usecase is not selected, prevent the action
    if (currentJobfile?.usecase == null) return toast.error(t("jobfile_documents_list.no_usecase_selected"));

    // if some docs are not validated, prevent the action
    if (!areAllDocsValidated) return toast.error(t("jobfile_documents_list.documents_not_validated"));

    // Check if jobfile has a data attribute
    // If jobfile has never been computed i.e. jobfile has no data, trigger validation
    try {
      if (!currentJobfile.data) await JobFileService.validateJobfile(currentJobfile._id);
    } catch (error) {
      console.error(error);
    } finally {
      navigate(`/jobfile/${currentJobfile._id}/validation`);
    }
  };

  return (
    <div
      className={`grow-0 flex-1 px-3 py-3 font-medium cursor-pointer text-sm text-center border-t border-1 border-slate-300 ${!areAllDocsValidated || currentJobfile?.usecase == null ? "bg-slate-200 hover:bg-slate-300" : "bg-emerald-200 hover:bg-emerald-300"
        }`}
      onClick={onClickValidate}
    >
      <IoIosArrowDropright className="inline mr-2 -mt-0.5 text-lg" />
      {t("jobfile_documents_list.validate_button")}
    </div>
  );
};

export default ValidateJobfileButton;
