import { isEqual, uniqWith } from "lodash";
import moment from "moment";

export const formatDate = (date: Date, format: string = "HH:mm - D MMM"): string => {
  return moment(date).format(format);
};

/**
 * Get unique array of objects. Remove all duplicated entries
 */
export const getUniqueArrayOfObjects = (arr: Array<any>): Array<any> => {
  return uniqWith(arr, (a, b) => {
    // we use _.isEqual to compare if a & b have exactly the same values for all the properties
    return isEqual(a, b);
  });
};

// Pagination function to get a sublist of an array based on pagination data
export const paginate = (arr: Array<any>, pageIndex: number, pageSize: number) => {
  return arr.slice(pageSize * pageIndex, pageIndex * pageSize + pageSize);
};

/**
 * Toggle value inside an array
 * If not exists, the value is added
 * If exists, the value is removed
 * @param {Array} array Array of values (of any kind)
 * @param {any} value The value that need to be added or removed from the array
 */
export const toggleValueInArray = (array: Array<any>, value: any) => {
  if (!array || !Array.isArray(array)) return;

  array.includes(value) ? array.splice(array.indexOf(value), 1) : array.push(value);
};
