export enum UserRoleEnum {
  CONTRIBUTOR = "contributor",
  OWNER = "owner",
};

export enum UserAccountStateEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING_INVITATION = "pending_invitation",
  DELETED = "deleted",
}

export interface ISavedQuery {
  name: string;
  search_text?: string;
  search_documents_types?: string[];
}

export interface ISignUpInvitation {
  token: string;
  expires: Date;
}

export interface INewUser {
  email: string;
  firstname?: string;
  lastname?: string;
  role: UserRoleEnum;
}

export interface IUser extends INewUser {
  _id: string;
  saved_queries: ISavedQuery[];
  signUpInvitation?: ISignUpInvitation;
  accountState: UserAccountStateEnum;
}
