import { IDocumentTypeField } from "models/document_type";
import api from "./api";
import { IUseCase, IUsecaseConnectorSection } from "models/usecase";
import { IJobFile } from "models/jobfile";

const USECASE_API_URL = "/usecase";

class UseCasesService {
  // Retrieve all existing documents types
  async getUseCases(): Promise<IUseCase[]> {
    return api.get(`${USECASE_API_URL}/`).then((response) => {
      return response.data;
    });
  }

  // Retrieve a single usecase
  async getUsecase(useCaseId: string): Promise<IUseCase> {
    return api.get(`${USECASE_API_URL}/${useCaseId}`).then((response) => {
      return response.data;
    });
  }

  // Update usecase configuration change
  async updateUsecaseConfiguration(useCaseId: string, sections: IUsecaseConnectorSection[]) {
    return api.post(`${USECASE_API_URL}/${useCaseId}/configuration`, { sections }).then((response) => {
      return response.data;
    });
  }

  // Retrieve all usables fields for a useCase
  async getUsableFields(useCaseId: string): Promise<IDocumentTypeField[]> {
    return api.get(`${USECASE_API_URL}/${useCaseId}/usable_fields`).then((response) => {
      return response.data;
    });
  }

  // Retrieve all jobfile samples ids for a useCase
  async getUseCaseLastJobfiles(useCaseId: string): Promise<IJobFile[]> {
    return api.get(`${USECASE_API_URL}/${useCaseId}/last_jobfiles`).then((response) => {
      return response.data;
    });
  }


}

const useCasesService = new UseCasesService();
export default useCasesService;
