import React from "react";
import { t } from "i18next";
import { ImTable2 } from "react-icons/im";
import { AiOutlineCloseCircle, AiOutlineInsertRowAbove, AiOutlineInsertRowBelow } from "react-icons/ai";
import { TbTablePlus, TbTableMinus, TbTrash } from "react-icons/tb";
import { ImSpinner2 } from "react-icons/im";
import { PiSelectionPlus } from "react-icons/pi";
import { BiListCheck } from "react-icons/bi";
import { Tooltip } from "@mui/material";
import toast from "react-hot-toast";
import { debounce } from "lodash";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  addTableRow,
  deleteDocumentTableRowsLocally,
  insertRowInTableLocally,
  setFocusedTable,
  setFocusedTableCell,
  setFocusedTableColumn,
  setTableAnnotationZone,
  setTableRowsSelection,
  updateDocumentFieldLocally,
  updateDocumentTableLocally,
} from "redux/labelling";
import { DocumentFieldOrigin, DocumentFieldRowUpdateRequest, DocumentFieldUpdateRequest, DocumentTableFieldAddRowRequest, IDocumentField, IDocumentTableRow } from "models/document";
import DocumentsService from "services/documents.service";
import { toggleValueInArray } from "utils/helpers";
import { DocumentTypeFieldType, IDocumentTypeField } from "models/document_type";
import { LABELLING_SAVE_DEBOUNCE_TIME, TABLE_COLUMN_DEFAULT_WIDTH, TABLE_COLUMN_MIN_WIDTH } from "config/constants";
import { getColorForFieldOrigin, getTableRows, getWordsSortedByLines, scrollToPageFromTableCell } from "utils/labelling";
import InputField from "./fields";
import { OcrWord } from "types/labelling";
import { ResizableTableColumn } from "components/ResizableTableColumn";

type TableLabellingPanelProps = {};

enum InsertRowPosition {
  Below = 1,
  Above = 2,
  End = 3,
}

const TableLabellingPanel: React.FC<TableLabellingPanelProps> = () => {
  const dispatch = useAppDispatch();
  const { currentDocument, focusedTable, pagesMetadata } = useAppSelector((state) => state.labelling);

  // Handling of rows deleted
  const handleRowsDeletionComplete = () => {
    // If there was a focused cell and this one was on these delete rows, we trigger the unfocus
    if (focusedTable?.focusedCell && focusedTable.rowsSelection.rows.includes(focusedTable.focusedCell.row)) dispatch(setFocusedTableCell(null));

    dispatch(setTableRowsSelection({ rows: [] }));
  };

  //
  // UI Actions
  //

  // Handle table row selection
  const handleRowClick = (index: number) => {
    if (!focusedTable) return;

    let updatedSelection = [...focusedTable.rowsSelection.rows];

    // If multiple selection is not enabled anymore, then we only get the current index
    if (focusedTable.rowsSelection.multiSelectionEnabled) {
      toggleValueInArray(updatedSelection, index);
    }
    // If range selection is enabled
    else if (focusedTable.rowsSelection.rangeSelectionEnabled) {
      updatedSelection.push(index);

      // Get min and max index
      const min = Math.min(...updatedSelection);
      const max = Math.max(...updatedSelection);

      // Select all elements between these two index
      updatedSelection = Array.from({ length: max - min + 1 }, (_, index) => index + min);
    }
    // Else we only get the current index
    else {
      updatedSelection = updatedSelection.filter((v) => v === index);
      // If there was multiple rows selected and now only the current clicked row => keep current row selected
      if ((focusedTable.rowsSelection.rows.length > 1 && updatedSelection.length === 1) === false) {
        toggleValueInArray(updatedSelection, index);
      }
    }

    dispatch(setTableRowsSelection({ ...focusedTable.rowsSelection, rows: updatedSelection }));
    dispatch(setFocusedTableCell(null));

    // If annotation zone is enabled, move row cursor
    if (focusedTable.annotationZone) {
      dispatch(setTableAnnotationZone({ ...focusedTable.annotationZone, activeRow: index }));
    }
  };

  // Handle table cell selection
  const handleCellClick = (event: React.MouseEvent, rowIndex: number, column: IDocumentTypeField) => {
    // Stop event propagation
    event.stopPropagation();
    event.preventDefault();

    // If cell is already selected, don't do anything
    if (focusedTable?.focusedCell?.row === rowIndex && focusedTable.focusedCell.cell.technicalName === column.technicalName) return;

    dispatch(setFocusedTableCell({ cell: column, row: rowIndex, edition: false }));

    if (currentDocument && focusedTable) scrollToPageFromTableCell(currentDocument, pagesMetadata, focusedTable, rowIndex, column);
  };

  // Handle table cell doubleClick => enter edition
  const handleCellDoubleClick = (event: React.MouseEvent, rowIndex: number, column: IDocumentTypeField) => {
    // Stop event propagation
    event.stopPropagation();
    event.preventDefault();

    // Enable edition for this field
    dispatch(setFocusedTableCell({ cell: column, row: rowIndex, edition: true }));

    if (currentDocument && focusedTable) scrollToPageFromTableCell(currentDocument, pagesMetadata, focusedTable, rowIndex, column);
  };

  // Handle table column click
  const handleColumnClick = (event: React.MouseEvent, column: IDocumentTypeField) => {
    // Stop event propagation
    event.stopPropagation();
    event.preventDefault();

    dispatch(setFocusedTableColumn({ column, selectedMappingWords: [] }));
  };

  //
  // Rendering helpers
  //

  const getColumnWidth = (column: IDocumentTypeField): number => {
    const width = localStorage.getItem(`table_column_width.${column.technicalName}`);
    if (width) return parseInt(width);
    else {
      switch (column.type) {
        case DocumentTypeFieldType.number:
          return TABLE_COLUMN_MIN_WIDTH;

        default:
          return TABLE_COLUMN_DEFAULT_WIDTH;
      }
    }
  };

  const onTableColumnSizeChange = (column: IDocumentTypeField, width: number) => {
    localStorage.setItem(`table_column_width.${column.technicalName}`, `${width}`);
  };

  //
  // Rendering
  //
  if (!currentDocument || !focusedTable) return;

  const tableRows = getTableRows(currentDocument, focusedTable);

  return (
    <div
      id={`table-${focusedTable.table.technicalName}`}
      className="flex flex-col grow-0 shrink-0 h-full bg-white w-full border-t border-t-slate-500 border-r border-docloop_borderColor relative z-50 overflow-hidden alwaysShowScrollbar"
    >
      <div className="flex items-center grow-0 bg-white border-b border-docloop_borderColor">
        <span className="font-semibold text-xs px-4 py-2 border-r border-docloop_borderColor">
          <ImTable2 className="inline mr-2 -mt-0.5" /> {t(`documentType.${focusedTable.table.technicalName}`, focusedTable.table.technicalName)} {`(${tableRows.length})`}
        </span>
        <div className="grow flex items-center">
          {/* Add row at the end */}
          <InsertRowButton position={InsertRowPosition.End} onInsertComplete={() => /*setSelectedRows([tableRows.length])*/ { }} />
          {focusedTable.displayCleaningAction && <CleaningTableButton />}
          {focusedTable.rowsSelection.rows.length > 0 && (
            <div className="flex items-center ml-8">
              <span className="text-[11px] px-2 py-2 border-r inline text-docloop_alternativeColor font-semibold">
                {t("labelling_panel.table.selected_rows_count_ordinal", { count: focusedTable.rowsSelection.rows.length ?? 0 })}
              </span>
              <DeleteRowsButton onDeletionComplete={handleRowsDeletionComplete} />
              {focusedTable.rowsSelection.rows.length === 1 && (
                <div className="flex items-center">
                  {/* Add row below: Select next row automatically */}
                  <InsertRowButton
                    selectedRow={focusedTable.rowsSelection.rows[0]}
                    position={InsertRowPosition.Below}
                    onInsertComplete={() => dispatch(setTableRowsSelection({ rows: [focusedTable.rowsSelection.rows[0] + 1] }))}
                  />
                  {/* Add row above: Nothing to do in that case because if we insert a row before, we are already on the good row after adding */}
                  <InsertRowButton selectedRow={focusedTable.rowsSelection.rows[0]} position={InsertRowPosition.Above} onInsertComplete={() => { }} />
                </div>
              )}
            </div>
          )}

          {focusedTable.isUpdatingRows && <ImSpinner2 className="inline ml-4 animate-spin text-lg" />}
        </div>
        <div className="flex grow-0 px-2">
          <ValidateColumnMapping />
          <Tooltip title={t("labelling_panel.table.close_edition")}>
            <button className="float-right text-xl opacity-70 hover:opacity-100" onClick={() => dispatch(setFocusedTable(null))} tabIndex={-1}>
              <AiOutlineCloseCircle />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="grow overflow-auto">
        <table className="w-full table-fixed cursor-pointer">
          <thead className="bg-slate-100 sticky top-0 z-10">
            <tr>
              <th scope="col" className="sticky z-10 left-0 w-10 text-[11px] leading-tight font-medium text-gray-900 px-2 text-left break-words border-r">
                <div className="h-[1px] absolute left-0 right-0 -bottom-[0.5px] bg-slate-200" />
              </th>
              {focusedTable.table.columns?.map((column) => {
                const isColumnMappingEnabled = focusedTable.focusedColumn?.column.technicalName === column.technicalName;

                return (
                  <ResizableTableColumn key={`table-${focusedTable.table.technicalName}.column_${column.technicalName}`} onSizeChange={(width) => onTableColumnSizeChange(column, width)}>
                    {({ ref }) => (
                      <th
                        scope="col"
                        className={`text-[11px] leading-tight font-medium text-gray-900 pl-2 pr-5 py-2 text-left break-words relative group ${isColumnMappingEnabled ? "bg-orange-100" : ""}`}
                        style={{ width: getColumnWidth(column) }}
                      >
                        {t(`documentType.${column.technicalName}`, column.technicalName)}
                        <Tooltip title={t("labelling_panel.table.enable_column_mapping")}>
                          <button
                            className="absolute top-1/2 right-0 px-1 py-1 -mt-3 text-lg hidden group-hover:block hover:text-docloop_alternativeColor"
                            onClick={(event: React.MouseEvent) => handleColumnClick(event, column)}
                          >
                            <PiSelectionPlus />
                          </button>
                        </Tooltip>
                        <div className="h-[1px] absolute left-0 right-0 -bottom-[0.5px] bg-slate-200 z-0" />
                        <div className="w-[5px] absolute top-0 bottom-0 -right-[2.5px] cursor-col-resize z-10" ref={ref}>
                          <div className="w-[1px] ml-[2px] h-full bg-slate-200"></div>
                        </div>
                      </th>
                    )}
                  </ResizableTableColumn>
                );
              })}
            </tr>
          </thead>
          <tbody className="overflow-y-auto z-0">
            {tableRows.map((tableRow, rowIndex) => {
              const isRowSelected = focusedTable.rowsSelection.rows.includes(rowIndex);

              return (
                <tr
                  key={`table-${focusedTable.table.technicalName}.row_${rowIndex}`}
                  className={`border-b transition duration-300 ease-in-out ${isRowSelected ? `bg-docloop_table_rowSelectionColor hover:bg-docloop_table_rowSelectionColor_hover` : "bg-white hover:bg-gray-100"
                    }`}
                  onClick={() => handleRowClick(rowIndex)}
                >
                  <td
                    className={`sticky z-0 left-0  ${isRowSelected || focusedTable.focusedCell?.row === rowIndex ? `bg-docloop_table_rowSelectionColor_hover` : "bg-slate-100"
                      } text-[10px] text-center font-semibold px-0.5 py-1 select-none`}
                  >
                    {rowIndex + 1}
                    <div className="w-[1px] absolute top-0 bottom-0 -right-[0.5px] bg-slate-200" />
                  </td>
                  {focusedTable.table.columns?.map((column) => {
                    const cellId = `table-${focusedTable.table.technicalName}.row_${rowIndex}.cell_${column.technicalName}`;
                    const rowCell = tableRow[column.technicalName];
                    const isCellFocused = focusedTable.focusedCell?.row === rowIndex && focusedTable.focusedCell.cell.technicalName === column.technicalName ? true : false;
                    const isCellEdition = focusedTable.focusedCell?.edition ?? false;
                    const isColumnMappingEnabled = focusedTable?.focusedColumn?.column.technicalName === column.technicalName;

                    return (
                      <td
                        id={cellId}
                        key={cellId}
                        className={`text-[10px] border-r px-1 py-0.5 whitespace-pre-line break-words ${isColumnMappingEnabled ? "bg-orange-50" : ""} ${isCellFocused ? `bg-docloop_table_cellSelectionColor` : ""
                          }`}
                        style={
                          rowCell && rowCell.origin !== DocumentFieldOrigin.None
                            ? { borderColor: `${getColorForFieldOrigin(rowCell.origin)}80`, borderRightWidth: 4, scrollMarginTop: 40 }
                            : { scrollMarginTop: 40 }
                        }
                        onClick={(event: React.MouseEvent) => handleCellClick(event, rowIndex, column)}
                        onDoubleClick={(event: React.MouseEvent) => handleCellDoubleClick(event, rowIndex, column)}
                      >
                        <TableInputField id={cellId} column={column} cell={rowCell} isEditing={isCellFocused && isCellEdition} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Local component to update a cell
type TableInputFieldProps = {
  id: string;
  column: IDocumentTypeField;
  cell: IDocumentField;
  isEditing: boolean;
};

const TableInputField: React.FC<TableInputFieldProps> = ({ id, column, cell, isEditing }) => {
  const dispatch = useAppDispatch();
  const { currentDocument, focusedTable } = useAppSelector((state) => state.labelling);

  // Create a debounced function to saveRow
  const debouncedSaveFieldInRow = React.useRef(
    debounce(async (field_id: string, row_idx: number, fieldUpdate: DocumentFieldUpdateRequest) => {
      if (!currentDocument) return;
      try {
        await DocumentsService.updateDocumentFieldInRow(currentDocument._id, field_id, row_idx, fieldUpdate);
        toast.success(t("labelling_panel.toaster_successful_table_mapping"));
      } catch (error) {
        toast.error(t("labelling_panel.toaster_unsuccessful_table_mapping"));
      }
    }, LABELLING_SAVE_DEBOUNCE_TIME)
  ).current;

  // Handle cell value change
  // Note: we only update redux store here. The backend save will be done thanks to a dedicated useEffect that detect when we leave a focused cell
  const handleCellUpdate = async (fieldUpdate: DocumentFieldUpdateRequest) => {
    if (!currentDocument || !focusedTable || !focusedTable.table.columns || !focusedTable.focusedCell) return;

    // Proceed the cell cleaning
    const rows: IDocumentTableRow[] = getTableRows(currentDocument, focusedTable);

    // Check if the row exist
    if (!rows[focusedTable.focusedCell.row]) {
      console.warn("[TableLabellingPanel] Error - Row index for field does not exist in table");
      return;
    }

    // Check if the field exist and have a value
    const currentRow: IDocumentTableRow = { ...rows[focusedTable.focusedCell.row] }; // Spread copy in order to make it editable

    // Reset the cell
    currentRow[focusedTable.focusedCell.cell.technicalName] = {
      field_id: focusedTable.focusedCell.cell.technicalName,
      field_type: focusedTable.focusedCell.cell.type,
      value: fieldUpdate.value,
      origin: fieldUpdate.origin,
      mapping: fieldUpdate.mapping,
    };

    // Update redux store
    const tableRowUpdate: DocumentFieldRowUpdateRequest = {
      table: focusedTable.table.technicalName,
      row: focusedTable.focusedCell.row,
      value: currentRow,
    };
    dispatch(updateDocumentTableLocally(tableRowUpdate));

    // Debounce call to server
    debouncedSaveFieldInRow(focusedTable.table.technicalName, focusedTable.focusedCell.row, fieldUpdate);
  };

  // Hide suspicious values from MAPPING or PREDICTION without bbox
  let cellValue = cell?.value
  if (cell && [DocumentFieldOrigin.Mapping, DocumentFieldOrigin.Prediction].includes(cell.origin) && cell.mapping && cell.mapping.length === 0) cellValue = ""

  return (
    <>
      {isEditing ? (
        <InputField
          id={`${id}.input`}
          field={column}
          type={column.type}
          isFocused={true}
          value={cellValue}
          origin={cell?.origin ?? DocumentFieldOrigin.None}
          mapping={cell?.mapping}
          onChange={handleCellUpdate}
          onFocus={() => { }}
          autoFocus
          onEnterPressed={() => { }}
        />
      ) : (
        cellValue ?? ""
      )}
    </>
  );
};

// Local component for "Delete rows" feature
type DeleteRowsButtonProps = {
  onDeletionComplete: () => void;
};

const DeleteRowsButton: React.FC<DeleteRowsButtonProps> = ({ onDeletionComplete }) => {
  const dispatch = useAppDispatch();
  const { currentDocument, focusedTable } = useAppSelector((state) => state.labelling);

  // Handle click on button
  const onClick = async () => {
    // If it's already updating rows, we prevent the click action
    if (!focusedTable || !currentDocument || focusedTable.isUpdatingRows) return;

    // Check if the focusedTable field already exists in document
    const documentField = currentDocument.extraction?.data[focusedTable.table.technicalName];
    // If not, return empty array
    if (!documentField) return [];

    // Exexcute bulk deletion locally and remotely
    try {
      await DocumentsService.deleteTableRows(currentDocument._id, focusedTable.table.technicalName, focusedTable.rowsSelection.rows);
      dispatch(deleteDocumentTableRowsLocally({ table: focusedTable.table.technicalName, rows: focusedTable.rowsSelection.rows }));
      toast.success(t("labelling_panel.table.row_deleted.success"));
    } catch (err) {
      toast.error(t("labelling_panel.table.row_deleted.error"));
    } finally {
      onDeletionComplete();
    }
  };

  // Render
  if (!focusedTable) return null;

  return (
    <Tooltip title={t("labelling_panel.table.delete_rows_ordinal", { count: focusedTable.rowsSelection.rows.length })}>
      <button className="text-lg opacity-70 hover:opacity-100 px-2 py-2 border-r" onClick={onClick} tabIndex={-1}>
        <TbTableMinus />
      </button>
    </Tooltip>
  );
};

// Local component for "Insert rows" feature
type InsertRowButtonProps = {
  selectedRow?: number;
  position: InsertRowPosition;
  onInsertComplete: () => void;
};

const InsertRowButton: React.FC<InsertRowButtonProps> = ({ selectedRow, position, onInsertComplete }) => {
  const dispatch = useAppDispatch();
  const { currentDocument, focusedTable } = useAppSelector((state) => state.labelling);

  // Get label depending of position
  const getText = (): string => {
    switch (position) {
      case InsertRowPosition.Above:
        return t(`labelling_panel.table.insert_row_above`);
      case InsertRowPosition.Below:
        return t(`labelling_panel.table.insert_row_below`);
      case InsertRowPosition.End:
        return t(`labelling_panel.table.insert_row_end`);
      default:
        return "";
    }
  };

  const getIcon = (): React.ReactNode => {
    switch (position) {
      case InsertRowPosition.Above:
        return <AiOutlineInsertRowAbove />;
      case InsertRowPosition.Below:
        return <AiOutlineInsertRowBelow />;
      case InsertRowPosition.End:
        return <TbTablePlus />;
      default:
        return "";
    }
  };

  // Handle click on button
  const onClick = async () => {
    // If it's already updating rows, we prevent the click action
    if (!focusedTable || !currentDocument || focusedTable.isUpdatingRows || !focusedTable.table.columns) return;

    const documentField = currentDocument.extraction?.data[focusedTable.table.technicalName];

    let newRowIndex: number | null;
    switch (position) {
      case InsertRowPosition.Above:
        newRowIndex = selectedRow !== undefined ? selectedRow : 0; // When we want to insert "above current row", it means inserting a new row at the same index
        break;
      case InsertRowPosition.Below:
        newRowIndex = selectedRow !== undefined ? selectedRow + 1 : 0;
        break;
      case InsertRowPosition.End:
        newRowIndex = (documentField?.value as Array<any>)?.length ?? 0;
        break;
      default:
        return;
    }

    // Call API to add the row
    const tableFieldNewRow: DocumentTableFieldAddRowRequest = {
      field_id: focusedTable.table.technicalName,
      table: focusedTable.table.technicalName,
      row: newRowIndex,
      columns: focusedTable.table.columns,
    };


    try {
      await dispatch(addTableRow({ docId: currentDocument._id, newRow: tableFieldNewRow }));

      // Update store locally
      const newRow: IDocumentTableRow = {};
      focusedTable.table.columns.forEach((col: IDocumentTypeField) => {
        newRow[col.technicalName] = {
          field_id: col.technicalName,
          field_type: col.type,
          value: "",
          origin: DocumentFieldOrigin.None,
          mapping: [],
        };
      });
      dispatch(
        insertRowInTableLocally({
          table: focusedTable.table.technicalName,
          row: newRowIndex,
          value: newRow,
        })
      );
      toast.success(t("labelling_panel.table.row_added"));
    } catch (err) {
      toast.error(t("labelling_panel.table.row_add_error"));
    } finally {
      // Trigger callback
      onInsertComplete();
    }
  };

  // Render
  return (
    <Tooltip title={getText()}>
      <button className="text-lg opacity-70 hover:opacity-100 px-2 py-2 border-r" onClick={onClick} tabIndex={-1}>
        {getIcon()}
      </button>
    </Tooltip>
  );
};

const CleaningTableButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentDocument, focusedTable } = useAppSelector((state) => state.labelling);

  // Handle click on button
  const onClick = async () => {
    if (!focusedTable || !currentDocument) return;

    if (!window.confirm(t("labelling_panel.table.empty_all_table_confirm_text"))) return;

    // Save locally
    const fieldUpdate: DocumentFieldUpdateRequest = {
      field_id: focusedTable.table.technicalName,
      field_type: focusedTable.table.type,
      value: [],
      origin: DocumentFieldOrigin.None,
      mapping: [],
    };
    dispatch(updateDocumentFieldLocally(fieldUpdate));

    // Save remotely
    await DocumentsService.updateDocumentField(currentDocument._id, fieldUpdate);
  };

  // Render
  return (
    <Tooltip title={t(`labelling_panel.table.empty_all_table`)}>
      <button className="text-lg opacity-70 hover:opacity-100 px-2 py-2 border-r" onClick={onClick} tabIndex={-1}>
        <TbTrash />
      </button>
    </Tooltip>
  );
};

// Local component to validate column mapping
const ValidateColumnMapping = () => {
  const { focusedTable, pagesMetadata } = useAppSelector((state) => state.labelling);

  // Helper function to get number of unique rows select
  const getLinesSelectedCount = (): number => {
    if (!focusedTable?.focusedColumn) return 0;

    // Get unique words selected
    const words = focusedTable.focusedColumn.selectedMappingWords.reduce((acc: OcrWord[], items: OcrWord[]) => {
      acc.push(...items);
      return acc;
    }, []);
    const uniqueWords = Array.from(new Set(words));

    // Extract all lines
    const lines = getWordsSortedByLines(uniqueWords, pagesMetadata, false);

    return lines.length;
  };

  // Handle click on validate mapping action
  const handleClick = () => {
    // Simulate the use of Enter key. It will trigger an handler in LabellingListeners.tsx
    document.dispatchEvent(new KeyboardEvent("keydown", { key: "Enter" }));
  };

  // Rendering
  if (!focusedTable?.focusedColumn) return;
  const linesSelectedCount = getLinesSelectedCount();

  return (
    <Tooltip
      title={t("labelling_panel.table.validate_column_mapping", { column: t(`documentType.${focusedTable.focusedColumn.column.technicalName}`, `${focusedTable.focusedColumn.column.technicalName}`) })}
    >
      <div className="mr-2 px-2 py-1 border-r cursor-pointer text-green-600 hover:opacity-70" onClick={handleClick}>
        <span className="text-xs font-semibold">{t("labelling_panel.table.selected_rows_count_ordinal", { count: linesSelectedCount })}</span>
        <BiListCheck className="inline ml-2 text-2xl" />
      </div>
    </Tooltip>
  );
};

export default TableLabellingPanel;
