import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setBreadcrumbsMenuItems } from "redux/navigation";

import { IJobFile } from "models/jobfile";
import { IDocument } from "models/document";
import DocumentsService from "services/documents.service";
import JobFilesService from "services/jobfiles.service";
import CatalogService from "services/catalog.service";
import { SearchBar } from "./components/SearchBar";
import { SavedQueries } from "./components/SavedQueries";
import { DocumentsTable } from "./components/DocumentsTable";
import { JobfilesTable } from "./components/JobfilesTable";
import { SearchTypeSelector, SearchType } from "./components/SearchTypeSelector";
import { SearchDocTypesSelector } from "./components/SearchDocTypesSelector";
import { NewMenu } from "./components/NewMenu";
import { CatalogSearchResponse, DocumentSearchHit, JobFileSearchHit } from "models/catalog";

export default function Home() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { searchText, searchDocumentsTypes } = useAppSelector((state) => state.search);

  const [documents, setDocuments] = useState<DocumentSearchHit[]>([]);
  const [jobfiles, setJobfiles] = useState<JobFileSearchHit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchType, setSearchType] = useState<SearchType>(SearchType.Documents);

  // Reset navigation breadcrumbs on page loading
  useEffect(() => {
    dispatch(setBreadcrumbsMenuItems(null));
  }, [dispatch]);

  // On location change, check state in parameter
  useEffect(() => {
    if (location.state?.search_type === SearchType.Jobfiles) setSearchType(SearchType.Jobfiles);
    else if (location.state?.search_type === SearchType.Documents) setSearchType(SearchType.Documents);
  }, [location]);

  // Trigger refresh documents / folders on search criterias changes
  useEffect(() => {
    searchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchDocumentsTypes]);

  //
  // APIs Calls
  //
  const searchDocuments = async () => {
    setIsLoading(true);
    setDocuments([]);
    setJobfiles([]);

    const res: CatalogSearchResponse = await CatalogService.search(searchText, searchDocumentsTypes);
    setDocuments(res.hits.documents.hits);
    setJobfiles(res.hits.jobfiles.hits);

    setIsLoading(false);
  };

  //
  // Render
  //
  return (
    <div className="h-full px-2">
      <Helmet>
        <title>Docloop</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={3} md={3} xl={2}>
          <SavedQueries />
        </Grid>
        <Grid item xs={9} md={8} xl={8}>
          <div className="flex flex-row">
            <SearchBar />
            <NewMenu
              reloadSearch={(searchType: SearchType) => {
                setSearchType(searchType);
                searchDocuments();
              }}
            />
          </div>
          <div className="flex flex-row mb-3 items-center">
            <SearchTypeSelector type={searchType} onChange={(type) => setSearchType(type)} />
            <SearchDocTypesSelector />
          </div>

          {searchType === SearchType.Documents && <DocumentsTable documents={documents} loading={isLoading} reloadSearch={searchDocuments} />}
          {searchType === SearchType.Jobfiles && <JobfilesTable jobfiles={jobfiles} loading={isLoading} reloadSearch={searchDocuments} />}
        </Grid>
      </Grid>
    </div>
  );
}
