import React from "react";
import { FormControl, Stack, TextField } from "@mui/material";
import { IProcessor } from "models/usecase";
import DocFieldSelector from "./DocFieldSelector";
import { DocumentTypeFieldType, IDocumentTypeField } from "models/document_type";
import { t } from "i18next";

type FormatDateInputProps = {
  processor: IProcessor;
  usableDocumentsFields: IDocumentTypeField[];
  onChange: (proc: any) => void;
};

const FormatDateInput: React.FC<FormatDateInputProps> = ({ processor, usableDocumentsFields, onChange }) => {
  return (
    <div className="w-full flex flex-col">
      <Stack spacing={1}>
        <DocFieldSelector
          processor={processor}
          onChange={(settings: Object) => onChange({ ...settings, format: processor.format })}
          usableDocumentsFields={usableDocumentsFields.filter((f) => f.type === DocumentTypeFieldType.date)}
        />
        <FormControl fullWidth size="small">
          <TextField
            label={t('processor.format_date.format_field')}
            placeholder={t('processor.format_date.format_field_placeholder')}
            value={processor?.format ?? ""}
            inputProps={{ style: { padding: "4.5px 6px", fontSize: 12, boxShadow: "none" } }}
            InputLabelProps={{shrink: true, style: {fontSize: 14}}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ format: event.target.value, field: processor.field });
            }}
            type="text"
          />
        </FormControl>
      </Stack>
    </div>
  );
};

export default FormatDateInput;
