import api from "./api";
import { IReferential, IReferentialEntry } from "models/referential";

const REFERENTIALS_API_URL = "/referentials";

class ReferentialsService {
  // Retrieve all existing referentials
  async getReferentials(): Promise<IReferential[]> {
    return api
      .get(`${REFERENTIALS_API_URL}/`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return [];
      });
  }

  // Search entries into a referential
  async searchReferentialEntries(referential_id: string, input_field: string, output_field: string, search_text: string): Promise<IReferentialEntry[]> {
    return api
      .post(`${REFERENTIALS_API_URL}/${referential_id}/search`, { input_field, output_field, search_text })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return [];
      });
  }
}

const referentialsService = new ReferentialsService();
export default referentialsService;
