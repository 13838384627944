import React, { useRef, useEffect, useCallback, ReactNode } from "react";

type InfiniteScrollListProps = {
  onEndReached?: () => void;
  className?: string;
  children: ReactNode
};

const InfiniteScrollList: React.FC<InfiniteScrollListProps> = ({ onEndReached, className = "", children, ...rest }) => {
  const node = useRef<HTMLDivElement>(null);

  // Handle scroll detection and trigger the callback when the bottom is reached
  const handleScroll = useCallback(() => {
    if (node.current) {
      const { scrollTop, scrollHeight, clientHeight } = node.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (onEndReached) onEndReached();
      }
    }
  }, [node, onEndReached]);

  // Add listener to node element
  useEffect(() => {
    if (node.current) {
      const currentNode = node.current;
      currentNode.addEventListener("scroll", handleScroll);
      // FIXME test this callback
      return () => currentNode.removeEventListener("scroll", handleScroll);
    }
  }, [node, handleScroll]);

  return (
    <div className={className} {...rest} ref={node}>
      {children}
    </div>
  );
};

export default InfiniteScrollList;