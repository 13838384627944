import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { t } from 'i18next';

interface ConfirmationModalProps {
    open: boolean; 
    handleClose: () => void;
    handleConfirm: (object: any) => void;
    title: string;
    children: any;
  };

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, handleClose, handleConfirm, title, children }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('global.cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    {t('global.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;