import React from "react";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";

import { IUsecaseConnectorDataField } from "models/usecase";
import { IReferentialEntry } from "models/referential";
import ReferentialsService from "services/referentials.service";

import { SEARCHING_DEBOUNCE_TIME } from "config/constants";

type ReferentialLookupFieldProps = {
  field: IUsecaseConnectorDataField;
  value: any;
  onChange: (value: string) => void;

  hideBorder?: boolean;

  onMenuOpen?: () => void;
  onMenuClose?: () => void;
};


interface Option {
  value: {} | null;
  label: string;
}

const ReferentialLookupField: React.FC<ReferentialLookupFieldProps> = ({ field, value, onChange, onMenuOpen, onMenuClose, hideBorder = false }) => {
  const formatEntryLabel = (entry: IReferentialEntry) => {
    return Object.values(entry).join(" - ");
  };

  // Declare onChange function with a debouncing feature
  // Debounce is used to prevent too much requets to backend
  // Explanation here: https://www.developerway.com/posts/debouncing-in-react
  const debouncedSearch = React.useRef(

    debounce(async (inputValue: string, callback: (options: Option[]) => void) => {
      const results = await ReferentialsService.searchReferentialEntries(field.processor.referential_id, field.processor.input_field, field.processor.output_field, inputValue);

      const options = results.map((r) => ({ value: r[field.processor.output_field] ?? null, label: formatEntryLabel(r) }));
      callback(sortOptions(options));
    }, SEARCHING_DEBOUNCE_TIME)
  ).current;

  // Trigger search results async
  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    debouncedSearch(inputValue, callback);
  };

  const sortOptions = (options: Option[]) => {
    return options.sort((a: Option, b: Option) => {
      if (a.label === null && b.label === null) return 0;
      if (a.label === null) return 1;
      if (b.label === null) return -1;

      return a.label > b.label ? 1 : -1;
    });
  }

  // Rendering
  return (
    <div className="flex relative">
      <AsyncSelect
        isClearable={true}
        className="grow bg-transparent"
        loadOptions={loadOptions}
        defaultOptions
        value={value ? { value, label: value } : null}
        formatOptionLabel={(option, { context }) => {
          return context === "menu" ? option.label : option.value;
        }}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuPosition="fixed"
        // menuIsOpen={value === "ES" ? true : false}
        styles={{
          valueContainer: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          control: (base) => ({
            ...base,
            background: "white",
            fontSize: 12,
            minHeight: "20px",
            cursor: "pointer",
            borderWidth: hideBorder ? 0 : 1
          }),
          indicatorsContainer: (base) => ({
            ...base,
            paddingRight: hideBorder ? 0 : 10,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            paddingTop: 4,
            paddingBottom: 4,
          }),
          clearIndicator: (base) => ({
            ...base,
            paddingTop: 4,
            paddingBottom: 4,
          }),
          option: (styles, state) => ({
            ...styles,
            cursor: "pointer",
            fontSize: 12,
          }),
          input: (styles) => ({
            ...styles,
            boxShadow: "none !important",
          }),
          menu: (styles) => ({
            ...styles,
            background: "white",
            zIndex: 100000,
            marginTop: 1,
            marginBottom: 1
          }),
          menuList: (styles) => ({
            ...styles,
            maxHeight: 180,
            position: "relative",
            zIndex: 100000
          }),
          menuPortal: (styles) => ({
            ...styles,
            zIndex: 50
          })
        }}
        onChange={(selectedOption) => onChange(selectedOption?.value ?? null)}
      />
    </div>
  );
};

export default ReferentialLookupField;
