import { t } from "i18next";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setBreadcrumbsMenuItems } from "redux/navigation";

type AdminMenuItem = {
  text: string;
  path?: string;
  isClickable: boolean;
  items?: AdminMenuItem[];
};

export default function AdminLayout() {
  const dispatch = useAppDispatch();
  const { useCases } = useAppSelector((state) => state.usecases);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const menuItems: AdminMenuItem[] = [
    {
      text: t("admin.users_management.title"),
      path: "/admin/users",
      isClickable: true,
    },
    {
      text: t("admin.usecases.title"),
      path: "/admin/usecase",
      isClickable: false,
      items: useCases.map((u) => ({
        text: u.name,
        path: `/admin/usecase/${u._id}/configuration`,
        isClickable: true,
      })),
    },
    {
      text: t("admin.referentials.title"),
      path: "/admin/referentials",
      isClickable: true,
    },
  ];

  // Hide breadcrumb menu in admin module
  useEffect(() => {
    dispatch(setBreadcrumbsMenuItems(null));
  }, [dispatch]);

  return (
    <div className="flex flex-row h-full flex-1 overflow-hidden">
      <div className={`grow-0 flex flex-col h-full w-80 flex-none relative z-1 px-4 pl-6 pt-10 text-[#333333]`}>
        <h2 className="font-extrabold text-lg uppercase tracking-wide mb-3">{t("user_menu.admin")}</h2>
        <ul>
          {menuItems.map((menuItem) => {
            const isSelected = menuItem.path ? pathname?.indexOf(menuItem.path) === 0 : false;
            return (
              <li key={menuItem.path} className={`select-none clear-both`} onClick={() => (menuItem.path && menuItem.isClickable ? navigate(menuItem.path) : null)}>
                <span
                  className={`float-left block my-0.5 pl-8 py-2 ${menuItem.isClickable ? "hover:underline cursor-pointer" : ""} border-l-4 ${
                    isSelected ? "border-l-docloop_alternativeColor text-docloop_alternativeColor font-semibold" : "border-l-transparent font-medium"
                  }`}
                >
                  {menuItem.text}
                </span>
                {menuItem.items && menuItem.items.length > 0 && (
                  <ul className="ml-7 mb-3 float-left clear-both">
                    {menuItem.items.map((subItem) => {
                      const isSubItemSelected = pathname === subItem.path;
                      return (
                        <li
                          key={subItem.path}
                          className={`select-none my-0.5 pl-2 text-xs py-1 ${subItem.isClickable ? "hover:underline cursor-pointer" : ""} ${
                            isSubItemSelected ? " text-docloop_alternativeColor font-semibold" : ""
                          }`}
                          onClick={() => (subItem.path ? navigate(subItem.path) : null)}
                        >
                          {subItem.text}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="min-h-full flex-1 grow relative z-0 overflow-auto bg-white shadow-lg rounded-tl-md mt-3 pb-3">
        <Outlet />
      </div>
    </div>
  );
}
