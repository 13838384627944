import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import authService from "./auth.service";
// we import only the types here, otherwise this will result in circular dependency
import { Store } from "redux/store";
// we can import the slice at is not dependant on store instance
import { login, logout } from "redux/users";
import _ from "lodash";
//import { apiUrlBase } from "../config/config";

// we need to access the redux store to warn that a token is outdated at middleware and outside
// of a react component see: https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
let store: Store;
export const injectStoreToApi = (_store: Store) => {
  store = _store;
};

const api = axios.create({
  baseURL: "/api/v1",
  headers: { "Content-Type": "application/json" },
  // we want HTTP ONLY cookies to be sent with each request as they contain tokens
  withCredentials: true,
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    // need to force the type overload since error.config returns an InternalAxiosRequest type
    const prevRequestConfig = error.config as AxiosRequestConfig;
    if (error?.response?.status === 401) {
      // if the auth/refresh url is sending 401 error too, then we force a logout
      // Used to prevent an infinite loop
      if (prevRequestConfig?.url === "/auth/refresh") {
        store.dispatch(logout());
        return Promise.reject("Can't refresh auth token");
      }

      try {
        // get refresh
        const { email, tenant_id } = await authService.refreshTokens();
        // refresh worked we set login state
        store.dispatch(login({ email: email, tenant_id: tenant_id }));
        // return api with prevRequest
        return api(prevRequestConfig);
      } catch (err) {
        // we signify the redux store that the user must be loggedOut
        store.dispatch(logout());
        return Promise.reject(err);
      }
    }

    // Anything else reject
    return Promise.reject(error);
  }
);

// Helpers to format an object into formData object
export const addObjectToFormData = (formData: FormData, obj: object, prefix = "") => {
  if (_.isObject(obj)) {
    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        addArrayToFormData(formData, value, `${prefix}["${key}"]`);
      } else formData.append(`${prefix}["${key}"]`, value);
    });
  } else {
    formData.append(prefix, obj);
  }
};

// Helpers to format an array into formData object
export const addArrayToFormData = (formData: FormData, arr: Array<object>, prefix = "") => {
  arr.forEach((entry, index) => {
    Object.entries(entry).forEach(([key, value]) => {
      addObjectToFormData(formData, value, `${prefix}[${index}]["${key}"]`);
    });
  });
};

export default api;
