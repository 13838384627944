export const LOCALSTORAGE_DOCUMENTS_PANEL_WIDTH_KEY = "documents_hub.defaultPanelWidth";
export const DEFAULT_DOCUMENTS_PANEL_WIDTH = 400;

export const LOCALSTORAGE_LABELLING_TABLE_PANEL_HEIGHT_KEY = "documents_hub.defaultTablePanelHeight";
export const DEFAULT_LABELLING_TABLE_PANEL_HEIGHT = 250;

export const LABELLING_SAVE_DEBOUNCE_TIME = 1000;
export const SEARCHING_DEBOUNCE_TIME = 500;

export const TABLE_COLUMN_MIN_WIDTH = 80;
export const TABLE_COLUMN_DEFAULT_WIDTH = 150;
export const TABLE_COLUMN_MAX_WIDTH = 350;

export const ANNOTATION_ORIGIN_COLORS = {
  MAPPING: "#309bef",
  MAPPING_ODD: "#32a852", // Dark green
  MAPPING_EVEN: "#abff57", // Light green
  PREDICTION: "#5aeed2",
  PREDICTION_ODD: "#3ea693", // Dark green
  PREDICTION_EVEN: "#bdf8ed", // Light green
  MANUAL: "#efbc30",
  HIGHLIGHTING: "#ee7f5a",
  DEFAULT: "#ccddeb"
};

export const NB_DOCUMENTS_PER_PAGE = 30;

// Size & offset to position the active row cursor in PageViewer canvas
export const ACTIVE_ROW_SIZE = 25; // px
export const ACTIVE_ROW_OFFSET = 10; // px

export const MAX_UPLOAD_SIZE = 50 * 1024 * 1024;
export const MAX_UPLOAD_FILES_NB = 20;