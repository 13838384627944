import React from "react";
import { FormControl, TextField } from "@mui/material";
import { IProcessor } from "models/usecase";

type FixedValueInputProps = {
  processor: IProcessor;
  onChange: (proc: any) => void;
};

const FixedValueInput: React.FC<FixedValueInputProps> = ({ processor, onChange }) => {
  return (
    <div className="w-full">
      <FormControl fullWidth size="small">
        <TextField
          value={processor?.value ?? ""}
          inputProps={{ style: { padding: "4.5px 6px", fontSize: 12, boxShadow: "none" } }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ value: event.target.value });
          }}
        />
      </FormControl>
    </div>
  );
};

export default FixedValueInput;
